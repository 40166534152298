<template>
  <div>

    <validation-observer ref="validationRules">
      <b-form>
        <b-row>
          <!-- name -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="t('Name')"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="t('Name')"
              >
                <b-form-input
                  v-model="dataModel.name"
                  maxlength="150"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- nameEn -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="t('Name En')"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="t('Name En')"
              >
                <b-form-input
                  v-model="dataModel.nameEn"
                  maxlength="150"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- optionValue -->
          <b-col
            v-if="acceptedValueId === 27"
            cols="12"
            md="6"
          >
            <b-form-group
              :label="t('Value')"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="t('Value')"
              >
                <b-form-input
                  v-model="dataModel.optionValue"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      type="submit"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="isLoading"
      @click.prevent="validationForm"
    >
      <b-spinner
        v-if="isLoading"
        small
      />
      {{ t('Save Changes') }}
    </b-button>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BSpinner,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// eslint-disable-next-line import/named
import { required, email, max } from '@validations'
import api from '../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
    BForm,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    acceptedValueId: {
      type: Number,
      required: true,
    },

  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      max,
      dataModel: {},
      isLoading: false,
    }
  },
  mounted() {
    if (this.id !== 0) {
      console.log(this.id)
      api.getAcceptedOption(this.id).then(result => {
        this.dataModel = result.data.data
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          // eslint-disable-next-line
        const dataModel = {
            parentId: this.parentId,
            acceptedValueId: this.acceptedValueId,
            name: this.dataModel.name,
            nameEn: this.dataModel.nameEn,
            optionValueType: 0,
            optionMenuId: 0,
            optionValue: this.dataModel.optionValue,
            optionColor: '',
            optionIcon: '',
            companyId: api.getCompany(),
          }
          console.log(dataModel)
          api.CreateUpdateAcceptedValue(this.id, dataModel).then(result => {
            this.isLoading = false
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

            this.$emit('refetch-data')
            this.dataModel = {}
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
