<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Services Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- Field: NationalityId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Nationality')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Nationality')"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.nationalityId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="NationalityList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: JobId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Job')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Job')"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.jobId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="JobList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- ServiceName -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Service Name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Service Name')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.serviceName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- ServiceNameEn -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Service Name En')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Service Name En')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.serviceNameEn"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- PriceWoVAT -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Price Wo VAT')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Price Wo VAT')"
                      >
                        <b-form-input
                          v-model="dataModel.priceWoVAT"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- vatValue -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('VAT Value')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('VAT Value')"
                      >
                        <b-form-input
                          v-model="dataModel.vatValue"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- PriceWVAT -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Price W VAT')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Price W VAT')"
                      >
                        <b-form-input
                          v-model="dataModel.priceWVAT"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: priceType -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group :label="t('Price Type')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Price Type')"
                      >
                        <v-select
                          v-model="dataModel.priceType"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="PriceTypeOptions"
                          :reduce="(val) => val.id"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Notes -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Notes')"
                    >
                      <b-form-textarea
                        v-model="dataModel.notes"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mb-75"
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ t("Save Changes") }}
              </b-button>

              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-services-list'}"
              >
                {{ t("Back To List") }}
              </b-button>
              <!-- Spacer -->
              <hr class="invoice-spacing">
              <!-- Field: BranchId -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="t('Branch')"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="t('Branch')"
                  >
                    <v-select
                      v-model="dataModel.branchId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="BranchsList"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'branchName' : 'branchNameEn'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      title="Add New"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BFormTextarea, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import AddAcceptedValue from '../../../components/AddAcceptedValue.vue'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    AddAcceptedValue,
    BFormTextarea,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {
        nationalityId: 0,
        jobId: 0,
        serviceName: '',
        serviceNameEn: '',
        accreditationNo: '',
        serviceDescription: '',
        serviceRequirements: '',
        durationDays: 0,
        durationDaysWrite: '',
        notes: '',
        priceWoVAT: 0,
        vatValue: 15,
        priceWVAT: 0,
        branchId: 0,
        priceType: 1,
        companyId: api.getCompany(),
      },
      BranchsList: [],
      NationalityList: [],
      JobList: [],
      PriceTypeOptions: [
        { id: 1, name: 'بالشهر', nameEn: 'Monthly' },
        { id: 2, name: 'باليوم', nameEn: 'Daily' },
        { id: 3, name: 'بالساعة', nameEn: 'Hourly' },
      ],
      id: 0,
      acceptedId: 0,

    }
  },
  watch: {
    'dataModel.priceWoVAT': 'calculatePriceWVAT',
    'dataModel.vatValue': 'calculatePriceWVAT',
  },
  mounted() {
    Promise.all([
      this.getCompaybranchs(),
      this.getNationalityList(),
      this.getJobList(),
      this.getservice(),
    ])
      .then(() => {
      // All asynchronous methods have completed
        console.log('All methods have completed.')
      })
      .catch(error => {
      // Handle errors if any of the asynchronous methods fail
        console.error('Error in one or more methods:', error)
      })
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.isLoading = true
          const dataModel = {
            nationalityId: this.dataModel.nationalityId,
            jobId: this.dataModel.jobId,
            serviceName: this.dataModel.serviceName,
            serviceNameEn: this.dataModel.serviceNameEn,
            accreditationNo: this.dataModel.accreditationNo,
            serviceDescription: this.dataModel.serviceDescription,
            serviceRequirements: this.dataModel.serviceRequirements,
            durationDays: this.dataModel.durationDays,
            durationDaysWrite: this.dataModel.durationDaysWrite,
            notes: this.dataModel.notes,
            priceWoVAT: this.dataModel.priceWoVAT,
            vatValue: this.dataModel.vatValue,
            priceWVAT: this.dataModel.priceWVAT,
            branchId: this.dataModel.branchId,
            priceType: this.dataModel.priceType,
            companyId: api.getCompany(),

          }
          api.CreateUpdateService(router.currentRoute.params.id, dataModel).then(result => {
            this.$swal({
              title: 'Good job!',
              text: 'Your details have been saved successfully.',
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.$swal({
              title: error.response.data.title,
              html: Object.values(error.response.data.errors)
                .map(item => `<div>${item}</div>`)
                .join(''),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
    calculatePriceWVAT() {
      if (
        typeof this.dataModel.priceWoVAT !== 'undefined'
        && typeof this.dataModel.vatValue !== 'undefined'
      ) {
        this.dataModel.priceWVAT = parseFloat(this.dataModel.priceWoVAT)
          + (parseFloat(this.dataModel.priceWoVAT) * parseFloat(this.dataModel.vatValue)) / 100
      }
    },
    async  getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    async  getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    async  getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      this.acceptedId = id
    },
    async  getservice() {
      api.getservicebyid(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
