<template>
  <section>
    <div>
      <vue-topprogress ref="topProgress" />
      <b-card>
        <b-card-header class="pb-50">
          <h5>
            {{ t('Filters') }}
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>

            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ t('Phone Number') }}</label>
              <div class="d-flex align-items-center justify-content-end">

                <b-form-input
                  id="search"
                  v-model="dataSearch.name"
                />
                  &nbsp;&nbsp;&nbsp;
                <b-button
                  variant="primary"
                  @click="LoadData"
                >
                  <span class="text-nowrap">{{ t('Search') }}</span>
                </b-button>
                ;&nbsp;
                <b-button
                  variant="danger"
                  @click="ClearLoadData"
                >
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t('entries') }}</label>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end" />
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="DataList"
          :fields="fields"
          :filter="filter"
          responsive=""
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                icon="MessageSquareIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({ name: 'app-settings-viewMessage', params: { id: data.item.id }})"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import { vueTopprogress } from 'vue-top-progress'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    vueTopprogress,
  },
  setup() {

  },
  data() {
    return {
      t,
      filter: null,
      // Sidebar
      isAddEditCountrySidebarActive: false,
      validityType: 0,
      dataSearch: {
        name: '',
        statusId: 0,
      },
      DataList: [],
      statusList: [
        { value: 0, label: 'الكل' },
        { value: 1, label: 'نشط' },
        { value: 2, label: 'غير نشط' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],

    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Full Name'),
          key: 'fullName',
          sortable: true,
        },
        {
          label: t('Phone Number'),
          key: 'phoneNumber',
          sortable: true,
        },
        {
          label: t('Address'),
          key: 'address',
          sortable: true,
        },
        {
          label: t('Message'),
          key: 'message',
          sortable: true,
        },
        { key: 'actions', label: t('Options') },
      ]
    },
  },
  mounted() {
    this.LoadData()
  },
  methods: {
    LoadData() {
      this.$refs.topProgress.start()
      api.GetCustomersMessages(this.currentPage - 1, this.perPage, '', '', this.dataSearch.name).then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.$refs.topProgress.done()
      })
    },
    ClearLoadData() {
      this.dataSearch.name = ''
      this.LoadData()
    },
    handlePageChange(value) {
      this.currentPage = value
      this.LoadData()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.LoadData()
    },
    getLanguage() {
      return api.getLanguage()
    },
    resolveStatusVariant(status) {
      console.log(status)
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    },
    Delete(id) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete it!'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          api.DeleteCountry(id).then(result1 => {
            this.LoadData()
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been delete successfully.'),
              icon: result1.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
            .catch(error => {
              console.log(error.response.status)
              if (error.response.status === 500) {
                this.$swal({
                  title: this.$t('Error'),
                  text: this.$t('The record is already in use and cannot be deleted'),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                this.$swal({
                  title: error.response.data.title,
                  html: Object.values(error.response.data.errors)
                    .map(item => `<div>${this.$t(item)}</div>`)
                    .join(''),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
  },
}
</script>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
