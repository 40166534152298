<template>
  <section>
    <div>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t('entries') }}</label>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  :placeholder="t('Search')"
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>

          <b-col
            cols="12"
            md="12"
          >
            <b-table
              class="position-relative"

              :items="DataList"
              :fields="fields"
              :filter="filter"
              responsive=""
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <template #cell(jNo)="data">
                <b-link
                  class="font-weight-bold"
                  @click="PrintJournal(data.item.uid)"
                >
                  {{ data.value }}
                </b-link>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <feather-icon
                  v-b-tooltip.hover.bottom="'Journal'"
                  icon="FileTextIcon"
                  class="cursor-pointer"
                  size="16"
                  @click="PrintJournal(data.item.uid)"
                />
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                />
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @change="handlePageChange"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  data() {
    return {
      t,
      filter: null,
      validityType: 0,
      dataSearch: {},
      DataList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Journal No'),
          key: 'jNo',
          sortable: true,
        },
        {
          label: t('Journal Date'),
          key: 'jDate',
          sortable: true,
        },
        {
          label: t('Source'),
          key: api.getLanguage() === 1 ? 'sourceName' : 'sourceNameEn',
          sortable: true,
        },
        {
          label: t('Total Deptor'),
          key: 'jTotalDeptor',
          sortable: true,
        },
        {
          label: t('Total Credator'),
          key: 'jTotalCredator',
          sortable: true,
        },

        {
          label: t('Branch'),
          key: api.getLanguage() === 1 ? 'branchName' : 'branchNameEn',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      api.GetJournal(this.currentPage - 1, this.perPage, 'JNo', '0', '', api.getCompany(), 0).then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getDataList()
    },
    getLanguage() {
      return api.getLanguage()
    },
    onClick(params) {
      console.log(params)
    },
    PrintJournal(id) {
      return api.PrintJournal(id)
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
