export default [

  {
    header: 'Customers',
    icon: 'UserIcon',
    children: [
      {
        title: 'Add Customer',
        route: 'app-customers-add',
      },
      {
        title: 'Customers List',
        route: { name: 'app-customers-list' },
      },
    ],
  },
  {
    header: 'Contracts',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Add New Contract',
        route: 'apps-e-commerce-shop',
      },
      {
        title: 'Contracts List',
        route: 'apps-e-commerce-wishlist',
      },
    ],
  },
  {
    header: 'Employment',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Add New Employee',
        route: 'apps-e-commerce-shop',
      },
      {
        title: 'Employment List',
        route: 'apps-e-commerce-wishlist',
      },
    ],
  },

  {
    header: 'External offices',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Add New Office',
        route: 'app-messages-all',
      },
      {
        title: 'offices List',
        route: { name: 'app-messages-outbound' },
      },
    ],
  },
  {
    header: 'Services',
    icon: 'ServerIcon',
    children: [
      {
        title: 'Add New Service',
        route: 'app-messages-all',
      },
      {
        title: 'Services List',
        route: { name: 'app-messages-outbound' },
      },
    ],
  },
  {
    header: 'Financial Management',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Account Tree',
        route: 'app-messages-all',
      },
      {
        title: 'Cost Centers',
        route: { name: 'app-messages-outbound' },
      },
      {
        title: 'Bonds',
        route: { name: 'app-messages-templates' },
      },
      {
        title: 'Journals',
        route: { name: 'app-messages-inbound' },
      },
      {
        title: 'General Ledger',
        route: { name: 'app-messages-scheduled' },
      },
      {
        title: 'Financial Years',
        route: { name: 'app-messages-trash' },
      },
    ],
  },
  {
    header: 'Reports',
    icon: 'AirplayIcon',
    children: [
      {
        title: 'Account Tree',
        route: 'app-messages-all',
      },
      {
        title: 'Cost Centers',
        route: { name: 'app-messages-outbound' },
      },
      {
        title: 'Bonds',
        route: { name: 'app-messages-templates' },
      },
      {
        title: 'Journals',
        route: { name: 'app-messages-inbound' },
      },
      {
        title: 'General Ledger',
        route: { name: 'app-messages-scheduled' },
      },
      {
        title: 'Financial Years',
        route: { name: 'app-messages-trash' },
      },
    ],
  },
  {
    header: 'System Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Company Data',
        route: 'apps-e-commerce-shop',
      },
      {
        title: 'Company Branches',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'User Groups',
        route: 'apps-e-commerce-product-details',
      },
      {
        title: 'Users',
        route: 'apps-e-commerce-wishlist',
      },
      {
        title: 'Places of birth',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Social Statuses',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Gender',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Nationalities',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Religions',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Regions',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Cities',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Districts',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Employers',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Jobs',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Banks',
        route: 'apps-e-commerce-checkout',
      },
      {
        title: 'Access methods',
        route: 'apps-e-commerce-checkout',
      },
    ],
  },

]
