var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validationRules"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('br'),_c('b-card-title',[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.t("Information")))])],1),_c('hr',{staticClass:"invoice-spacing"})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Notes')}},[_c('validation-provider',{attrs:{"name":_vm.t('Notes'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"rows":"3"},model:{value:(_vm.dataModel.notes),callback:function ($$v) {_vm.$set(_vm.dataModel, "notes", $$v)},expression:"dataModel.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('SMS Message')}},[_c('validation-provider',{attrs:{"name":_vm.t('SMS Message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"rows":"3"},model:{value:(_vm.dataModel.smsMessage),callback:function ($$v) {_vm.$set(_vm.dataModel, "smsMessage", $$v)},expression:"dataModel.smsMessage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('FollowUp Date')}},[_c('validation-provider',{attrs:{"name":_vm.t('FollowUp Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"state":errors.length > 0 ? false : null,"dir":_vm.getLanguage() === 1 ? 'rtl' : 'ltr',"type":"date"},model:{value:(_vm.dataModel.followUpDate),callback:function ($$v) {_vm.$set(_vm.dataModel, "followUpDate", $$v)},expression:"dataModel.followUpDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Reminder After')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.reminderAfter),callback:function ($$v) {_vm.$set(_vm.dataModel, "reminderAfter", $$v)},expression:"dataModel.reminderAfter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Contract Status')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ContractStatusList,"reduce":function (val) { return val.id; },"clearable":false,"input-id":"id","label":_vm.getLanguage() === 1 ? 'name' : 'nameEn'},nativeOn:{"click":function($event){return _vm.getContractStatusList()}},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_c('li',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-new",modifiers:{"modal-new":true}}],staticClass:"add-new d-flex align-items-center my-50",on:{"click":function($event){return _vm.SetAcceptedId(25)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"align-middle ml-25"},[_vm._v("Add New")])],1)]},proxy:true}],null,true),model:{value:(_vm.dataModel.contractStatusId),callback:function ($$v) {_vm.$set(_vm.dataModel, "contractStatusId", $$v)},expression:"dataModel.contractStatusId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{staticClass:"invoice-actions mt-md-0 mt-2",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.t("Save Changes"))+" ")]),_c('b-button',{attrs:{"variant":"outline-primary","block":"","to":{ name: 'app-contract-followup'}}},[_vm._v(" "+_vm._s(_vm.t("Back To List"))+" ")])],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-new","hide-footer":true,"centered":"","size":"lg","title":"Add New"}},[_c('add-accepted-Value',{attrs:{"id":0,"parent-id":0,"accepted-value-id":_vm.acceptedId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }