var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('vue-topprogress',{ref:"topProgress"}),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validationRules"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('br'),_c('b-card-title',[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.t("Information")))])],1),_c('hr',{staticClass:"invoice-spacing"})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Name')}},[_c('validation-provider',{attrs:{"name":_vm.t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":""},model:{value:(_vm.dataModel.fullName),callback:function ($$v) {_vm.$set(_vm.dataModel, "fullName", $$v)},expression:"dataModel.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Phone Number')}},[_c('validation-provider',{attrs:{"name":_vm.t('Phone Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":""},model:{value:(_vm.dataModel.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dataModel, "phoneNumber", $$v)},expression:"dataModel.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Address')}},[_c('validation-provider',{attrs:{"name":_vm.t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":""},model:{value:(_vm.dataModel.address),callback:function ($$v) {_vm.$set(_vm.dataModel, "address", $$v)},expression:"dataModel.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Message')}},[_c('validation-provider',{attrs:{"name":_vm.t('Message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"readonly":""},model:{value:(_vm.dataModel.message),callback:function ($$v) {_vm.$set(_vm.dataModel, "message", $$v)},expression:"dataModel.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{staticClass:"invoice-actions mt-md-0 mt-2",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('b-button',{attrs:{"variant":"outline-primary","block":"","to":{ name: 'app-settings-customersmessages'}}},[_vm._v(" "+_vm._s(_vm.t("Back To List"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }