<template>
  <section>
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row>

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card>

              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <br>
                  <b-card-title>
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span>{{
                      t("Information")
                    }}</span>
                  </b-card-title>
                  <hr class="invoice-spacing">
                </b-col>

                <!-- nameAr -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="t('Name')"
                    label-for="nameAr"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Name')"
                    >
                      <b-form-input
                        id="nameAr"
                        v-model="dataModel.nameAr"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- nameEn -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="t('Name En')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Name En')"
                    >
                      <b-form-input
                        id="nameEn"
                        v-model="dataModel.nameEn"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="t('Allow Access Pages')"
                  >
                    <v-select
                      v-model="selected"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      multiple
                      :options="MenusList"
                      label="title"
                    >
                      <template #option="{ title, icon }">
                        <feather-icon
                          :icon="icon"
                          size="16"
                          class="align-middle mr-25"
                        />
                        <span> {{ title }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <br>

              </b-row>
              <br>
              <br>

            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                block
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="danger"
                block
                :to="{ name: 'app-UserGroups-list'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCardTitle,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
import { vueTopprogress } from 'vue-top-progress'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vueTopprogress,
    vSelect,
    BCardTitle,
    BSpinner,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      selected: [],
      dataModel: {},
      MenusList: [],
      id: 0,
      isLoading: false,
    }
  },
  mounted() {
    this.GetMenusDropDownList()
    if (this.decryptId(router.currentRoute.params.id) !== 0) {
      this.$refs.topProgress.start()
      api.GetGroupById(this.decryptId(router.currentRoute.params.id)).then(result => {
        this.dataModel = result.data.data
        this.selected = result.data.data.menus
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      console.log(this.selected)
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          this.isLoading = true
          // eslint-disable-next-line
        const dataModel = {
            id: this.decryptId(router.currentRoute.params.id),
            nameAr: this.dataModel.nameAr,
            nameEn: this.dataModel.nameEn,
            menus: this.selected,
            companyId: api.getCompany(),

          }
          api.CreateUpdateGroup(this.decryptId(router.currentRoute.params.id), dataModel).then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isLoading = false
            this.$refs.topProgress.done()

            if (this.decryptId(router.currentRoute.params.id) === 0) {
              this.$router.push({ name: 'app-UserGroups-edit', params: { id: result.data.data } })
            }
          })
            .catch(error => {
              this.$refs.topProgress.done()
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    GetMenusDropDownList() {
      api.GetMenusDropDownList().then(result => {
        this.MenusList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    decryptId(str) {
      const decodedStr = decodeURIComponent(str)
      return AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8) === '' ? 0
        : AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8)
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
