<template>
  <section>

    <div>
      <vue-topprogress ref="topProgress" />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>entries</label>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="ContractsList"
          :fields="fields"
          :filter="filter"
          responsive=""
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(customerNameEn)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(data.item.customerNameEn)"
                  variant="light-success"
                  :to="{ name: 'app-contract-edit', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'app-contract-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.titleNameEn }}:{{ data.item.customerNameEn }}
              </b-link>
            </b-media>
          </template>
          <template #cell(customerName)="data">
            <b-link
              :to="{ name: 'app-contract-edit', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(employeeName)="data">
            <b-link
              :to="{ name: 'app-select-employee', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(employeeNameEn)="data">
            <b-link
              :to="{ name: 'app-select-employee', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              {{ data.value }}
            </b-link>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'app-contract-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'app-contract-addfollowup', params: { id: data.item.id } }"
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Add Follow Up</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'app-contract-followup', params: { id: data.item.id } }"
              >
                <feather-icon icon="ListIcon" />
                <span class="align-middle ml-50">Follow Up List</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{ name: 'app-contract-EmploymentArrival', params: { id: data.item.id } }"
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Employment Arrival</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BPagination,
  BMedia, BAvatar,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { vueTopprogress } from 'vue-top-progress'

import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    vueTopprogress,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BLink,
    BPagination,
    BMedia,
    BAvatar,
    vSelect,
  },
  data() {
    return {
      filter: null,
      avatarText,
      loading: false,
      validityType: 0,
      dataSearch: {},
      ContractsList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Employee Id'),
          key: 'employeeId',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: t('Employee Name'),
          key: api.getLanguage() === 1 ? 'employeeName' : 'employeeNameEn',
          sortable: true,
        },
        {
          label: t('Visa Type'),
          key: api.getLanguage() === 1 ? 'visaTypeName' : 'visaTypeNameEn',
          sortable: true,
        },
        {
          label: t('Customer Name'),
          key: api.getLanguage() === 1 ? 'customerName' : 'customerNameEn',
          sortable: true,
        },
        {
          label: t('Registration Date'),
          key: 'registrationDate',
          sortable: true,
        },
        {
          label: t('Service'),
          key: api.getLanguage() === 1 ? 'serviceName' : 'serviceNameEn',
          sortable: true,
        },
        {
          label: t('Price'),
          key: 'priceBDiscount',
          sortable: true,
        },
        {
          label: t('Vat'),
          key: 'vatValue',
          sortable: true,
        },
        {
          label: t('Musaned Fee'),
          key: 'musanedFee',
          sortable: true,
        },
        {
          label: t('Total'),
          key: 'totalWVAT',
          sortable: true,
        },
        {
          label: t('Visa Number'),
          key: 'visaNumber',
          sortable: true,
        },
        {
          label: t('Beneficiaries Count'),
          key: 'beneficiariesCount',
          sortable: true,
        },
        {
          label: t('Visa Date (Hijri)'),
          key: 'visaDateHijri',
          sortable: true,
        },
        {
          label: t('Visa Date (Gregorian)'),
          key: 'visaDateGregorian',
          sortable: true,
        },
        {
          label: t('Job'),
          key: api.getLanguage() === 1 ? 'jobName' : 'jobNameEn',
          sortable: true,
        },
        {
          label: t('Nationality'),
          key: api.getLanguage() === 1 ? 'nationalityName' : 'nationalityNameEn',
          sortable: true,
        },
        {
          label: t('Religion'),
          key: api.getLanguage() === 1 ? 'religionName' : 'religionNameEn',
          sortable: true,
        },
        {
          label: t('Arrival Destination'),
          key: api.getLanguage() === 1 ? 'arrivalDestinationName' : 'arrivalDestinationNameEn',
          sortable: true,
        },
        {
          label: t('Arrival Airport'),
          key: api.getLanguage() === 1 ? 'arrivalAirportName' : 'arrivalAirportNameEn',
          sortable: true,
        },
        {
          label: t('Age Of'),
          key: 'ageOf',
          sortable: true,
        },
        {
          label: t('Age To'),
          key: 'ageTo',
          sortable: true,
        },
        {
          label: t('Contract Duration'),
          key: 'contractDuration',
          sortable: true,
        },
        {
          label: t('Completion Period'),
          key: 'completionPeriod',
          sortable: true,
        },
        {
          label: t('Experience'),
          key: api.getLanguage() === 1 ? 'experienceName' : 'experienceNameEn',
          sortable: true,
        },
        {
          label: t('Musaned Contract No'),
          key: 'musanedContractNo',
          sortable: true,
        },
        {
          label: t('Musaned Authorization No'),
          key: 'musanedAuthorizationNo',
          sortable: true,
        },
        {
          label: t('Musaned Authorization (Hijri)'),
          key: 'musanedAuthorizationDateHijri',
          sortable: true,
        },
        {
          label: t('Musaned Authorization (Gregorian)'),
          key: 'musanedAuthorizationDataGregorian',
          sortable: true,
        },
        {
          label: t('Work City'),
          key: 'workCity',
          sortable: true,
        },
        {
          label: t('Work Neighborhood'),
          key: 'workNeighborhood',
          sortable: true,
        },
        {
          label: t('Work Street'),
          key: 'workStreet',
          sortable: true,
        },
        {
          label: t('Responsible Person'),
          key: 'responsiblePerson',
          sortable: true,
        },
        {
          label: t('Housing Type'),
          key: api.getLanguage() === 1 ? 'housingTypeName' : 'housingTypeNameEn',
          sortable: true,
        },
        {
          label: t('Authorization No'),
          key: 'authorizationNo',
          sortable: true,
        },
        {
          label: t('Authorization Date (Hijri)'),
          key: 'authorizationDateHijri',
          sortable: true,
        },
        {
          label: t('Authorization Date (Gregorian)'),
          key: 'authorizationDateGregorian',
          sortable: true,
        },
        {
          label: t('Salary'),
          key: 'salary',
          sortable: true,
        },
        {
          label: t('Vacation Days'),
          key: 'vacationDays',
          sortable: true,
        },
        {
          label: t('Qualifications'),
          key: 'qualifications',
          sortable: true,
        },
        {
          label: t('Experience'),
          key: 'experience',
          sortable: true,
        },
        {
          label: t('Appearance'),
          key: api.getLanguage() === 1 ? 'appearanceName' : 'appearanceNameEn',
          sortable: true,
        },
        {
          label: t('Border Number'),
          key: 'borderNumber',
          sortable: true,
        },
        {
          label: t('External Office'),
          key: api.getLanguage() === 1 ? 'externalOfficeName' : 'externalOfficeNameEn',
          sortable: true,
        },
        {
          label: t('Al Maqqab'),
          key: api.getLanguage() === 1 ? 'alMaqqabName' : 'alMaqqabNameEn',
          sortable: true,
        },
        {
          label: t('Mediator'),
          key: api.getLanguage() === 1 ? 'mediatorName' : 'mediatorNameEn',
          sortable: true,
        },
        {
          label: t('Agent'),
          key: 'agent',
          sortable: true,
        },
        {
          label: t('Agent Contact Details'),
          key: 'agentContactDetails',
          sortable: true,
        },
        {
          label: t('Condition 1'),
          key: api.getLanguage() === 1 ? 'condition1Name' : 'condition1NameEn',
          sortable: true,
        },
        {
          label: t('Condition 2'),
          key: api.getLanguage() === 1 ? 'condition2Name' : 'condition2NameEn',
          sortable: true,
        },
        {
          label: t('Condition 3'),
          key: api.getLanguage() === 1 ? 'condition3Name' : 'condition3NameEn',
          sortable: true,
        },
        {
          label: t('Condition 4'),
          key: api.getLanguage() === 1 ? 'condition4Name' : 'condition4NameEn',
          sortable: true,
        },
        {
          label: t('Condition 5'),
          key: api.getLanguage() === 1 ? 'condition5Name' : 'condition5NameEn',
          sortable: true,
        },
        {
          label: t('Additional Terms'),
          key: 'additionalTerms',
          sortable: true,
        },
        {
          label: t('Status'),
          key: api.getLanguage() === 1 ? 'contractStatusName' : 'contractStatusNameEn',
          sortable: true,
        },
        {
          label: t('Branch'),
          key: api.getLanguage() === 1 ? 'branchName' : 'branchNameEn',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
  },
  mounted() {
    this.getcontractsList()
  },
  methods: {
    getcontractsList() {
      this.$refs.topProgress.start()
      api.getcontractsList(this.currentPage - 1, this.perPage, '', '', '', api.getCompany(), 0).then(result => {
        this.ContractsList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.$refs.topProgress.done()
      })
    },
    handlePageChange(value) {
      console.log(value)
      this.currentPage = value
      this.getcontractsList()
    },
    handlePerPageChange(value) {
      console.log(value)
      this.perPage = value
      this.getcontractsList()
    },
    handleFieldsChange(value) {
      console.log(value)
    },
    search() {
      api.searchcustomers(this.currentPage - 1, this.perPage, '', '', '', api.getCompany(), 0,
        this.dataSearch.field, this.dataSearch.condition, this.dataSearch.search).then(result => {
        this.ContractsList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
