<template>
  <section>
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="text-right"
          >

            <!-- Action Buttons -->
            <b-button
              variant="primary"
              :disabled="isLoading"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              {{ t("Save Changes") }}
            </b-button>
               &nbsp; &nbsp;
            <b-button
              variant="danger"
              :to="{ name: 'app-bond-list'}"
            >
              {{ t("Back To List") }}
            </b-button>

          </b-col>
        </b-row>
        <br>
        <b-row>

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="12"
            md="12"
          >

            <b-card>
              <!-- Header -->
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Bond Information")
                      }}
                      </span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Bond Type')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Bond Type')"
                      >
                        <v-select
                          v-model="dataModel.bondType"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="BondTypeOptions"
                          :reduce="(val) => val.id"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @input="val => GetBondAutoNum(val)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: bondNo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Bond No')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Bond No')"
                      >
                        <b-form-input
                          v-model="dataModel.bondNo"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- notebook -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Notebook')"
                    >
                      <b-form-input
                        v-model="dataModel.notebook"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- bondDate -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Bond Date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Bond Date')"
                      >
                        <flat-pickr
                          v-model="dataModel.bondDate"
                          :state="errors.length > 0 ? false : null"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: accountFrom -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('From Account')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('From Account')"
                      >
                        <v-select
                          v-model="dataModel.accountFrom"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="AccountsList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: accountTo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('To Account')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('To Account')"
                      >
                        <v-select
                          v-model="dataModel.accountTo"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="AccountsList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: amount -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Amount')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Amount')"
                      >
                        <b-form-input
                          v-model="dataModel.amount"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: paymentType -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Payment Type')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Payment Type')"
                      >
                        <v-select
                          v-model="dataModel.paymentType"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="PaymentTypeOptions"
                          :reduce="(val) => val.id"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: paperNo -->
                  <b-col
                    v-if="dataModel.paymentType === 2"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Paper No')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Paper No')"
                      >
                        <b-form-input
                          v-model="dataModel.paperNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: paperDate -->
                  <b-col
                    v-if="dataModel.paymentType === 2"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Paper Date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Paper Date')"
                      >
                        <flat-pickr
                          v-model="dataModel.paperDate"
                          :state="errors.length > 0 ? false : null"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- representativeId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Representative')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Representative')"
                      >
                        <v-select
                          v-model="dataModel.representativeId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="EmployeeList"
                          :reduce="(val) => val.id"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- costCenterId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Cost Center')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Cost Center')"
                      >
                        <v-select
                          v-model="dataModel.costCenterId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="CostCenterList"
                          :reduce="(val) => val.id"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- currencyId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Currency')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Currency')"
                      >
                        <v-select
                          v-model="dataModel.currencyId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="CurrencyList"
                          :reduce="(val) => val.id"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @input="val => GetCurrencyExRate(val)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- exRate -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Exchange Rate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Exchange Rate')"
                      >
                        <b-form-input
                          v-model="dataModel.exRate"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- description -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Description')"
                    >
                      <b-form-textarea
                        v-model="dataModel.description"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BFormTextarea, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { vueTopprogress } from 'vue-top-progress'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormTextarea,
    flatPickr,
    BCardBody,
    BCardTitle,
    BSpinner,
    vueTopprogress,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {
        bondType: 1,
        bondNo: '',
        notebook: '',
        bondDate: new Date(),
        accountFrom: '',
        accountTo: '',
        amount: 0,
        vatRatio: 0,
        vatValue: 0,
        amountWithVat: 0,
        representativeId: 0,
        paymentType: 1,
        paperNo: '',
        paperDate: '',
        description: '',
        companyId: api.getCompany(),
        branchId: 0,
        userId: api.getUser(),
        carryOvered: true,
        costCenterId: 0,
        currencyId: '',
        exRate: 0,
        recSource: 0,
        recId: 0,
        yearId: 0,
      },
      BranchsList: [],
      AccountsList: [],
      EmployeeList: [],
      CostCenterList: [],
      CurrencyList: [],
      id: 0,
      acceptedId: 0,
      PaymentTypeOptions: [
        { id: 1, name: 'صندوق', nameEn: 'Cash' },
        { id: 2, name: 'بنك', nameEn: 'Bank' },
      ],
      BondTypeOptions: [
        { id: 1, name: 'سند قبض', nameEn: 'Receipt Voucher' },
        { id: 2, name: 'سند صرف', nameEn: 'Exchange Voucher' },
      ],
      isLoading: false,

    }
  },
  mounted() {
    this.getCompaybranchs()
    this.getAccountsList()
    this.getemployeeList()
    this.getCurrencyList()
    this.GetBondAutoNum(1)
    this.getCostCenterList()
    if (router.currentRoute.params.id !== 0) {
      this.$refs.topProgress.start()
      api.getbond(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
        this.dataModel.paymentType = result.data.data.paymentType === 'كاش' ? 1 : 2
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      if (this.dataModel.amount <= 0) {
        this.$swal({
          title: this.$t('warning'),
          text: this.$t('It is not allowed to make a receipt voucher equal  0'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$refs.validationRules.validate().then(success => {
          if (success) {
          // eslint-disable-next-line
            this.isLoading = true
            api.CreateUpdateBond(router.currentRoute.params.id, this.dataModel).then(result => {
              this.getAccountsList()
              this.isLoading = false
              this.$swal({
                title: this.$t('Good job!'),
                text: this.$t('Your details have been saved successfully.'),
                icon: result.data.httpCode === 200 ? 'success' : 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })

              this.dataModel = {}
              this.$refs.validationRules.reset()
              this.$router.push({ name: 'app-bond-list' })
            })
          }
        })
      }
    },
    getAccountsList() {
      api.GetAllAccountChild(0, 0, '', '', '', api.getCompany(), 0).then(result => {
        this.AccountsList = result.data.data.pageData
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      console.log('1', id)
      this.acceptedId = id
    },
    GetBondAutoNum(val) {
      api.GetBondAutoNum(api.getCompany(), 0, val).then(result => {
        this.dataModel.bondNo = result.data.data
      })
    },
    getemployeeList() {
      api.getemployeeList(0, 0, '', '', '', api.getCompany(), 0).then(result => {
        this.EmployeeList = result.data.data.pageData
      })
    },
    getCurrencyList() {
      api.getAcceptedOptions(27).then(result => {
        this.CurrencyList = result.data.data.pageData
        this.dataModel.currencyId = result.data.data.pageData[0].id
        this.dataModel.exRate = result.data.data.pageData[0].optionValue
      })
    },
    GetCurrencyExRate(val) {
      api.getAcceptedOption(val).then(result => {
        this.dataModel.exRate = result.data.data.optionValue
      })
    },
    getCostCenterList() {
      api.getAcceptedOptions(28).then(result => {
        this.CostCenterList = result.data.data.pageData
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
