<template>
  <section>
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">

        <b-row>
          <b-col
            cols="12"
            md="12"
            xl="12"
            class="text-right"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              :disabled="isLoading"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              {{ t("Save Changes") }}
            </b-button>
              &nbsp;
              &nbsp;
            <b-button
              variant="outline-danger"
              :to="{ name: 'app-customers-list'}"
            >
              {{ t("Back To List") }}
            </b-button>

          </b-col>
        </b-row>
        <br>
        <b-row>

          <b-col
            cols="12"
            xl="12"
            md="12"
          >

            <b-card>

              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Personal Information")
                      }}</span>
                    </b-card-title>
                  </b-col>

                  <!-- Title -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Title')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Title')"
                      >
                        <v-select
                          ref="titleId"
                          v-model="dataModel.titleId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="TitlesList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getTitlesList()"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.titleId.open = true"
                          >
                            <strong>{{ t("Sorry, no matching options") }}</strong>
                          </span>
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(14)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">
                                {{ t('Add New') }}

                              </span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Name Arabic -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Customer Name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Customer Name')"
                      >
                        <b-form-input
                          v-model="dataModel.customerName"
                          maxlength="100"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Name English -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Customer Name En')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Customer Name En')"
                      >
                        <b-form-input
                          v-model="dataModel.customerNameEn"
                          maxlength="100"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: IdentityOrIqamaNo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Identity Or IqamaNo')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|integer"
                        :name="t('Identity Or IqamaNo')"
                      >
                        <b-form-input
                          v-model="dataModel.identityOrIqamaNo"
                          maxlength="10"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: SexId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Gender')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Gender')"
                      >
                        <v-select
                          ref="sexId"
                          v-model="dataModel.sexId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="GenderList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getGenderList()"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.sexId.open = true"
                          >
                            <strong>{{ t("Sorry, no matching options") }}</strong>
                          </span>
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(7)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New') }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Mobile No -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Mobile No')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|integer"
                        :name="t('Mobile No')"
                      >
                        <b-form-input
                          v-model="dataModel.mobileNo"
                          maxlength="10"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Job -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Job')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Job')"
                      >
                        <b-form-input
                          v-model="dataModel.job"
                          maxlength="100"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: NationalityId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Nationality')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Nationality')"
                      >
                        <v-select
                          ref="nationalityId"
                          v-model="dataModel.nationalityId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="NationalityList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getNationalityList()"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.nationalityId.open = true"
                          >
                            <strong>{{ t("Sorry, no matching options") }}</strong>
                          </span>
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(8)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t('Add New') }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Address Information")
                      }}</span>
                    </b-card-title>
                  </b-col>
                  <!-- Field: RegionId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Region')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Region')"
                      >
                        <v-select
                          ref="regionId"
                          v-model="dataModel.regionId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="RegionList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                          @click.native="getRegionList()"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.regionId.open = true"
                          >
                            <strong>{{ t("Sorry, no matching options") }}</strong>
                          </span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: City -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('City')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('City')"
                      >
                        <v-select
                          ref="cityId"
                          v-model="dataModel.cityId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="CityList"
                          :reduce="val => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                          @click.native="getCitiesList(dataModel.regionId)"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.cityId.open = true"
                          >
                            <strong>{{ t("Sorry, no matching options") }}</strong>
                          </span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: DistrictId -->
             

                  <!-- Field: Street -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group

                      :label="t('Street')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Street')"
                      >
                        <b-form-input
                          v-model="dataModel.street"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Street En -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group

                      :label="t('Street En')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Street En')"
                      >
                        <b-form-input
                          v-model="dataModel.streetEn"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Family and Contact Information")
                      }}</span>
                    </b-card-title>
                  </b-col>
                  <!-- Field: Children No -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Children Count')"
                    >
                      <b-form-input
                        v-model="dataModel.childrenCount"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: MonthlyIncome -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Monthly Income')"
                    >
                      <b-form-input
                        v-model="dataModel.monthlyIncome"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: FamilyMembersNo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Family Members Count')"
                    >
                      <b-form-input
                        v-model="dataModel.familyMembersCount"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: MobileOtherNo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Other Mobile No')"
                    >
                      <b-form-input
                        v-model="dataModel.mobileOtherNo"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Phone -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group

                      :label="t('Phone')"
                    >
                      <b-form-input
                        v-model="dataModel.phone"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Email -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group

                      :label="t('Email')"
                    >

                      <validation-provider
                        #default="{ errors }"
                        :name="t('Email')"
                        rules="email"
                      >
                        <b-form-input
                          v-model="dataModel.email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Work Information")
                      }}</span>
                    </b-card-title>
                  </b-col>
                  <!-- Field: Employer -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Employer')"
                    >
                      <b-form-input
                        v-model="dataModel.employer"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Address -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Address')"
                    >
                      <b-form-input
                        v-model="dataModel.address"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: WorkPhone -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Work Phone')"
                    >
                      <b-form-input
                        v-model="dataModel.workPhone"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: PostBox -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Post Box')"
                    >
                      <b-form-input
                        v-model="dataModel.postBox"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: PostalCode -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Postal Code')"
                    >
                      <b-form-input
                        v-model="dataModel.postalCode"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Bank Account -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Bank Account')"
                    >
                      <b-form-input
                        v-model="dataModel.bankAccount"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Bank Name -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Bank Name')"
                    >
                      <v-select
                        ref="bankNameId"
                        v-model="dataModel.bankNameId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="BankList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getBankList()"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.bankNameId.open = true"
                        >
                          <strong>{{ t("Sorry, no matching options") }}</strong>
                        </span>
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(12)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">{{ t('Add New') }}</span>
                          </li>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Other Information")
                      }}</span>
                    </b-card-title>
                  </b-col>
                  <!-- Field: Birth place -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Birth place')"
                    >
                      <b-form-input
                        v-model="dataModel.birthplace"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: IssueDateGregorian -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Issue Date (Gregorian)')"
                    >
                      <date-picker
                        v-model="dataModel.issueDateGregorian"
                        type="date"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: ExpiryDateGregorian -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Expiry Date (Gregorian)')"
                    >
                      <date-picker
                        v-model="dataModel.expiryDateGregorian"
                        type="date"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Birth Date (Gregorian) -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Birth Date (Gregorian)')"
                    >

                      <date-picker
                        v-model="dataModel.birthdateGregorian"
                        type="date"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: SocialStatusId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Social Status')"
                    >

                      <v-select
                        ref="socialStatusId"
                        v-model="dataModel.socialStatusId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="SocialStatusList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getSocialStatusList()"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.socialStatusId.open = true"
                        >
                          <strong>{{ t("Sorry, no matching options") }}</strong>
                        </span>
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(6)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">{{ t('Add New') }}</span>
                          </li>
                        </template>
                      </v-select>

                    </b-form-group>
                  </b-col>

                  <!-- Field: Access Method -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Access Method')"
                    >
                      <v-select
                        ref="accessMethodId"
                        v-model="dataModel.accessMethodId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="AccessMethodList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getAccessMethodList()"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.accessMethodId.open = true"
                        >
                          <strong>{{ t("Sorry, no matching options") }}</strong>
                        </span>
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(13)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">{{ t('Add New') }}</span>
                          </li>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- Field: CustomerFeedback -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Customer Feedback')"
                    >
                      <quill-editor
                        v-model="dataModel.customerFeedback"
                        :options="snowOption"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      :title="t('Add New')"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import VueDatetimeJs from 'vue-datetime-js'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCardTitle,
  BSpinner,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import AddAcceptedValue from '../../../components/AddAcceptedValue.vue'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    quillEditor,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardTitle,
    vSelect,
    AddAcceptedValue,
    datePicker: VueDatetimeJs,
    BSpinner,
  },
  data() {
    const { getValidationState } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      id: 0,
      RegionList: [],
      isLoading: false,
      CityList: [],
      DistrictList: [],
      TitlesList: [],
      BankList: [],
      AccessMethodList: [],
      SocialStatusList: [],
      GenderList: [],
      NationalityList: [],
      BranchsList: [],
      acceptedId: 0,
    }
  },
  mounted() {
    this.getTitlesList()
    this.getCompaybranchs()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          // eslint-disable-next-line
        const dataModel = {
            customerNo: 0,
            customerName: this.dataModel.customerName,
            customerNameEn: this.dataModel.customerNameEn,
            identityOrIqamaNo: this.dataModel.identityOrIqamaNo,
            birthplace: this.dataModel.birthplace,
            issueDateHijri: this.dataModel.issueDateHijri,
            issueDateGregorian: this.dataModel.issueDateGregorian,
            expiryDateHijri: this.dataModel.expiryDateHijri,
            expiryDateGregorian: this.dataModel.expiryDateGregorian,
            birthdateHijri: this.dataModel.birthdateHijri,
            birthdateGregorian: this.dataModel.birthdateGregorian,
            socialStatusId: this.dataModel.socialStatusId,
            sexId: this.dataModel.sexId,
            nationalityId: this.dataModel.nationalityId,
            regionId: this.dataModel.regionId,
            cityId: this.dataModel.cityId,
            districtId: 0,
            street: this.dataModel.street,
            streetEn: this.dataModel.streetEn,
            titleId: this.dataModel.titleId,
            childrenCount: this.dataModel.childrenCount,
            monthlyIncome: this.dataModel.monthlyIncome,
            familyMembersCount: this.dataModel.familyMembersCount,
            mobileNo: this.dataModel.mobileNo,
            mobileOtherNo: this.dataModel.mobileOtherNo,
            phone: this.dataModel.phone,
            email: this.dataModel.email,
            employer: this.dataModel.employer,
            job: this.dataModel.job,
            address: this.dataModel.address,
            workPhone: this.dataModel.workPhone,
            postBox: this.dataModel.postBox,
            postalCode: this.dataModel.postalCode,
            bankAccount: this.dataModel.bankAccount,
            bankNameId: this.dataModel.bankNameId,
            customerFeedback: this.dataModel.customerFeedback,
            branchId: this.dataModel.branchId,
            accessMethodId: this.dataModel.accessMethodId,
            companyId: api.getCompany(),

          }
          api.CreateUpdateCustomer(this.id, dataModel).then(result => {
            this.isLoading = false
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

            this.dataModel = {}
            this.$refs.validationRules.reset()
          })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.isLoading = false
              this.$swal({
                title: this.$t(error.response.data.title),
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${this.$t(item)}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getRegionList() {
      api.getRegionList().then(result => {
        this.RegionList = result.data.data.pageData
      })
    },
    getCitiesList(parentId) {
      api.getCitiesList(parentId).then(result => {
        this.CityList = result.data.data.pageData
      })
    },
    getDistrictsList(parentId) {
      api.getDistrictsList(parentId).then(result => {
        this.DistrictList = result.data.data.pageData
      })
    },
    getTitlesList() {
      api.getAcceptedOptions(14).then(result => {
        this.TitlesList = result.data.data.pageData
      })
    },
    getAccessMethodList() {
      api.getAcceptedOptions(13).then(result => {
        this.AccessMethodList = result.data.data.pageData
      })
    },
    getBankList() {
      api.getAcceptedOptions(12).then(result => {
        this.BankList = result.data.data.pageData
      })
    },
    getSocialStatusList() {
      api.getAcceptedOptions(6).then(result => {
        this.SocialStatusList = result.data.data.pageData
      })
    },
    getGenderList() {
      api.getAcceptedOptions(7).then(result => {
        this.GenderList = result.data.data.pageData
      })
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      console.log('1', id)
      this.acceptedId = id
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
