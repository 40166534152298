import axios from 'axios'

export default axios.create({
baseURL: 'https://estkdamapi.azurewebsites.net/api/',
 //baseURL: 'http://localhost:5001/api/',

  headers: {
    'Content-type': 'application/json',
  },
})
