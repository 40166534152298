// services/api.js
import API_URL from './config';
import axios from 'axios';

const http = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

export default http;
