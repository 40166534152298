<template>
  <section>

    <div>
      <vue-topprogress ref="topProgress" />

      <b-card-actions :title="t('Filters')" action-collapse>
        <div class="m-2">
          <b-row>
            <!-- Field: socialID -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Social ID')">
                <b-form-input v-model="dataSearch.socialID" />
              </b-form-group>
            </b-col>
            <!-- Field: customerMobileNO -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Mobile No')">
                <b-form-input v-model="dataSearch.customerMobileNO" />
              </b-form-group>
            </b-col>

            <!-- Field:Customer Name -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Customer Name')">
                <b-form-input v-model="dataSearch.customerName" />
              </b-form-group>
            </b-col>

            <!-- Field: Contract No -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Contract No')">
                <b-form-input v-model="dataSearch.contractNO" />
              </b-form-group>
            </b-col>
            <!-- Field: Musaned No -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Musaned No')">

                <b-form-input v-model="dataSearch.mosandContractNo" />

              </b-form-group>
            </b-col>

            <!-- Field: Worker Name -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Worker Name')">

                <b-form-input v-model="dataSearch.workerName" />

              </b-form-group>
            </b-col>

            <!-- Field: workerProfessionId -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Worker Profession')">
                <v-select v-model="dataSearch.workerProfessionId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="JobList" :reduce="(val) => val.id" :clearable="false" input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'" />

              </b-form-group>
            </b-col>
            <!-- Field: NationalityId -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Nationality')">

                <v-select v-model="dataSearch.workerNationalityId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="NationalityList" :reduce="(val) => val.id" :clearable="false" input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'" />

              </b-form-group>
            </b-col>
            <!-- Field: ReligionId -->
            <b-col cols="12" md="4">
              <b-form-group :label="t('Religion')">

                <v-select v-model="dataSearch.workerReligionId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="ReligionsList" :reduce="(val) => val.id" :clearable="false" input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'" />

              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group :label="t('Is Arrived')">
                <v-select v-model="dataSearch.isArrived" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="ArrivedOptions" :reduce="(val) => val.id" input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group :label="t('Warranty Period')">
                <v-select v-model="dataSearch.warranty" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="WarrantyOptions" :reduce="(val) => val.id" input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group :label="t('Cancelation Side')">

                <v-select v-model="dataSearch.cancelationSide" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="CancelationSideOptions" :reduce="(val) => val.id" input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'" />

              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group :label="t('Paid')">

                <v-select v-model="dataSearch.isPaid" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'" :options="PaidOptions"
                  :reduce="(val) => val.id" input-id="id" :label="getLanguage() === 1 ? 'name' : 'nameEn'" />

              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" class="text-center">
              <b-button variant="primary" @click="getcontractsList">
                <span class="text-nowrap">{{ t('Search') }}</span>
              </b-button>
              &nbsp;&nbsp;&nbsp;
              <b-button variant="danger" @click="resetSearch">
                <span class="text-nowrap">{{ t('Clear') }}</span>
              </b-button>

            </b-col>

          </b-row>
        </div>
      </b-card-actions>

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" @input="handlePerPageChange" />
              <label>{{ t('entries') }}</label>

              &nbsp;&nbsp;&nbsp;
              <b-button variant="primary" :to="{ name: 'app-rental-contract-add', params: { id: 0 } }">
                <span class="text-nowrap">{{ t("Add Contract") }}</span>
              </b-button>

            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                <b-button variant="primary" :disabled="!filter" @click="filter = ''">
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table class="position-relative" :items="ContractsList" :fields="fields" :filter="filter" responsive
          :busy="isBusy" primary-key="id" show-empty empty-text="No matching records found" :tbody-tr-class="rowClass">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>{{ t('Loading...') }}</strong>
            </div>
          </template>

          <template #cell(customerNameAr)="data">
            <b-button variant="primary" block :to="{ name: 'app-rental-contract-add', params: { id: data.item.id } }">
              {{ data.value }}
            </b-button>
          </template>
          <template #cell(customerNameEn)="data">
            <b-button variant="primary" block :to="{ name: 'app-rental-contract-add', params: { id: data.item.id } }">
              {{ data.value }}
            </b-button>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" class="cursor-pointer" size="16" />
              </template>



              <!-- <b-dropdown-item @click="InvoicePDF(data.item.id)">
                <feather-icon icon="PrinterIcon" class="mr-50" size="16" />
                Print Invoice
              </b-dropdown-item> -->

              <b-dropdown-item @click="handleDelete(data.item.id)">
                <feather-icon icon="TrashIcon" class="mr-50" size="16" />
                {{ t('Delete') }}
              </b-dropdown-item>

              <b-dropdown-item @click="GetDomesticWorkerFormPdf(data.item.id)">
                <feather-icon icon="FileIcon" class="mr-50" size="16" />
                {{ t('Domestic Worker Form') }}
              </b-dropdown-item>

              <b-dropdown-item @click="generate(data.item.id)">
                <feather-icon icon="FileIcon" class="mr-50" size="16" />
                {{ t('Print Contract') }}
              </b-dropdown-item>

              <b-dropdown-item @click="openUpdateStatusModal(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" size="16" />
                {{ t('Update Status') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>


        <div class="mx-2 mb-2">
          <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" />
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="handlePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal id="update-status-modal" ref="updateStatusModal" :title="t('Update Rental Contract Status')"
      @ok="submitStatusUpdate" no-close-on-backdrop no-close-on-esc :ok-title="t('OK')" :cancel-title="t('Cancel')">
      <b-form @submit.stop.prevent="submitStatusUpdate">
        <b-form-group :label="t('Status Type')">
          <v-select v-model="statusUpdate.statusTypeId" :options="StatusTypeOptions" :reduce="(val) => val.id"
            input-id="id" :label="getLanguage() === 1 ? 'nameAr' : 'name'" />
        </b-form-group>
        <b-form-group :label="t('Reason')">
          <b-form-textarea v-model="statusUpdate.reason" rows="3" max-rows="6" />
        </b-form-group>
        <b-form-group v-if="statusUpdate.statusTypeId === 4" :label="t('New Employee')">

          <v-select v-model="statusUpdate.newEmployeeId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
            :options="EmployeeList" :reduce="val => val.id" :clearable="false" input-id="id"
            :label="getLanguage() === 1 ? 'employeeName' : 'employeeNameEn'" />
        </b-form-group>
        <b-form-group v-if="statusUpdate.statusTypeId === 4" :label="t('Rent Start Date')">
          <b-form-datepicker v-model="statusUpdate.rentStart" :state="!!statusUpdate.rentStart" />
        </b-form-group>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BTooltip,
  BLink,
  BPagination,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import AES from 'crypto-js/aes'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { vueTopprogress } from 'vue-top-progress'

import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    vueTopprogress,
    BCardActions,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BTooltip,
    BLink,
    BPagination,
    vSelect,
    BSpinner,
    BFormGroup,
    BFormTextarea,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      t,
      filter: null,
      avatarText,
      loading: false,
      validityType: 0,
      dataSearch: {
        ServiceId: 0,
        EmployeeId: 0,
        CustomerId: 0,
      },
      ContractsList: [],
      SocialStatusList: [],
      NationalityList: [],
      ReligionsList: [],
      JobList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
      ArrivedOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'وصل', nameEn: 'Arrived' },
        { id: 2, name: 'لم يصل', nameEn: 'Not Arrived' },
      ],
      WarrantyOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'داخل فترة الضمان', nameEn: 'In Warranty' },
        { id: 2, name: 'خارج فترة الضمان', nameEn: 'Out Warranty' },
      ],
      CancelationSideOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'الشركة', nameEn: 'Company' },
        { id: 2, name: 'العميل', nameEn: 'Client' },
      ],
      PaidOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'تم الدفع', nameEn: 'Paid' },
        { id: 2, name: 'لم يتم الدفع', nameEn: 'UnPaid' },
      ],
      isBusy: false,
      StatusTypeOptions: [
        { id: 1, name: 'Valid', nameAr: 'ساري' },
        { id: 2, name: 'Expired', nameAr: 'منتهي' },
        { id: 3, name: 'Renewed', nameAr: 'مجدد' },
        { id: 4, name: 'Replacement of a worker', nameAr: 'استبدال عامل' },
        { id: 5, name: 'Canceled', nameAr: 'ملغي' }
      ],

      statusUpdate: {
        rentalContractId: 0,
        statusTypeId: null,
        reason: '',
        changedByEmployeeId: 0,
        newEmployeeId: null,
        rentStart: null,
      },
      EmployeeList: [], // Populate this with your employee data
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Contract ID'),
          key: 'id',
          sortable: true,
        },
        {
          label: t('Customer Name'),
          key: api.getLanguage() === 1 ? 'customerNameAr' : 'customerNameEn',
          sortable: true,
        },
        {
          label: t('Social ID'),
          key: 'socialID',
          sortable: true,
        },
        {
          label: t('Customer Mobile No'),
          key: 'customerMobileNO',
          sortable: true,
        },
        {
          label: t('Service'),
          key: api.getLanguage() === 1 ? 'serviceNameAr' : 'serviceNameEn',
          sortable: true,
        },
        {
          label: t('Worker Name'),
          key: api.getLanguage() === 1 ? 'employeeNameAr' : 'employeeNameEn',
          sortable: true,
        },
        {
          label: t('Pricing Type'),
          key: api.getLanguage() === 1 ? 'pricingTypeNameAr' : 'pricingTypeNameEn',
          sortable: true,
        },
        {
          label: t('Numbers'),
          key: 'numberOfMonths',
          sortable: true,
        },
        {
          label: t('Start Date'),
          key: 'rentStart',
          sortable: true,
        },

        {
          label: t('End Date'),
          key: 'rentEnd',
          sortable: true,
        },

        {
          label: t('Contact Amount'),
          key: 'totalContract',
          sortable: true,
        },
        {
          label: t('Vat Amount'),
          key: 'totalValueAdded',
          sortable: true,
        },

        {
          label: t('Amount With VAT'),
          key: 'netContractValue',
          sortable: true,
        },
        {
          label: t('Status'),
          key: api.getLanguage() === 1 ? 'currentStatusNameAr' : 'currentStatusNameEn',
          sortable: true,
        },
        { key: 'actions', label: t('Actions') },
      ]
    },
  },
  mounted() {
    this.getcontractsList()
  },
  methods: {
    getcontractsList() {
      this.$refs.topProgress.start()
      this.isBusy = !this.isBusy
      api.GetRentalContracts(this.currentPage - 1, this.perPage, '', '',
        this.dataSearch.ServiceId, this.dataSearch.EmployeeId, this.dataSearch.CustomerId).then(result => {
          this.ContractsList = result.data.data.pageData
          this.totalRows = result.data.data.filteredCount
          this.isBusy = !this.isBusy
          this.$refs.topProgress.done()
        })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getcontractsList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getcontractsList()
    },
    handleFieldsChange(value) {
      console.log(value)
    },
    getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    getReligionsList() {
      api.getAcceptedOptions(9).then(result => {
        this.ReligionsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    getemployeesdropdown() {
      api.getemployeesdropdown().then(result => {
        this.EmployeeList = result.data.data.pageData
      })
    },
    resetSearch() {
      this.dataSearch = {
        customerName: '',
        socialID: '',
        customerMobileNO: '',
        contractNO: '',
        mosandContractNo: 0,
        workerName: '',
        workerProfessionId: 0,
        workerNationalityId: 0,
        workerReligionId: 0,
        isArrived: 0,
        warranty: 0,
      }
      this.getcontractsList()
    },
    encryptId(str) {
      const ciphertext = AES.encrypt(str.toString(), 'secretPassphrase')
      this.$router.push({ name: 'app-mediation-edit', params: { id: encodeURIComponent(ciphertext.toString()) } })
    },
    PrintInvoice(id) {
      return api.PrintInvoiceForRentalContract(id)
    },
    handleDelete(id) {
      if (confirm('Are you sure you want to delete this contract?')) {
        this.isBusy = true;
        api.deleteRentalContract(id)
          .then(() => {
            this.ContractsList = this.ContractsList.filter(contract => contract.id !== id);
            this.isBusy = false;
            this.$swal({
              title: 'Good job!',
              text: 'Contract has been deleted successfully.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          })
          .catch(error => {
            console.error('There was an error deleting the contract!', error);
            this.isBusy = false;
            this.$swal({
              title: 'Error!',
              text: 'There was an error deleting the employee.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          });
      }
    },
    InvoicePDF(id) {
      return api.InvoicePDF(id)
    },
    GetDomesticWorkerFormPdf(id) {
      return api.GetDomesticWorkerFormPdf(id)
    },
    generate(id) {
      return api.generate(id)
    },
    async submitStatusUpdate() {
      try {
        console.log(this.statusUpdate)


        api.updateRentalContractStatus(this.statusUpdate)
          .then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Status updated successfully.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs.updateStatusModal.hide()
            this.getcontractsList()
          })
          .catch(error => {
            this.isLoading = false
            this.$swal({
              title: error.response.data.title,
              html: Object.values(error.response.data.errors)
                .map(item => `<div>${item}</div>`)
                .join(''),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })



      } catch (error) {
        this.$bvToast.toast('Error updating status', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    openUpdateStatusModal(contract) {
      this.getemployeesdropdown()
      this.statusUpdate = {
        rentalContractId: contract.id,
        statusTypeId: null,
        reason: '',
        changedByEmployeeId: 0,
        newEmployeeId: 0,
        rentStart: contract.rentStart,
      }
      this.$refs.updateStatusModal.show()
    },
    rowClass(item) {

      if (!item || !item.rentEnd) return '';

      const today = new Date();
      const rentEndDate = new Date(item.rentEnd);
      const diffTime = Math.abs(rentEndDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


      // Apply the class for Canceled status first
      if (item.currentStatusId === 5) {
        return 'table-canceled'; // Light Red for Canceled
      }

      // Check if the RentEnd date is within 10 days
      if (diffDays <= 10) {
        return 'table-warning';
      }

    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.my-class .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}

.table-warning {
  background-color: #f5f5bb;
}

.table-valid {
  background-color: #d4edda;
  /* Green for Valid */
}

.table-expired {
  background-color: Red;
  /* Red for Expired */
}

.table-renewed {
  background-color: Blue;
  /* Blue for Renewed */
}

.table-replacement {
  background-color: Yellow;
  /* Yellow for Replacement of a worker */
}

.table-canceled {
  background-color: #e76060;
  color: #d4edda;
  /* Light Red for Canceled */
}
</style>
