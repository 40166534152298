<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("External Office Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- ExternalOfficeName -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.externalOfficeName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- ExternalOfficeNameEn -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Name En')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.externalOfficeNameEn"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- CoordinatorName -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Coordinator Name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.coordinatorName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- CoordinatorNameEn -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Coordinator Name En')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.coordinatorNameEn"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- CoordinatorMobile -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Coordinator Mobile')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.coordinatorMobile"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- CRN -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('CRN')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.crn"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- PhoneNumbers -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Phone Numbers')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-textarea
                          v-model="dataModel.phoneNumbers"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- FaxNumbers -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Fax Numbers')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-textarea
                          v-model="dataModel.faxNumbers"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- OtherDetails -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Other Details')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <quill-editor
                          v-model="dataModel.otherDetails"
                          :options="snowOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Address -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Address')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.address"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Username -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Username')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.username"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Password -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Password')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.password"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mb-75"
                @click.prevent="validationForm"
              >
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-externalOffices-list'}"
              >
                {{ t("Back To List") }}
              </b-button>
              <!-- Spacer -->
              <hr class="invoice-spacing">
              <!-- Field: BranchId -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="t('Branch')"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <v-select
                      v-model="dataModel.branchId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="BranchsList"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'branchName' : 'branchNameEn'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      title="Add New"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BFormTextarea, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    quillEditor,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormTextarea,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      BranchsList: [],
      id: 0,

    }
  },
  mounted() {
    this.getCompaybranchs()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
        const dataModel = {
            externalOfficeName: this.dataModel.externalOfficeName,
            externalOfficeNameEn: this.dataModel.externalOfficeNameEn,
            coordinatorName: this.dataModel.coordinatorName,
            coordinatorNameEn: this.dataModel.coordinatorNameEn,
            coordinatorMobile: this.dataModel.coordinatorMobile,
            crn: this.dataModel.crn,
            phoneNumbers: this.dataModel.phoneNumbers,
            faxNumbers: this.dataModel.faxNumbers,
            otherDetails: this.dataModel.otherDetails,
            address: this.dataModel.address,
            username: this.dataModel.username,
            password: this.dataModel.password,
            branchId: this.dataModel.branchId,
            companyId: api.getCompany(),

          }
          api.CreateExternalOffice(this.id, dataModel).then(result => {
            this.$swal({
              title: 'Good job!',
              text: 'Your details have been saved successfully.',
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

            this.dataModel = {}
            this.$refs.validationRules.reset()
          })
        }
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
