<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row>
          <b-col cols="12" md="12" class="text-right">

            <!-- Action Buttons -->
            <b-button variant="primary" :disabled="isLoading" @click.prevent="validationForm">
              <b-spinner v-if="isLoading" small />
              {{ t("Save Changes") }}
            </b-button>

            &nbsp; &nbsp;
            <b-button variant="danger" :to="{ name: 'app-rental-contract-list' }">
              {{ t("Back To List") }}
            </b-button>

          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="12" xl="12" md="12">

            <b-card>
              <!-- Header -->
              <b-card-body>
                <b-row>
                  <b-col cols="12" md="12">
                    <br>
                    <b-card-title>
                      <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                      <span>{{
                        t("Customer Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: socialID -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Social ID')">
                      <validation-provider #default="{ errors }" :name="t('Social ID')" rules="required|integer">
                        <b-form-input id="socialID" v-model="dataModel.socialID" maxlength="10"
                          :state="errors.length > 0 ? false : null" @change="val => GetBySocialID(val)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: customerMobileNO -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Mobile No')">
                      <validation-provider #default="{ errors }" :name="t('Mobile No')" rules="required|integer">
                        <b-form-input id="customerMobileNO" v-model="dataModel.customerMobileNO" :disabled="IsEditMode"
                          maxlength="12" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Name -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Customer Name')">
                      <validation-provider #default="{ errors }" :name="t('Customer Name')" rules="required">
                        <b-form-input v-model="dataModel.customerName" :disabled="IsEditMode" maxlength="150"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Country -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Nationality')">
                      <validation-provider #default="{ errors }" rules="required" :name="t('Nationality')">
                        <v-select v-model="dataModel.customerCountryId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="NationalityList" :reduce="(val) => val.id" :disabled="IsEditMode" :clearable="false"
                          input-id="id" :label="getLanguage() === 1 ? 'name' : 'nameEn'" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: RegionId -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Region')">
                      <validation-provider #default="{ errors }" :name="t('Region')" rules="required">
                        <v-select v-model="dataModel.customerRegionId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="RegionList" :reduce="(val) => val.id" :clearable="false" :disabled="IsEditMode"
                          input-id="id" :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: City -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('City')">
                      <validation-provider #default="{ errors }" :name="t('City')" rules="required">
                        <v-select v-model="dataModel.customerCityId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="CityList" :reduce="val => val.id" :clearable="false" :disabled="IsEditMode"
                          input-id="id" :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                          @click.native="getCitiesList(dataModel.customerRegionId)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: customerGenderId -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Gender')">
                      <validation-provider #default="{ errors }" :name="t('Gender')" rules="required">
                        <v-select v-model="dataModel.customerGenderId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="GenderList" :reduce="(val) => val.id" :clearable="false" :disabled="IsEditMode"
                          input-id="id" :label="getLanguage() === 1 ? 'name' : 'nameEn'" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: customerProfession -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Profession')">
                      <validation-provider #default="{ errors }" :name="t('Profession')" rules="required">
                        <b-form-input v-model="dataModel.customerProfession" maxlength="100"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Address -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Address')">
                      <validation-provider #default="{ errors }" :name="t('Address')" rules="required">
                        <b-form-input v-model="dataModel.customerAddress" maxlength="100"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col cols="12" md="12">
                    <br>
                    <b-card-title>
                      <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                      <span>{{
                        t("Service Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Contract No -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Service')">
                      <validation-provider #default="{ errors }" rules="required" :name="t('Service')">
                        <v-select v-model="dataModel.serviceId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ServicesList" :reduce="val => val.id" :clearable="false" input-id="id"
                          :label="getLanguage() === 1 ? 'serviceName' : 'serviceNameEn'" @input="getservicebyid" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Contract Value -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Price Wo VAT')">
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input v-model="dataModel.priceWoVAT" disabled
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VAT Ratio -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('VAT Ratio')">
                      <validation-provider #default="{ errors }" rules="required">

                        <b-form-input v-model="dataModel.vatValue" disabled :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: amountWithVAT -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Total With VAT')">
                      <validation-provider #default="{ errors }" rules="required">

                        <b-form-input v-model="dataModel.priceWVAT" disabled
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Start Date')">
                      <validation-provider #default="{ errors }" rules="required" :name="t('Start Date')">
                        <flat-pickr v-model="dataModel.rentStart" :config="flatpickrConfig"
                          :state="errors.length > 0 ? false : null" class="form-control invoice-edit-input"
                          @input="calculateDuration" />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('End Date')">
                      <validation-provider #default="{ errors }" rules="required" :name="t('End Date')">
                        <flat-pickr v-model="dataModel.rentEnd" :config="flatpickrConfig"
                          :state="errors.length > 0 ? false : null" class="form-control invoice-edit-input"
                          @input="calculateDuration" />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group :label="getLabel()">
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input v-model="dataModel.numberOfMonths" disabled
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Contract Value -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Total Contract')">
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input v-model="dataModel.totalContract" disabled
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VAT Ratio -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Total Value Added')">
                      <validation-provider #default="{ errors }" rules="required">

                        <b-form-input v-model="dataModel.totalValueAdded" disabled
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: amountWithVAT -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Net Contract Value')">
                      <validation-provider #default="{ errors }" rules="required">

                        <b-form-input v-model="dataModel.netContractValue" disabled
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col cols="12" md="12">
                    <br>
                    <b-card-title>
                      <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                      <span>{{
                        t("Required Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Worker Name -->
                  <b-col cols="12" md="4">
                    <b-form-group :label="t('Worker Name')">

                      <validation-provider #default="{ errors }" rules="required" :name="t('Worker Name')">

                        <v-select v-model="dataModel.employeeId" :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="EmployeeList" :reduce="val => val.id" :clearable="false" input-id="id"
                          :label="getLanguage() === 1 ? 'employeeName' : 'employeeNameEn'" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col cols="12" md="12">
                    <br>
                    <b-card-title>
                      <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                      <span>{{
                        t("Other Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- Field: Additional Terms -->
                  <b-col cols="12" md="12">
                    <b-form-group :label="t('Additional Terms')">

                      <quill-editor v-model="dataModel.additionalTerms" :options="snowOption" />

                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>

        </b-row>
      </validation-observer>
    </b-form>

  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCardBody, BCardTitle,
  VBTooltip,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { vueTopprogress } from 'vue-top-progress'
import vSelect from 'vue-select'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    vueTopprogress,
    quillEditor,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    flatPickr,
    BCardBody,
    BCardTitle,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    const { getValidationState } = formValidation(this.dataModel)
    const { getValidationStatebond } = formValidation(this.bondModel)
    const { getValidationStatetransferToAccounts } = formValidation(this.transferToAccountsModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      getValidationStatebond,
      getValidationStatetransferToAccounts,
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      DataList: [],
      dataModel: {
        serviceId: '',
        socialID: '',
        customerMobileNO: '',
        customerName: '',
        customerCountryId: '',
        customerRegionId: '',
        customerCityId: '',
        customerGenderId: '',
        customerProfession: '',
        customerAddress: '',
        employeeId: '',
        rentStart: new Date(),
        rentEnd: new Date(),
        pricingTypeId: 1,
        priceWoVAT: 0,
        vatValue: 0,
        priceWVAT: 0,

        numberOfMonths: 0,
        totalContract: 0,
        totalValueAdded: 0,
        netContractValue: 0,
        companyId: api.getCompany(),
        branchId: 0,
      },

      id: 0,
      RegionList: [],
      CityList: [],
      DistrictList: [],
      TitlesList: [],
      BankList: [],
      AccessMethodList: [],
      SocialStatusList: [],
      GenderList: [],
      NationalityList: [],
      VisaTypeList: [],
      BranchsList: [],
      acceptedId: 0,
      ReligionsList: [],
      JobList: [],
      ArrivalDestinationList: [],
      ArrivalAirportList: [],
      ExperienceList: [],
      HousingTypeList: [],
      AppearanceList: [],
      AlMaqqabList: [],
      MediatorList: [],
      AgentList: [],
      CustomersList: [],
      ServicesList: [],
      ExternalOfficeList: [],
      errorsList: [],
      DataListExchange: [],
      isLoading: false,
      filter: null,
      filterExchange: null,
      totalRowsExchange: 1,

      perPageExchange: 5,
      totalRows: 1,
      currentPageExchange: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      flatpickrConfig: {
        enableTime: false, // Include time picker
        dateFormat: 'Y-m-d', // Format for date and time
      },
      AccountsList: [],
      EmployeeList: [],
      CostCenterList: [],
      CurrencyList: [],
      InvoicesList: [],
      CustomerId: 0,
      totalAmount: 0,
      totalExchangeAmount: 0,
      IsBond: false,
      IsEditMode: false,
      priceTypeDurations: {
        6: { unit: 'month', value: 1 },    // 1 Month
        4: { unit: 'month', value: 3 },    // 3 Months
        5: { unit: 'month', value: 6 },    // 6 Months
        7: { unit: 'year', value: 1 },     // 1 Year
        1: { unit: 'month', value: 1 },    // Monthly
        2: { unit: 'day', value: 1 },      // Daily
        3: { unit: 'hour', value: 1 },     // Hourly
      },
    }
  },
  computed: {

  },
  watch: {
    // eslint-disable-next-line func-names
    'dataModel.pricingTypeId': function (newVal) {
      // Adjust flatpickrConfig based on pricingTypeId
      if (newVal === 3) {
        this.$set(this.flatpickrConfig, 'enableTime', true)
        this.$set(this.flatpickrConfig, 'dateFormat', 'Y-m-d H:i')
      } else {
        this.$set(this.flatpickrConfig, 'enableTime', false)
        this.$set(this.flatpickrConfig, 'dateFormat', 'Y-m-d')
      }
    },
  },
  mounted() {
    this.init()
    this.calculateDuration()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.isLoading = true

          api.AddEditRentalContract(router.currentRoute.params.id, this.dataModel)
            .then(result => {
              this.$swal({
                title: this.$t('Good job!'),
                text: this.$t('Your details have been saved successfully.'),
                icon: result.data.httpCode === 200 ? 'success' : 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.PrintInvoice(result.data.data)
              this.$router.push({ name: 'app-rental-contract-list' })

              this.isLoading = false
              this.$refs.validationRules.reset()
            })
            .catch(error => {
              this.isLoading = false
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getLabel() {
      switch (this.dataModel.pricingTypeId) {
        case 1:
          return this.$t('Number Of Months')
        case 2:
          return this.$t('Number Of Days')
        case 3:
          return this.$t('Number Of Hours')
        default:
          return this.$t('Number Of Months')
      }
    },
    calculateDuration2() {
      const start = new Date(this.dataModel.rentStart)
      const end = new Date(this.dataModel.rentEnd)

      // Calculate the difference in days
      const diffInDays = Math.floor((end - start) / (24 * 60 * 60 * 1000))

      console.log('diffInDays', diffInDays)
      console.log('pricingTypeId', this.dataModel.pricingTypeId)

      // Depending on pricingTypeId, calculate duration
      switch (this.dataModel.pricingTypeId) {
        case 1:
          // Pricing Type 1: Monthly
          // eslint-disable-next-line no-case-declarations
          const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth())
          this.$set(this.dataModel, 'numberOfMonths', diffInMonths)
          break
        case 2:
          // Pricing Type 2: Daily
          this.$set(this.dataModel, 'numberOfMonths', diffInDays + 1)
          break
        case 3:
          // Pricing Type 3: Hourly
          // eslint-disable-next-line no-case-declarations
          const diffInHours = Math.floor((end - start) / (60 * 60 * 1000))
          this.$set(this.dataModel, 'numberOfMonths', diffInHours)
          break
        default:
          break
      }

      console.log('numberOfMonths', this.dataModel.numberOfMonths)

      this.dataModel.totalContract = parseFloat(this.dataModel.priceWoVAT) * parseFloat(this.dataModel.numberOfMonths)
      console.log('priceWoVAT', this.dataModel.priceWoVAT)
      console.log('totalContract', this.dataModel.totalContract)

      // Check if totalContract is NaN and set it to 0 if true
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.dataModel.totalContract)) {
        this.dataModel.totalContract = 0
      }
      console.log('totalContract2', this.dataModel.totalContract)
      // eslint-disable-next-line no-mixed-operators
      this.dataModel.totalValueAdded = parseFloat(this.dataModel.totalContract) * parseFloat(this.dataModel.vatValue) / 100

      // Check if totalValueAdded is NaN and set it to 0 if true
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.dataModel.totalValueAdded)) {
        this.dataModel.totalValueAdded = 0
      }

      this.dataModel.netContractValue = parseFloat(this.dataModel.totalContract) + parseFloat(this.dataModel.totalValueAdded)

      // Check if netContractValue is NaN and set it to 0 if true
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.dataModel.netContractValue)) {
        this.dataModel.netContractValue = 0
      }
    },
    calculateDuration() {
      const start = new Date(this.dataModel.rentStart);
      let end = new Date(start);

      const priceType = this.dataModel.pricingTypeId;
      const duration = this.priceTypeDurations[priceType];

      if (!duration) {
        console.error('Unknown price type:', priceType);
        return;
      }

      switch (duration.unit) {
        case 'day':
          end.setDate(end.getDate() + duration.value);
          break;
        case 'month':
          end.setMonth(end.getMonth() + duration.value);
          break;
        case 'year':
          end.setFullYear(end.getFullYear() + duration.value);
          break;
        case 'hour':
          end.setHours(end.getHours() + duration.value);
          break;
        default:
          console.error('Unknown duration unit:', duration.unit);
          return;
      }

      this.dataModel.rentEnd = end.toISOString();
      console.log('Updated rent end date:', this.dataModel.rentEnd);

      // Calculate the difference in days
      const diffInDays = Math.floor((end - start) / (24 * 60 * 60 * 1000));

      console.log('diffInDays', diffInDays);
      console.log('pricingTypeId', this.dataModel.pricingTypeId);

      // Depending on pricingTypeId, calculate duration
      switch (this.dataModel.pricingTypeId) {
        case 1:
          // Pricing Type 1: Monthly
          const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
          this.$set(this.dataModel, 'numberOfMonths', diffInMonths);
          break;
        case 2:
          // Pricing Type 2: Daily
          this.$set(this.dataModel, 'numberOfMonths', diffInDays + 1);
          break;
        case 3:
          // Pricing Type 3: Hourly
          const diffInHours = Math.floor((end - start) / (60 * 60 * 1000));
          this.$set(this.dataModel, 'numberOfMonths', diffInHours);
          break;
        case 4:
        case 5:
        case 6:
        case 7:
          this.$set(this.dataModel, 'numberOfMonths', duration.value);
          break;
        default:
          break;
      }

      console.log('numberOfMonths', this.dataModel.numberOfMonths);

      this.dataModel.totalContract = parseFloat(this.dataModel.priceWoVAT) * parseFloat(this.dataModel.numberOfMonths);
      console.log('priceWoVAT', this.dataModel.priceWoVAT);
      console.log('totalContract', this.dataModel.totalContract);

      // Check if totalContract is NaN and set it to 0 if true
      if (isNaN(this.dataModel.totalContract)) {
        this.dataModel.totalContract = 0;
      }
      console.log('totalContract2', this.dataModel.totalContract);

      this.dataModel.totalValueAdded = parseFloat(this.dataModel.totalContract) * parseFloat(this.dataModel.vatValue) / 100;

      // Check if totalValueAdded is NaN and set it to 0 if true
      if (isNaN(this.dataModel.totalValueAdded)) {
        this.dataModel.totalValueAdded = 0;
      }

      this.dataModel.netContractValue = parseFloat(this.dataModel.totalContract) + parseFloat(this.dataModel.totalValueAdded);

      // Check if netContractValue is NaN and set it to 0 if true
      if (isNaN(this.dataModel.netContractValue)) {
        this.dataModel.netContractValue = 0;
      }
    },
    getRegionList() {
      api.getRegionList().then(result => {
        this.RegionList = result.data.data.pageData
      })
    },
    getCitiesList(parentId) {
      api.getCitiesList(parentId).then(result => {
        this.CityList = result.data.data.pageData
      })
    },
    getDistrictsList(parentId) {
      api.getDistrictsList(parentId).then(result => {
        this.DistrictList = result.data.data.pageData
      })
    },

    getGenderList() {
      api.getAcceptedOptions(7).then(result => {
        this.GenderList = result.data.data.pageData
      })
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    getReligionsList() {
      api.getAcceptedOptions(9).then(result => {
        this.ReligionsList = result.data.data.pageData
      })
    },
    getemployeesdropdown() {
      api.getemployeesdropdown().then(result => {
        this.EmployeeList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      this.acceptedId = id
    },

    updateContactAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.dataModel.vatAmount = parseFloat(val) * parseFloat(this.dataModel.vatPercentage === '' ? 0 : this.dataModel.vatPercentage) / 100

      this.dataModel.amountWithVAT = parseFloat(val)
        + parseFloat(this.dataModel.vatAmount === '' ? 0 : this.dataModel.vatAmount)

      this.dataModel.amountDue = parseFloat(this.dataModel.amountWithVAT === '' ? 0 : this.dataModel.amountWithVAT)
        + parseFloat(this.dataModel.musanedFee === '' ? 0 : this.dataModel.musanedFee)
    },
    updateBondAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.bondModel.vatValue = parseFloat(val) * parseFloat(this.bondModel.vatRatio === '' ? 0 : this.bondModel.vatRatio) / 100

      this.bondModel.amountWithVat = parseFloat(this.bondModel.amount) + parseFloat(this.bondModel.vatValue)

      // eslint-disable-next-line no-mixed-operators
      this.bondExchangeModel.vatValue = parseFloat(val) * parseFloat(this.bondExchangeModel.vatRatio === '' ? 0 : this.bondExchangeModel.vatRatio) / 100

      this.bondExchangeModel.amountWithVat = parseFloat(this.bondExchangeModel.amount) + parseFloat(this.bondExchangeModel.vatValue)
    },
    updateBondVatAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.bondModel.vatValue = parseFloat(val) * parseFloat(this.bondModel.amount === '' ? 0 : this.bondModel.amount) / 100

      this.bondModel.amountWithVat = parseFloat(this.bondModel.amount) + parseFloat(this.bondModel.vatValue)

      // eslint-disable-next-line no-mixed-operators
      this.bondExchangeModel.vatValue = parseFloat(val) * parseFloat(this.bondExchangeModel.amount === '' ? 0 : this.bondExchangeModel.amount) / 100

      this.bondExchangeModel.amountWithVat = parseFloat(this.bondExchangeModel.amount) + parseFloat(this.bondExchangeModel.vatValue)
    },
    PrintInvoice(id) {
      return api.PrintInvoice(id)
    },
    updateVatAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.dataModel.vatAmount = parseFloat(val) * parseFloat(this.dataModel.contactAmount === '' ? 0 : this.dataModel.contactAmount) / 100

      this.dataModel.amountWithVAT = parseFloat(this.dataModel.contactAmount === '' ? 0 : this.dataModel.contactAmount)
        + parseFloat(this.dataModel.vatAmount === '' ? 0 : this.dataModel.vatAmount)

      this.dataModel.amountDue = parseFloat(this.dataModel.amountWithVAT === '' ? 0 : this.dataModel.amountWithVAT)
        + parseFloat(this.dataModel.musanedFee === '' ? 0 : this.dataModel.musanedFee)
    },

    updateMusanedFee(val) {
      this.dataModel.amountDue = parseFloat(this.dataModel.amountWithVAT === '' ? 0 : this.dataModel.amountWithVAT)
        + parseFloat(val === '' ? 0 : val)
    },
    GetBySocialID(val) {
      api.GetBySocialID(val).then(result => {
        console.log(result.data.data)
        console.log(result.data.data.regionId)
        this.dataModel.customerMobileNO = result.data.data === null ? '' : result.data.data.mobileNo
        this.dataModel.customerName = result.data.data === null ? '' : result.data.data.customerName
        this.dataModel.customerCountryId = result.data.data === null ? '' : result.data.data.nationalityId
        this.dataModel.customerRegionId = result.data.data === null ? '' : result.data.data.regionId
        this.getCitiesList(result.data.data === null ? '' : result.data.data.regionId)
        this.dataModel.customerCityId = result.data.data === null ? '' : result.data.data.cityId
        this.dataModel.customerGenderId = result.data.data === null ? '' : result.data.data.sexId
        this.dataModel.customerProfession = result.data.data === null ? '' : result.data.data.job
      })
    },
    getDataList(id) {
      if (id !== 0) {
        api.getBondList(0, 0, '', '0', '', api.getCompany(), 0, 1,
          id, 1).then(result => {
            this.DataList = result.data.data.pageData
            this.totalRows = result.data.data.filteredCount
            this.totalAmount = result.data.data.pageData
              .map(({ amount }) => amount)
              .reduce((a, b) => a + b, 0)
          })
        api.getBondList(0, 0, '', '0', '', api.getCompany(), 0, 1,
          id, 2).then(result => {
            this.DataListExchange = result.data.data.pageData
            this.totalRowsExchange = result.data.data.filteredCount
            this.totalExchangeAmount = result.data.data.pageData
              .map(({ amount }) => amount)
              .reduce((a, b) => a + b, 0)
          })
        api.GetAllInvoiceForContract(id).then(result => {
          this.InvoicesList = result.data.data.pageData
        })
      }
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getDataList()
    },
    handlePageChangeExchange(value) {
      this.currentPageExchange = value
      this.getDataList()
    },
    handlePerPageChangeExchange(value) {
      this.perPageExchange = value
      this.getDataList()
    },
    getservicebyid(id) {
      api.getservicebyid(id).then(result => {
        console.log('priceType', result.data.data.priceType)
        this.dataModel.pricingTypeId = result.data.data.priceType
        this.dataModel.priceWoVAT = result.data.data.priceWoVAT
        this.dataModel.vatValue = result.data.data.vatValue
        this.dataModel.priceWVAT = result.data.data.priceWVAT
        this.calculateDuration()
      })
    },

    init() {
      this.getCompaybranchs()
      this.getGenderList()
      this.getNationalityList()
      this.getJobList()
      this.getReligionsList()
      this.getRegionList()
      this.getservicedropdown()
      this.getemployeesdropdown()
    },

    getservicedropdown() {
      api.getservicedropdown().then(result => {
        this.ServicesList = result.data.data.pageData
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.add-new {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
