<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- Field: Arrival Date -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Arrival Date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Arrival Date')"
                        rules="required"
                      >

                        <date-picker
                          v-model="dataModel.arrivalDate"
                          :state="errors.length > 0 ? false : null"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          type="date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: contractStatusId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Contract Status')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.contractStatusId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="ContractStatusList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getContractStatusList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(25)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Arrival Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Entry No"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.entryNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Entry Date (Hijri)"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <date-picker
                          v-model="dataModel.entryDateHijri"
                          :state="errors.length > 0 ? false : null"
                          :locale="'ar-SA'"
                          :calendar="'hijri'"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Passport No"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.passportNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: RegistrationDate -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Issue Date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Issue Date')"
                        rules="required"
                      >

                        <date-picker
                          v-model="dataModel.issueDate"
                          :state="errors.length > 0 ? false : null"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          type="date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="BirthDate"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <date-picker
                          v-model="dataModel.birthDate"
                          :state="errors.length > 0 ? false : null"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          type="date"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Full Name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.fullName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Full Name En"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.fullNameEn"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Recipient Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="City"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.city"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Airport"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.airport"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Recipient Name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.recipientName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="As A"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.asA"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Flight Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Flight No"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.flightNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: FlightDate -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Flight Date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Flight Date')"
                        rules="required"
                      >

                        <date-picker
                          v-model="dataModel.flightDate"
                          :state="errors.length > 0 ? false : null"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          type="date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: الطيران -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Flight"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.flight"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Ticket No"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.ticketNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Notes")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- Notes -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Notes')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Notes')"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="dataModel.notes"
                          :state="errors.length > 0 ? false : null"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mb-75"
                @click.prevent="validationForm"
              >
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-contracts-list'}"
              >
                {{ t("Back To List") }}
              </b-button>
            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      title="Add New"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BFormTextarea, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import VueDatetimeJs from 'vue-datetime-js'
import vSelect from 'vue-select'
import AddAcceptedValue from '../../../components/AddAcceptedValue.vue'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    AddAcceptedValue,
    datePicker: VueDatetimeJs,
    BFormTextarea,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      BranchsList: [],
      ContractStatusList: [],
      JobList: [],
      id: 0,
      acceptedId: 0,

    }
  },
  mounted() {
    this.getbycontractId()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
        const dataModel = {
            contractId: router.currentRoute.params.id,
            arrivalDate: this.dataModel.arrivalDate,
            entryNo: this.dataModel.entryNo,
            entryDateHijri: this.dataModel.entryDateHijri,
            passportNo: this.dataModel.passportNo,
            issueDate: this.dataModel.issueDate,
            birthDate: this.dataModel.birthDate,
            fullName: this.dataModel.fullName,
            fullNameEn: this.dataModel.fullNameEn,
            city: this.dataModel.city,
            airport: this.dataModel.airport,
            recipientName: this.dataModel.recipientName,
            asA: this.dataModel.asA,
            flightNo: this.dataModel.flightNo,
            flightDate: this.dataModel.flightDate,
            flight: this.dataModel.flight,
            ticketNo: this.dataModel.ticketNo,
            notes: this.dataModel.notes,
            contractStatusId: this.dataModel.contractStatusId,
          }
          api.CreateUpdateemploymentarrival(this.id, dataModel).then(result => {
            this.$swal({
              title: 'Good job!',
              text: 'Your details have been saved successfully.',
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
    getContractStatusList() {
      api.getAcceptedOptions(25).then(result => {
        this.ContractStatusList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      console.log('1', id)
      this.acceptedId = id
    },
    getbycontractId() {
      api.getbycontractId(router.currentRoute.params.id).then(result => {
        this.id = result.data.data.id
        this.dataModel = result.data.data
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
