<template>
  <section>
    <div>
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-new
                variant="primary"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">
                  {{ t('Add New') }}
                </span>
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"
          :items="AcceptedOptionsList"
          :fields="fields"
          :filter="filter"
          :current-page="currentPage"
          :per-page="perPage"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(name)="data">
            <b-link
              v-b-modal.modal-edit
              class="font-weight-bold"
              @click="SetId(data.item.id)"
            >
              {{ data.value }}
            </b-link>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-edit
                @click="SetId(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ t('Edit') }}</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
        <hr class="invoice-spacing">
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="لكل صفحة"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="left"
              class="mt-2"
            >
              <template
                #first-text
              >
                <span class="text-success">الاول</span>
              </template>
              <template #prev-text>
                <span class="text-danger">السابق</span>
              </template>
              <template #next-text>
                <span class="text-warning">التالي</span>
              </template>
              <template #last-text>
                <span class="text-info">الاخير</span>
              </template>
              <template #ellipsis-text>
                <div>
                  <b-spinner
                    small
                    type="grow"
                  />
                  <b-spinner
                    small
                    type="grow"
                  />
                </div>
              </template>
              <template #page="{ page, active }">
                <b v-if="active">{{ page }}</b>
                <i v-else>{{ page }}</i>
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      :title="t('Add New')"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedValueId"
        @refetch-data="getAcceptedOptionsList"
      />

    </b-modal>
    <b-modal
      id="modal-edit"
      :hide-footer="true"
      centered
      size="lg"
      title="Edit"
    >
      <add-accepted-Value
        :id="id"
        :parent-id="0"
        :accepted-value-id="acceptedValueId"
        @refetch-data="getAcceptedOptionsList"
      />

    </b-modal>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BLink,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import AddAcceptedValue from './AddAcceptedValue.vue'
import api from '../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BLink,
    BPagination,
    BFormSelect,
    AddAcceptedValue,
  },
  props: {
    acceptedValueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      t,
      filter: null,
      validityType: 0,
      fields: [

        {
          label: 'الاسم',
          key: 'name',
          sortable: true,
        },
        {
          label: 'الاسم انجليزي',
          key: 'nameEn',
          sortable: true,
        },
        { key: 'actions', label: 'التفاصيل' },
      ],
      AcceptedOptionsList: [],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      acceptedValue2: this.acceptedValueId,
      id: 0,
      companyId: api.getCompany(),
    }
  },
  mounted() {
    console.log(this.acceptedValue2)
    this.getAcceptedOptionsList()
  },
  methods: {
    getAcceptedOptionsList() {
      api.getAcceptedOptionsList(0, 0, '', '', '', api.getCompany(), 0, this.acceptedValueId).then(result => {
        this.AcceptedOptionsList = result.data.data.pageData
        this.totalRows = this.AcceptedOptionsList.length
      })
    },
    SetId(id) {
      this.id = id
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
