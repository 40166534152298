<template>
  <section>
    <div>
      <vue-topprogress ref="topProgress" />
      <b-card>
        <b-card-header class="pb-50">
          <h5>
            {{ t('Filters') }}
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>

            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ t('Name') }}</label>
              <div class="d-flex align-items-center justify-content-end">

                <b-form-input
                  id="search"
                  v-model="dataSearch.name"
                />
                      &nbsp;&nbsp;&nbsp;
                <b-button
                  variant="primary"
                  @click="LoadData"
                >
                  <span class="text-nowrap">{{ t('Search') }}</span>
                </b-button>
                &nbsp;&nbsp;&nbsp;
                <b-button
                  variant="danger"
                  @click="Clear"
                >
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t('entries') }}</label>

                      &nbsp;&nbsp;&nbsp;

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  :to="{ name: 'app-portal-addservicedetails', params: { id: 0 } }"
                >
                  <span class="text-nowrap">{{ t('Add Details') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="DataList"
          :fields="fields"
          :filter="filter"
          responsive=""
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(id)="data">
            <b-link
              :to="{ name: 'app-portal-addservicedetails', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              #{{ data.value }}
            </b-link>
          </template>

          <!-- Column: Status -->
          <template #cell(isPublish)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.isPublish)}`"
              class="text-capitalize"
            >
              {{ data.item.isPublish === true ? 'نشط' : 'غير نشط' }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({ name: 'app-portal-addservicedetails', params: { id: data.item.id }})"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BLink,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import { vueTopprogress } from 'vue-top-progress'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    vSelect,
    vueTopprogress,
    BBadge,
  },
  setup() {

  },
  data() {
    return {
      t,
      filter: null,
      // Sidebar
      isAddEditCountrySidebarActive: false,
      validityType: 0,
      dataSearch: {
        name: '',
      },
      DataList: [],
      statusList: [
        { value: 0, label: 'الكل' },
        { value: 1, label: 'نشط' },
        { value: 2, label: 'غير نشط' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Display Order'),
          key: 'displayOrder',
          sortable: true,
        },
        {
          label: t('Title'),
          key: 'title',
          sortable: true,
        },
        {
          label: t('Publish'),
          key: 'isPublish',
          sortable: true,
        },
        { key: 'actions', label: t('Options') },
      ]
    },
  },
  mounted() {
    this.LoadData()
  },
  methods: {
    LoadData() {
      this.$refs.topProgress.start()
      api.GetPortalServicesDetails(this.currentPage - 1, this.perPage, '', '', this.dataSearch.name, 1).then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.$refs.topProgress.done()
      })
        .catch(error => {
          this.$refs.topProgress.done()
          this.$swal({
            title: error.response.data.title,
            html: Object.values(error.response.data.errors)
              .map(item => `<div>${item}</div>`)
              .join(''),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    Clear() {
      this.dataSearch.name = ''
      this.LoadData()
    },
    handlePageChange(value) {
      this.currentPage = value
      this.LoadData()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.LoadData()
    },
    getLanguage() {
      return api.getLanguage()
    },
    resolveStatusVariant(status) {
      console.log(status)
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    },
  },
}
</script>
      <style lang="scss" scoped>
      .per-page-selector {
        width: 90px;
      }
      </style>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
      </style>
