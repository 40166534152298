var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validationRules"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('br'),_c('b-card-title',[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.t("External Office Information")))])],1),_c('hr',{staticClass:"invoice-spacing"})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Name')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.externalOfficeName),callback:function ($$v) {_vm.$set(_vm.dataModel, "externalOfficeName", $$v)},expression:"dataModel.externalOfficeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Name En')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.externalOfficeNameEn),callback:function ($$v) {_vm.$set(_vm.dataModel, "externalOfficeNameEn", $$v)},expression:"dataModel.externalOfficeNameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Coordinator Name')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.coordinatorName),callback:function ($$v) {_vm.$set(_vm.dataModel, "coordinatorName", $$v)},expression:"dataModel.coordinatorName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Coordinator Name En')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.coordinatorNameEn),callback:function ($$v) {_vm.$set(_vm.dataModel, "coordinatorNameEn", $$v)},expression:"dataModel.coordinatorNameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Coordinator Mobile')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.coordinatorMobile),callback:function ($$v) {_vm.$set(_vm.dataModel, "coordinatorMobile", $$v)},expression:"dataModel.coordinatorMobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('CRN')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.crn),callback:function ($$v) {_vm.$set(_vm.dataModel, "crn", $$v)},expression:"dataModel.crn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Phone Numbers')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.dataModel.phoneNumbers),callback:function ($$v) {_vm.$set(_vm.dataModel, "phoneNumbers", $$v)},expression:"dataModel.phoneNumbers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Fax Numbers')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.dataModel.faxNumbers),callback:function ($$v) {_vm.$set(_vm.dataModel, "faxNumbers", $$v)},expression:"dataModel.faxNumbers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Other Details')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.dataModel.otherDetails),callback:function ($$v) {_vm.$set(_vm.dataModel, "otherDetails", $$v)},expression:"dataModel.otherDetails"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Address')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.address),callback:function ($$v) {_vm.$set(_vm.dataModel, "address", $$v)},expression:"dataModel.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Username')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.username),callback:function ($$v) {_vm.$set(_vm.dataModel, "username", $$v)},expression:"dataModel.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Password')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.password),callback:function ($$v) {_vm.$set(_vm.dataModel, "password", $$v)},expression:"dataModel.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{staticClass:"invoice-actions mt-md-0 mt-2",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.t("Save Changes"))+" ")]),_c('b-button',{attrs:{"variant":"outline-primary","block":"","to":{ name: 'app-externalOffices-list'}}},[_vm._v(" "+_vm._s(_vm.t("Back To List"))+" ")]),_c('hr',{staticClass:"invoice-spacing"}),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Branch')}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.BranchsList,"reduce":function (val) { return val.id; },"clearable":false,"input-id":"id","label":_vm.getLanguage() === 1 ? 'branchName' : 'branchNameEn'},model:{value:(_vm.dataModel.branchId),callback:function ($$v) {_vm.$set(_vm.dataModel, "branchId", $$v)},expression:"dataModel.branchId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-new","hide-footer":true,"centered":"","size":"lg","title":"Add New"}},[_c('add-accepted-Value',{attrs:{"id":0,"parent-id":0,"accepted-value-id":_vm.acceptedId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }