<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">

        <b-row class="invoice-add">

          <b-col
            cols="12"
            xl="12"
            md="12"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <!-- Step 1: Employee Information -->
                <div v-if="currentStep === 1">

                  <b-row>
                    <!-- ... (Employee Information Form Fields) ... -->
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <br>
                      <b-card-title>
                        <feather-icon
                          icon="InfoIcon"
                          size="16"
                          class="mr-0 mr-sm-50"
                        />
                        <span>{{
                          t("Employee Information")
                        }}</span>
                      </b-card-title>
                      <hr class="invoice-spacing">
                    </b-col>
                    <b-col
                      cols="12"
                      md="12"
                    >

                      <!-- User Avatar & Action Buttons -->
                      <div class="d-flex justify-content-start">
                        <b-avatar
                          :src="imagePath"
                          size="104px"
                          rounded
                        />
                        <div class="d-flex flex-column ml-1">
                          <div class="mb-1">
                            <label class="btn">
                              <input
                                ref="file"
                                type="file"
                                @change="selectFile"
                              >
                            </label>
                          </div>
                        </div>
                      </div>

                    </b-col>
                    <!-- Field: NationalityId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Nationality')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Nationality')"
                          rules="required"
                        >
                          <v-select
                            v-model="dataModel.nationalityId"
                            :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                            :options="NationalityList"
                            :reduce="(val) => val.id"
                            :clearable="false"
                            input-id="id"
                            :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Field: job -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Job')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="t('Job')"
                        >

                          <v-select
                            v-model="dataModel.jobId"
                            :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                            :options="JobList"
                            :reduce="(val) => val.id"
                            :clearable="false"
                            input-id="id"
                            :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Field: ReligionId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Religion')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Religion')"
                          rules="required"
                        >
                          <v-select
                            v-model="dataModel.religionId"
                            :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                            :options="ReligionsList"
                            :reduce="(val) => val.id"
                            :clearable="false"
                            input-id="id"
                            :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Field: SexId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Gender')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Gender')"
                          rules="required"
                        >
                          <v-select
                            v-model="dataModel.sexId"
                            :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                            :options="GenderList"
                            :reduce="(val) => val.id"
                            :clearable="false"
                            input-id="id"
                            :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- EmployeeName -->
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        :label="t('Employee Name')"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="t('Employee Name')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="dataModel.employeeName"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- EmployeeNameEn -->
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        :label="t('Employee Name En')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Employee Name En')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="dataModel.employeeNameEn"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Birthday -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Birth Day')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Birth Day')"
                          rules="required"
                        >
                          <date-picker
                            v-model="dataModel.birthday"
                            :state="errors.length > 0 ? false : null"
                            type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Field: BirthplaceId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Birth place')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Birth place')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="dataModel.birthplace"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Field: SocialStatusId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Social Status')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Social Status')"
                          rules="required"
                        >
                          <v-select
                            v-model="dataModel.socialStatusId"
                            :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                            :options="SocialStatusList"
                            :reduce="(val) => val.id"
                            :clearable="false"
                            input-id="id"
                            :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- MobileNo -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Mobile No')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="t('Mobile No')"
                        >
                          <b-form-input
                            v-model="dataModel.mobileNo"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Field: Hired Date -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Hired Date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="t('Hired Date')"
                        >

                          <date-picker
                            v-model="dataModel.hiredDate"
                            :state="errors.length > 0 ? false : null"
                            type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Field: BranchId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Branch')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="t('Branch')"
                        >
                          <v-select
                            v-model="dataModel.branchId"
                            :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                            :options="BranchsList"
                            :reduce="(val) => val.id"
                            :clearable="false"
                            input-id="id"
                            :label="getLanguage() === 1 ? 'branchName' : 'branchNameEn'"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                           <!-- Fixed Salary -->
                           <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Fixed Salary')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="t('Fixed Salary')"
                        >
                          <b-form-input
                            v-model="dataModel.fixedSalary"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Archived')"
                      >
                      <b-form-checkbox
                        v-model="dataModel.isArchived"
                      />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Available For Rent')"
                      >
                      <b-form-checkbox
                        v-model="dataModel.isAvailableForRent"
                      />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Available For Transfer')"
                      >
                      <b-form-checkbox
                        v-model="dataModel.isAvailableForTransfer"
                      />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <!-- Step 2: Passport Information -->
                <div v-if="currentStep === 2">
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <br>
                      <b-card-title>
                        <feather-icon
                          icon="InfoIcon"
                          size="16"
                          class="mr-0 mr-sm-50"
                        />
                        <span>{{
                          t("Passport Information")
                        }}</span>
                      </b-card-title>
                      <hr class="invoice-spacing">
                    </b-col>
                    <!-- ... (Passport Information Form Fields) ... -->
                    <!-- PassportNumber -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Passport Number')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Passport Number')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="dataModel.passportNumber"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- PassportIssueDate -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Passport Issue Date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Passport Issue Date')"
                          rules="required"
                        >
                          <date-picker
                            v-model="dataModel.passportIssueDate"
                            :state="errors.length > 0 ? false : null"
                            type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- PassportExpiryDate -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Passport Expiry Date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Passport Expiry Date')"
                          rules="required"
                        >
                          <date-picker
                            v-model="dataModel.passportExpiryDate"
                            :state="errors.length > 0 ? false : null"
                            type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Field: PlaceIssueId -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Place Issue')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="t('Place Issue')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="dataModel.placeIssue"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <!-- Step 3: Other Information -->
                <div v-if="currentStep === 3">
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <br>
                      <b-card-title>
                        <feather-icon
                          icon="InfoIcon"
                          size="16"
                          class="mr-0 mr-sm-50"
                        />
                        <span>{{
                          t("Other Information")
                        }}</span>
                      </b-card-title>
                      <hr class="invoice-spacing">
                    </b-col>
                    <!-- Address -->
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        :label="t('Address')"
                      >

                        <b-form-input
                          v-model="dataModel.address"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Mobile No2 -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Mobile No2')"
                      >
                        <b-form-input
                          v-model="dataModel.mobileNo2"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Email -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Email')"
                      >
                        <b-form-input
                          v-model="dataModel.email"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Landline -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Landline')"
                      >
                        <b-form-input
                          v-model="dataModel.landline"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- SponsorName -->
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="t('Sponsor Name')"
                      >
                        <b-form-input
                          v-model="dataModel.sponsorName"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Field: WorkedBeforeId -->
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        :label="t('Worked Before')"
                      >

                        <b-form-input
                          v-model="dataModel.workedBefore"
                        />

                      </b-form-group>
                    </b-col>

                  </b-row>
                </div>

              </b-card-body>
            </b-card>

          </b-col>

          <b-col
            cols="12"
            md="12"
            xl="12"
            class="invoice-actions mt-md-0 mt-2 text-center"
          >
            <b-card>
              <!-- Navigation Buttons -->
              <b-button
                v-if="currentStep > 1"
                variant="outline-primary"
                @click.prevent="prevStep"
              >
                {{ t("Previous") }}

              </b-button>
              &nbsp;
              <b-button
                v-if="currentStep < totalSteps"
                variant="primary"
                @click.prevent="nextStep"
              >
                {{ t("Next") }}

              </b-button>

              &nbsp;
              <b-button
                v-if="currentStep === 3"
                variant="primary"
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ t("Save Changes") }}
              </b-button>
              &nbsp;
              <b-button
                v-if="currentStep === 3"
                variant="outline-primary"
                :to="{ name: 'app-employees-list'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      title="Add New"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
  </section>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import VueDatetimeJs from 'vue-datetime-js'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BAvatar, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import AddAcceptedValue from '../../../components/AddAcceptedValue.vue'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BAvatar,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BSpinner,
    vSelect,
    AddAcceptedValue,
    datePicker: VueDatetimeJs,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      BranchsList: [],
      NationalityList: [],
      ReligionsList: [],
      SocialStatusList: [],
      GenderList: [],
      JobList: [],
      id: 0,
      isLoading: false,
      acceptedId: 0,
      currentStep: 1,
      totalSteps: 3,
      selectedFiles: undefined,
      fileInfos: [],
      filePath: '',
      imagePath: '',
    }
  },
  mounted() {
    this.getCompaybranchs()
    this.getNationalityList()
    this.getJobList()
    this.getReligionsList()
    this.getSocialStatusList()
    this.getGenderList()
    this.getemployee()
  },
  methods: {
    selectFile() {
      this.selectedFiles = this.$refs.file.files
      this.totalFiles = this.selectedFiles.length
      this.upload()
    },
    upload() {
      this.progress = 0
      this.$refs.topProgress.start()
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.selectedFiles.length; index++) {
        this.currentFile = this.selectedFiles.item(index)
        api.UploadCompnyLogo(this.currentFile, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        }, 0)
          .then(response => {
            this.message = response.data.message
            this.filePath = response.data.dbPath
            this.imagePath = api.CompanyLogo(response.data.dbPath)
            this.logoModel = {
              id: 0,
              fileName: response.data.fileName,
              filePath: response.data.dbPath,
              fileSize: response.data.filesize,
              fileExtension: response.data.fileExtension,
              attachmentType: 20,
              companyId: api.getCompany(),
            }
            // eslint-disable-next-line no-unused-vars
            api.AddEditCompanyAttachment(0, this.logoModel).then(result => {
              this.$refs.topProgress.done()
              this.isLoading = false
            })
              .catch(error => {
                this.countFiles = index + 1
                this.$refs.topProgress.done()
                this.isLoading = false
                this.$swal({
                  title: 'error',
                  html: Object.values(error.response.data.errors)
                    .map(item => `<div>${item}</div>`)
                    .join(''),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          })
          .catch(error => {
            this.countFiles = index + 1
            this.$swal({
              title: 'error',
              html: Object.values(error.response.data.errors)
                .map(item => `<div>${item}</div>`)
                .join(''),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.selectedFiles = undefined
          })
      }

      this.selectedFiles = undefined
    },
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
        const dataModel = {
            employeeName: this.dataModel.employeeName,
            employeeNameEn: this.dataModel.employeeNameEn,
            passportNumber: this.dataModel.passportNumber,
            passportIssueDate: this.dataModel.passportIssueDate,
            passportExpiryDate: this.dataModel.passportExpiryDate,
            birthday: this.dataModel.birthday,
            birthplace: this.dataModel.birthplace,
            placeIssue: this.dataModel.placeIssue,
            religionId: this.dataModel.religionId,
            socialStatusId: this.dataModel.socialStatusId,
            sexId: this.dataModel.sexId,
            nationalityId: this.dataModel.nationalityId,
            address: this.dataModel.address,
            mobileNo: this.dataModel.mobileNo,
            mobileNo2: this.dataModel.mobileNo2,
            email: this.dataModel.email,
            landline: this.dataModel.landline,
            workedBefore: this.dataModel.workedBefore,
            jobId: this.dataModel.jobId,
            hiredDate: this.dataModel.hiredDate,
            sponsorName: this.dataModel.sponsorName,
            branchId: this.dataModel.branchId,
            isArchived: this.dataModel.isArchived,
            isAvailableForRent: this.dataModel.isAvailableForRent,
            isAvailableForTransfer: this.dataModel.isAvailableForTransfer,
            companyId: api.getCompany(),
            imagePath: this.imagePath,
            fixedSalary: this.dataModel.fixedSalary

          }
          api.CreateUpdateEmployee(router.currentRoute.params.id, dataModel).then(result => {
            this.$swal({
              title: 'Good job!',
              text: 'Your details have been saved successfully.',
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        // eslint-disable-next-line no-plusplus
        this.currentStep++
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        // eslint-disable-next-line no-plusplus
        this.currentStep--
      }
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    getReligionsList() {
      api.getAcceptedOptions(9).then(result => {
        this.ReligionsList = result.data.data.pageData
      })
    },
    getSocialStatusList() {
      api.getAcceptedOptions(6).then(result => {
        this.SocialStatusList = result.data.data.pageData
      })
    },
    getGenderList() {
      api.getAcceptedOptions(7).then(result => {
        this.GenderList = result.data.data.pageData
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      console.log('1', id)
      this.acceptedId = id
    },
    getemployee() {
      api.getemployee(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
        this.imagePath = result.data.data.imagePath
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
