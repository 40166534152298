<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              title=""
            >
              <!-- Header -->
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Contract Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- Field: Visa Type -->
                  <b-col
                    cols="12"
                    md="6"
                  >

                    <validation-provider
                      #default="{ errors }"
                      :name="t('Visa Type')"
                      rules="required"
                    >
                      <b-form-group
                        :label="t('Visa Type')"
                        :state="errors.length > 0 ? false:null"
                      >
                        <v-select
                          v-model="dataModel.visaTypeId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="VisaTypeList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getVisaTypeList()"
                        >

                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(16)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                  </b-col>
                  <b-col />
                  <!-- Customer -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Customer')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Customer')"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.customerId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="CustomersList"
                          :reduce="val => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'customerName' : 'customerNameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: RegistrationDate -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Registration Date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Registration Date')"
                        rules="required"
                      >

                        <date-picker
                          v-model="dataModel.registrationDate"
                          :state="errors.length > 0 ? false : null"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          type="date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Financial Data")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Service -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Service"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.serviceId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ServicesList"
                          :reduce="val => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'serviceName' : 'serviceNameEn'"
                          @input="getservicebyid"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: PriceBDiscount -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Price Wo VAT"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.priceBDiscount"
                          :state="errors.length > 0 ? false : null"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VATValue -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="VATValue"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >

                        <b-form-input
                          v-model="dataModel.vatValue"
                          :state="errors.length > 0 ? false : null"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: MusanedFee -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Musaned Fee"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >

                        <b-form-input
                          v-model="dataModel.musanedFee"
                          :state="errors.length > 0 ? false : null"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: TotalWVAT -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Total With VAT"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >

                        <b-form-input
                          v-model="dataModel.totalWVAT"
                          :state="errors.length > 0 ? false : null"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Visa Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- Field: VisaNumber -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Visa Number"
                    >
                      <b-form-input
                        v-model="dataModel.visaNumber"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: BeneficiariesCount -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Beneficiaries Count"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="regex:^([0-9]+)$"
                      >

                        <b-form-input
                          v-model="dataModel.beneficiariesCount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VisaDateHijri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Visa Date (Hijri)"
                    >
                      <date-picker
                        v-model="dataModel.visaDateHijri"
                        :locale="'ar-SA'"
                        :calendar="'hijri'"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: VisaDate (Gregorian) -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Visa Date (Gregorian)"
                    >

                      <date-picker
                        v-model="dataModel.visaDateGregorian"
                        type="date"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: JobId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Job"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.jobId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="JobList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getJobList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(11)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col />
                  <!-- Field: NationalityId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Nationality"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.nationalityId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="NationalityList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getNationalityList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(8)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ReligionId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Religion"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.religionId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ReligionsList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getReligionsList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(9)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ArrivalDestinationId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Arrival Destination"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.arrivalDestinationId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ArrivalDestinationList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getArrivalDestinationList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(17)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ArrivalAirportId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Arrival Airport"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.arrivalAirportId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ArrivalAirportList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getArrivalAirportList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(18)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: AgeOf -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Age Of"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.ageOf"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: AgeTo -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Age To"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.ageTo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ContractDuration -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Contract Duration"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.contractDuration"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: CompletionPeriod -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Completion Period"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.completionPeriod"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ExperienceId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Experience"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.experienceId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ExperienceList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getExperienceList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(19)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Musaned Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Musaned Authorization No -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Musaned Authorization No"
                    >
                      <b-form-input
                        v-model="dataModel.musanedAuthorizationNo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col />
                  <!-- Field: MusanedAuthorizationDateHijri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Musaned Authorization Date (Hijri)"
                    >
                      <date-picker
                        v-model="dataModel.musanedAuthorizationDateHijri"
                        :locale="'ar-SA'"
                        :calendar="'hijri'"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Musaned Authorization Date (Gregorian) -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Musaned Authorization Date (Gregorian)"
                    >

                      <date-picker
                        v-model="dataModel.musanedAuthorizationDataGregorian"
                        type="date"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Work Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: WorkCity -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Work City"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.workCity"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: workNeighborhood -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Work Neighborhood"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.workNeighborhood"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: WorkStreet -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Work Street"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.workStreet"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ResponsiblePerson -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Responsible Person"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.responsiblePerson"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: HousingTypeId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Housing Type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.housingTypeId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="HousingTypeList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getHousingTypeList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(20)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: AuthorizationNo -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Authorization No"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.authorizationNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: AuthorizationDateHijri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Authorization Date (Hijri)"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <date-picker
                          v-model="dataModel.authorizationDateHijri"
                          :state="errors.length > 0 ? false : null"
                          :locale="'ar-SA'"
                          :calendar="'hijri'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Authorization Date (Gregorian) -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Authorization Date (Gregorian)"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <date-picker
                          v-model="dataModel.authorizationDateGregorian"
                          :state="errors.length > 0 ? false : null"
                          type="date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Administrative Data")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Salary -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Salary"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.salary"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VacationDays -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="VacationDays"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                      >
                        <b-form-input
                          v-model="dataModel.vacationDays"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Qualifications -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Qualifications"
                    >
                      <b-form-input
                        v-model="dataModel.qualifications"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Experience -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Experience"
                    >
                      <b-form-input
                        v-model="dataModel.experience"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Appearance -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Appearance"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.appearanceId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="AppearanceList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getAppearanceList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(21)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: BorderNumber -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Border Number"
                    >
                      <b-form-input
                        v-model="dataModel.borderNumber"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: ExternalOfficeId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="External Office"
                    >
                      <v-select
                        v-model="dataModel.externalOfficeId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ExternalOfficeList"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'externalOfficeName' : 'externalOfficeNameEn'"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: AlMaqqabId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="AlMaqqab"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.alMaqqabId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="AlMaqqabList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getAlMaqqabList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(22)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: MediatorId -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Mediator"
                    >

                      <v-select
                        v-model="dataModel.mediatorId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="MediatorList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getMediatorList()"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(23)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">Add New</span>
                          </li>
                        </template>
                      </v-select>

                    </b-form-group>
                  </b-col>
                  <!-- Field: Agent -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Agent"
                    >
                      <b-form-input
                        v-model="dataModel.agent"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: AgentContactDetails -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Agent Contact Details"
                    >
                      <b-form-input
                        v-model="dataModel.agentContactDetails"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Terms & Conditions")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Condition1Id -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Condition 1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.condition1Id"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ConditionList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getConditionList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(24)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Condition2Id -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Condition 2"
                    >
                      <v-select
                        v-model="dataModel.condition2Id"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ConditionList"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getConditionList()"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(24)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">Add New</span>
                          </li>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Condition3Id -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Condition 3"
                    >

                      <v-select
                        v-model="dataModel.condition3Id"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ConditionList"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getConditionList()"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(24)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">Add New</span>
                          </li>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Condition4Id -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Condition 4"
                    >

                      <v-select
                        v-model="dataModel.condition4Id"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ConditionList"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getConditionList()"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(24)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">Add New</span>
                          </li>
                        </template>
                      </v-select>

                    </b-form-group>
                  </b-col>
                  <!-- Field: Condition5Id -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Condition 5"
                    >

                      <v-select
                        v-model="dataModel.condition5Id"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ConditionList"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @click.native="getConditionList()"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-new
                            class="add-new d-flex align-items-center my-50"
                            @click="SetAcceptedId(24)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                            <span class="align-middle ml-25">Add New</span>
                          </li>
                        </template>
                      </v-select>

                    </b-form-group>
                  </b-col>
                  <!-- Field: Additional Terms -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label="Additional Terms"
                    >

                      <quill-editor
                        v-model="dataModel.additionalTerms"
                        :options="snowOption"
                      />

                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mb-75"
                @click.prevent="validationForm"
              >
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-contracts-list'}"
              >
                {{ t("Back To List") }}
              </b-button>
              <!-- Spacer -->
              <hr class="invoice-spacing">
              <!-- Field: BranchId -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="t('Branch')"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <v-select
                      v-model="dataModel.branchId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="BranchsList"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'branchName' : 'branchNameEn'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      title="Add New"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import VueDatetimeJs from 'vue-datetime-js'
import {
  BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import AddAcceptedValue from '../../../components/AddAcceptedValue.vue'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    quillEditor,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    AddAcceptedValue,
    datePicker: VueDatetimeJs,
  },
  data() {
    const { getValidationState } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      locale: 'en',
      getValidationState,
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      dataModel: {
        contractNo: 0,
        employeeId: 0,
        visaTypeId: '',
        customerId: '',
        registrationDate: '',
        serviceId: '',
        priceBDiscount: 0,
        discount: 0,
        vatValue: 0,
        musanedFee: 0,
        totalWVAT: 0,
        visaNumber: '',
        beneficiariesCount: 0,
        visaDateHijri: '',
        visaDateGregorian: '',
        jobId: '',
        nationalityId: '',
        religionId: '',
        arrivalDestinationId: '',
        arrivalAirportId: '',
        ageOf: 0,
        ageTo: 0,
        contractDuration: 0,
        completionPeriod: 0,
        experienceId: '',
        musanedContractNo: '',
        musanedAuthorizationNo: '',
        musanedAuthorizationDateHijri: '',
        musanedAuthorizationDataGregorian: '',
        workCity: '',
        workNeighborhood: '',
        workStreet: '',
        responsiblePerson: '',
        housingTypeId: '',
        authorizationNo: '',
        authorizationDateHijri: '',
        authorizationDateGregorian: '',
        salary: 0,
        vacationDays: 0,
        qualifications: '',
        experience: '',
        appearanceId: 0,
        borderNumber: '',
        externalOfficeId: 0,
        alMaqqabId: 0,
        mediatorId: 0,
        agent: '',
        agentContactDetails: '',
        condition1Id: '',
        condition2Id: 0,
        condition3Id: 0,
        condition4Id: 0,
        condition5Id: 0,
        additionalTerms: '',
        contractStatusId: 0,
        companyId: api.getCompany(),
        branchId: '',
      },
      id: 0,
      RegionList: [],
      CityList: [],
      DistrictList: [],
      TitlesList: [],
      BankList: [],
      AccessMethodList: [],
      SocialStatusList: [],
      GenderList: [],
      NationalityList: [],
      VisaTypeList: [],
      BranchsList: [],
      acceptedId: 0,
      ReligionsList: [],
      JobList: [],
      ArrivalDestinationList: [],
      ArrivalAirportList: [],
      ExperienceList: [],
      HousingTypeList: [],
      AppearanceList: [],
      AlMaqqabList: [],
      MediatorList: [],
      ConditionList: [],
      CustomersList: [],
      ServicesList: [],
      ExternalOfficeList: [],
      errorsList: [],

    }
  },
  computed: {

  },
  mounted() {
    this.getcontract()
    this.getTitlesList()
    this.getCompaybranchs()
    this.getcustomersdropdown()
    this.getservicedropdown()
    this.getexternalOfficedropdown()
    this.getRegionList()
    this.getTitlesList()
    this.getVisaTypeList()
    this.getSocialStatusList()
    this.getGenderList()
    this.getNationalityList()
    this.getJobList()
    this.getReligionsList()
    this.getArrivalDestinationList()
    this.getArrivalAirportList()
    this.getExperienceList()
    this.getHousingTypeList()
    this.getAppearanceList()
    this.getAlMaqqabList()
    this.getMediatorList()
    this.getcustomersdropdown()
    this.getConditionList()
    this.getservicedropdown()
    this.getexternalOfficedropdown()
    this.locale = api.getLanguage() === 1 ? 'ar' : 'en'
    localize(this.locale)

    // re-validate to re-generate the messages.
    // this.$refs.validationRules.validate()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
        const dataModel = {
            contractNo: 0,
            employeeId: this.dataModel.employeeId,
            visaTypeId: this.dataModel.visaTypeId,
            customerId: this.dataModel.customerId,
            registrationDate: this.dataModel.registrationDate,
            serviceId: this.dataModel.serviceId,
            priceBDiscount: this.dataModel.priceBDiscount,
            discount: this.dataModel.discount,
            vatValue: this.dataModel.vatValue,
            musanedFee: this.dataModel.musanedFee,
            totalWVAT: this.dataModel.totalWVAT,
            visaNumber: this.dataModel.visaNumber,
            beneficiariesCount: this.dataModel.beneficiariesCount,
            visaDateHijri: this.dataModel.visaDateHijri,
            visaDateGregorian: this.dataModel.visaDateGregorian,
            jobId: this.dataModel.jobId,
            nationalityId: this.dataModel.nationalityId,
            religionId: this.dataModel.religionId,
            arrivalDestinationId: this.dataModel.arrivalDestinationId,
            arrivalAirportId: this.dataModel.arrivalAirportId,
            ageOf: this.dataModel.ageOf,
            ageTo: this.dataModel.ageTo,
            contractDuration: this.dataModel.contractDuration,
            completionPeriod: this.dataModel.completionPeriod,
            experienceId: this.dataModel.experienceId,
            musanedContractNo: this.dataModel.musanedContractNo,
            musanedAuthorizationNo: this.dataModel.musanedAuthorizationNo,
            musanedAuthorizationDateHijri: this.dataModel.musanedAuthorizationDateHijri,
            musanedAuthorizationDataGregorian: this.dataModel.musanedAuthorizationDataGregorian,
            workCity: this.dataModel.workCity,
            workNeighborhood: this.dataModel.workNeighborhood,
            workStreet: this.dataModel.workStreet,
            responsiblePerson: this.dataModel.responsiblePerson,
            housingTypeId: this.dataModel.housingTypeId,
            authorizationNo: this.dataModel.authorizationNo,
            authorizationDateHijri: this.dataModel.authorizationDateHijri,
            authorizationDateGregorian: this.dataModel.authorizationDateGregorian,
            salary: this.dataModel.salary,
            vacationDays: this.dataModel.vacationDays,
            qualifications: this.dataModel.qualifications,
            experience: this.dataModel.experience,
            appearanceId: this.dataModel.appearanceId,
            borderNumber: this.dataModel.borderNumber,
            externalOfficeId: this.dataModel.externalOfficeId,
            alMaqqabId: this.dataModel.alMaqqabId,
            mediatorId: this.dataModel.mediatorId,
            agent: this.dataModel.agent,
            agentContactDetails: this.dataModel.agentContactDetails,
            condition1Id: this.dataModel.condition1Id,
            condition2Id: this.dataModel.condition2Id,
            condition3Id: this.dataModel.condition3Id,
            condition4Id: this.dataModel.condition4Id,
            condition5Id: this.dataModel.condition5Id,
            additionalTerms: this.dataModel.additionalTerms,
            contractStatusId: this.dataModel.contractStatusId,
            companyId: api.getCompany(),
            branchId: this.dataModel.branchId,

          }
          api.CreateUpdateContract(router.currentRoute.params.id, dataModel)
            .then(result => {
              console.log(result)

              this.$swal({
                title: 'Good job!',
                text: 'Your details have been saved successfully.',
                icon: result.data.httpCode === 200 ? 'success' : 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getRegionList() {
      api.getRegionList().then(result => {
        this.RegionList = result.data.data.pageData
      })
    },
    getCitiesList(parentId) {
      api.getCitiesList(parentId).then(result => {
        this.CityList = result.data.data.pageData
      })
    },
    getDistrictsList(parentId) {
      api.getDistrictsList(parentId).then(result => {
        this.DistrictList = result.data.data.pageData
      })
    },
    getTitlesList() {
      api.getAcceptedOptions(14).then(result => {
        this.TitlesList = result.data.data.pageData
      })
    },
    getAccessMethodList() {
      api.getAcceptedOptions(13).then(result => {
        this.AccessMethodList = result.data.data.pageData
      })
    },
    getVisaTypeList() {
      api.getAcceptedOptions(16).then(result => {
        this.VisaTypeList = result.data.data.pageData
      })
    },
    getSocialStatusList() {
      api.getAcceptedOptions(6).then(result => {
        this.SocialStatusList = result.data.data.pageData
      })
    },
    getGenderList() {
      api.getAcceptedOptions(7).then(result => {
        this.GenderList = result.data.data.pageData
      })
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    getReligionsList() {
      api.getAcceptedOptions(9).then(result => {
        this.ReligionsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      console.log('1', id)
      this.acceptedId = id
    },
    getArrivalDestinationList() {
      api.getAcceptedOptions(17).then(result => {
        this.ArrivalDestinationList = result.data.data.pageData
      })
    },
    getArrivalAirportList() {
      api.getAcceptedOptions(18).then(result => {
        this.ArrivalAirportList = result.data.data.pageData
      })
    },
    getExperienceList() {
      api.getAcceptedOptions(19).then(result => {
        this.ExperienceList = result.data.data.pageData
      })
    },
    getHousingTypeList() {
      api.getAcceptedOptions(20).then(result => {
        this.HousingTypeList = result.data.data.pageData
      })
    },
    getAppearanceList() {
      api.getAcceptedOptions(21).then(result => {
        this.AppearanceList = result.data.data.pageData
      })
    },
    getAlMaqqabList() {
      api.getAcceptedOptions(22).then(result => {
        this.AlMaqqabList = result.data.data.pageData
      })
    },
    getMediatorList() {
      api.getAcceptedOptions(23).then(result => {
        this.MediatorList = result.data.data.pageData
      })
    },
    getConditionList() {
      api.getAcceptedOptions(24).then(result => {
        this.ConditionList = result.data.data.pageData
      })
    },
    getcustomersdropdown() {
      api.getcustomersdropdown().then(result => {
        this.CustomersList = result.data.data.pageData
      })
    },
    getservicedropdown() {
      api.getservicedropdown().then(result => {
        this.ServicesList = result.data.data.pageData
      })
    },
    getexternalOfficedropdown() {
      api.getexternalOfficedropdown().then(result => {
        this.ExternalOfficeList = result.data.data.pageData
      })
    },
    getservicebyid(id) {
      api.getservicebyid(id).then(result => {
        this.dataModel.priceBDiscount = result.data.data.priceWoVAT
        this.dataModel.vatValue = result.data.data.vatValue
        this.dataModel.totalWVAT = result.data.data.priceWVAT
      })
    },
    getcontract() {
      api.getcontract(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
      })
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
