<template>
  <section id="dashboard-analytics">
    <vue-topprogress ref="topProgress" />
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >

        <b-row>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UsersIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ RegisteredCustomersCount }}
                </h2>
                <span>{{ t("Registered Customers") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="secondary"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="MailIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ MediationContractCount }}
                </h2>
                <span>{{ t("Mediation Contract") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="primary"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="FileTextIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ IndividualContractsCount }}
                </h2>
                <span>{{ t("Individual Contracts") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="ClipboardIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ UnpaidBillsCount }}
                </h2>
                <span>{{ t("Unpaid Bills") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <br>
            <b-card-title>

              <h3>
                <feather-icon
                  icon="BarChart2Icon"
                  size="20"
                />
                {{
                  t("Mediation Contract Statistics")
                }}</h3>
            </b-card-title>
            <!-- Spacer -->
            <hr class="invoice-spacing">
          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UsersIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ EmploymentArrivedCount }}
                </h2>
                <span>{{ t("Employment Arrived") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="primary"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UsersIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ EmploymentWithinWarrantyCount }}
                </h2>
                <span>{{ t("Employment Within Warranty") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="warning"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UsersIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ EmploymentOutsideWarrantyCount }}
                </h2>
                <span>{{ t("Employment Outside Warranty") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UserXIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ ContractsCanceledCount }}
                </h2>
                <span>{{ t("Contracts Canceled") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <br>
            <b-card-title>

              <h3>
                <feather-icon
                  icon="BarChart2Icon"
                  size="20"
                />
                {{
                  t("Rent Contract Statistics")
                }}</h3>
            </b-card-title>
            <!-- Spacer -->
            <hr class="invoice-spacing">
          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="primary"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="FileIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.totalContracts }}
                </h2>
                <span>{{ t("Total Contracts") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="CalendarIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ new Date(employeesstatistics.earliestContractStart).toLocaleDateString() }}
                </h2>
                <span>{{ t("Earliest Contract Start") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="CalendarIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ new Date(employeesstatistics.latestContractEnd).toLocaleDateString() }}
                </h2>
                <span>{{ t("Latest Contract End") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="PercentIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.averageContractDurationMonths }}
                </h2>
                <span>{{ t("Average Contract Duration Months") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="DollarSignIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.totalRevenue }}
                </h2>
                <span>{{ t("Total Revenue") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="DollarSignIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.averageRevenuePerContract }}
                </h2>
                <span>{{ t("Average Revenue Per Contract") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="ClockIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.contractsExpireInOneMonth }}
                </h2>
                <span>{{ t("Contracts Expire In One Month") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="ClockIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.contractsExpireInTwoMonths }}
                </h2>
                <span>{{ t("Contracts Expire In Two Months") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="ClockIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.contractsExpireInThreeMonths }}
                </h2>
                <span>{{ t("Contracts Expire In Three Months") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="success"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UserCheckIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.availableEmployeeCount }}
                </h2>
                <span>{{ t("Available Employee Count") }}</span>
              </div>
            </b-card>

          </b-col>
          <b-col
            xl="3"
            sm="6"
          >
            <b-card class="text-center">
              <b-avatar
                class="mb-1"
                variant="danger"
                size="45"
              >
                <feather-icon
                  size="21"
                  icon="UserXIcon"
                />
              </b-avatar>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesstatistics.unavailableEmployeeCount }}
                </h2>
                <span>{{ t("Unavailable Employee Count") }}</span>
              </div>
            </b-card>

          </b-col>
        </b-row>

      </b-col>

    </b-row>

  </section>
</template>
<script>
import {
  BCard, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { vueTopprogress } from 'vue-top-progress'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    vueTopprogress,
  },
  data() {
    return {
      // i18n
      t,
      RegisteredCustomersCount: 0,
      MediationContractCount: 0,
      IndividualContractsCount: 0,
      UnpaidBillsCount: 0,
      EmploymentArrivedCount: 0,
      EmploymentWithinWarrantyCount: 0,
      EmploymentOutsideWarrantyCount: 0,
      ContractsCanceledCount: 0,
      employeesstatistics: {},
    }
  },
  created() {

  },
  mounted() {
    this.$refs.topProgress.start()
    this.GetRegisteredCustomersCount()
    this.GetMediationContractCount()
    this.GetIndividualContractsCount()
    this.GetUnpaidBillsCount()
    this.GetEmploymentArrivedCount()
    this.GetEmploymentWithinWarrantyCount()
    this.GetEmploymentOutsideWarrantyCount()
    this.GetContractsCanceledCount()
    this.getemployeesstatistics()
  },
  methods: {
    GetRegisteredCustomersCount() {
      api.GetRegisteredCustomersCount().then(result => {
        this.RegisteredCustomersCount = result.data.data
      })
    },
    GetMediationContractCount() {
      api.GetMediationContractCount().then(result => {
        this.MediationContractCount = result.data.data
      })
    },
    GetIndividualContractsCount() {
      api.GetIndividualContractsCount().then(result => {
        this.IndividualContractsCount = result.data.data
      })
    },
    GetUnpaidBillsCount() {
      api.GetUnpaidBillsCount().then(result => {
        this.UnpaidBillsCount = result.data.data
      })
    },
    GetEmploymentArrivedCount() {
      api.GetEmploymentArrivedCount().then(result => {
        this.EmploymentArrivedCount = result.data.data
      })
    },
    GetEmploymentWithinWarrantyCount() {
      api.GetEmploymentWithinWarrantyCount().then(result => {
        this.EmploymentWithinWarrantyCount = result.data.data
      })
    },
    GetEmploymentOutsideWarrantyCount() {
      api.GetEmploymentOutsideWarrantyCount().then(result => {
        this.EmploymentOutsideWarrantyCount = result.data.data
      })
    },
    GetContractsCanceledCount() {
      api.GetContractsCanceledCount().then(result => {
        this.ContractsCanceledCount = result.data.data
        this.$refs.topProgress.done()
      })
    },
    getemployeesstatistics() {
      api.getemployeesstatistics().then(result => {
        this.employeesstatistics = result.data.data
        this.$refs.topProgress.done()
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
