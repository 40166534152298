<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <vue-topprogress ref="topProgress" />
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ companyName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="text-center">
            <b-img
              fluid
              width="100"
              height="100"
              :src="companyLogo"
              alt=""
            />
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ companyName }}
            </b-card-title>
          </div>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { vueTopprogress } from 'vue-top-progress'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../../services/api'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vueTopprogress,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      companyName: '',
      password: 'admin',
      userEmail: 'admin@demo.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      companyLogo: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    const model = {
      // eslint-disable-next-line no-restricted-globals
      url: location.href,
    }
    // eslint-disable-next-line no-restricted-globals
    api.GetByPort(model).then(result => {
      console.log(result.data.data)
      this.companyName = result.data.data === null ? 'Barieq' : result.data.data.companyName
      this.companyLogo = result.data.data === null ? require('@/assets/images/logo/logo.svg') : api.CompanyLogo(result.data.data.companyLogo)
    })
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          api.login({
            userName: this.userEmail,
            password: this.password,
            returnUrl: '',
            rememberMe: true,
          })
            .then(response => {
              console.log(response)
              useJwt.setToken(response.data.data.id_token)
              useJwt.setRefreshToken(response.data.data.refresh_token)
              localStorage.setItem('fullname', response.data.data.fullname)
              localStorage.setItem('userRole', response.data.data.userRole)
              localStorage.setItem('companyId', response.data.data.companyId)
              localStorage.setItem('userId', response.data.data.userId)
              localStorage.setItem('menuList', JSON.stringify(response.data.data.menuList))

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${response.data.data.fullname || response.data.data.fullname}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${response.data.data.userRole}. Now you can start to explore!`,
                },
              })
              // this.$router.replace('/')
              this.$router.push({ name: 'dashboard-analytics' })
              this.$refs.topProgress.done()
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$swal({
                title: 'خطا',
                text: 'تحقق من اسم المستخدم وكلمة السر',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$refs.topProgress.done()
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
