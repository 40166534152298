var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('vue-topprogress',{ref:"topProgress"}),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validationRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('br'),_c('b-card-title',[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',[_vm._v(_vm._s(_vm.t("Information")))])],1),_c('hr',{staticClass:"invoice-spacing"})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Name'),"label-for":"nameAr"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.t('Name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nameAr","state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.nameAr),callback:function ($$v) {_vm.$set(_vm.dataModel, "nameAr", $$v)},expression:"dataModel.nameAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Name En')}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.t('Name En')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nameEn","state":errors.length > 0 ? false : null},model:{value:(_vm.dataModel.nameEn),callback:function ($$v) {_vm.$set(_vm.dataModel, "nameEn", $$v)},expression:"dataModel.nameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Allow Access Pages')}},[_c('v-select',{attrs:{"dir":_vm.getLanguage() === 1 ? 'rtl' : 'ltr',"multiple":"","options":_vm.MenusList,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var icon = ref.icon;
return [_c('feather-icon',{staticClass:"align-middle mr-25",attrs:{"icon":icon,"size":"16"}}),_c('span',[_vm._v(" "+_vm._s(title))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('br')],1),_c('br'),_c('br')],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.t("Save Changes"))+" ")],1),_c('b-button',{attrs:{"variant":"danger","block":"","to":{ name: 'app-UserGroups-list'}}},[_vm._v(" "+_vm._s(_vm.t("Back To List"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }