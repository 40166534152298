// routes/transferRequests.js
export default [
    {
      path: '/TransferRequests',
      name: 'transfer-requests-list',
      component: () => import('@/views/apps/TransferRequests/TransferRequestsList.vue'),
      meta: {
        pageTitle: 'Transfer Requests List',
        breadcrumb: [
          {
            text: 'Transfer Requests',
            to: '/TransferRequests',
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/TransferRequests/add',
      name: 'add-transfer-request',
      component: () => import('@/views/apps/TransferRequests/AddTransferRequest.vue'),
      meta: {
        pageTitle: 'Add Transfer Request',
        breadcrumb: [
          {
            text: 'Transfer Requests',
            to: '/TransferRequests',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/TransferRequests/edit/:id',
      name: 'edit-transfer-request',
      component: () => import('@/views/apps/TransferRequests/EditTransferRequest.vue'),
      meta: {
        pageTitle: 'Edit Transfer Request',
        breadcrumb: [
          {
            text: 'Transfer Requests',
            to: '/TransferRequests',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      props: true, // لتمرير معرف الطلب كخاصية
    },
    {
      path: '/TransferRequests/:id',
      name: 'transfer-request-details',
      component: () => import('@/views/apps/TransferRequests/TransferRequestDetails.vue'),
      meta: {
        pageTitle: 'Transfer Request Details',
        breadcrumb: [
          {
            text: 'Transfer Requests',
            to: '/TransferRequests',
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
      props: true, // لتمرير معرف الطلب كخاصية
    },
    {
      path: '/TransferRequests/statuses',
      name: 'transfer-request-statuses',
      component: () => import('@/views/apps/TransferRequests/TransferRequestStatuses.vue'),
      meta: {
        pageTitle: 'Transfer Request Statuses',
        breadcrumb: [
          {
            text: 'Transfer Requests',
            to: '/TransferRequests',
          },
          {
            text: 'Statuses',
            active: true,
          },
        ],
      },
    },
  ];
  