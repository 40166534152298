// services/TransferRequestsService.js
import http from "../../api";

class TransferRequestsService {
  // جلب جميع طلبات نقل الكفالة
  getAll() {
    return http.get("TransferRequests");
  }

  // جلب طلب نقل كفالة واحد حسب معرفه
  get(id) {
    return http.get(`TransferRequests/${id}`);
  }

  // إنشاء طلب نقل كفالة جديد
  create(data) {
    return http.post("TransferRequests", data);
  }

  // تحديث طلب نقل كفالة حسب المعرف
  update(id, data) {
    return http.put(`TransferRequests/${id}`, data);
  }

  // حذف طلب نقل كفالة حسب المعرف
  delete(id) {
    return http.delete(`TransferRequests/${id}`);
  }

  // جلب جميع الحالات المتاحة لطلبات نقل الكفالة
  getStatuses() {
    return http.get("TransferRequests/statuses");
  }

  getAvailableEmployeesForTransfer(companyId) {
    return http.get(`TransferRequests/available-employees`, {
      params: { companyId },
    })
  }

    // جلب بيانات العميل بناءً على رقم الهوية ومعرّف الشركة
    fetchCustomerBySocialID(socialId, companyId) {
        return http.get(`TransferRequests/by-social-id/${socialId}/${companyId}`);
      }
    
      // جلب بيانات العميل بناءً على رقم الجوال ومعرّف الشركة
      fetchCustomerByMobileNo(mobileNo, companyId) {
        return http.get(`TransferRequests/by-mobile-no/${mobileNo}/${companyId}`);
      }

}

export default new TransferRequestsService();
