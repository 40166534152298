export default [

  // Companies
  {
    path: '/Companies/Add',
    name: 'app-company-add',
    component: () => import('@/views/apps/SystemSettings/Companies/AddCompany.vue'),
    meta: {
      pageTitle: 'Add Company',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/Companies/List',
        },
        {
          text: 'Add Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Companies/edit/:id',
    name: 'app-company-edit',
    component: () => import('@/views/apps/SystemSettings/Companies/EditCompany.vue'),
    meta: {
      pageTitle: 'Edit Company',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/Companies/List',
        },
        {
          text: 'Edit Company',
          active: false,
        },
      ],
    },
  },
  {
    path: '/Companies/List',
    name: 'app-company-list',
    component: () => import('@/views/apps/SystemSettings/Companies/ListCompany.vue'),
    meta: {
      pageTitle: 'Companies List',
      breadcrumb: [
        {
          text: 'Companies',
          to: '/Companies/List',
        },
        {
          text: 'Companies List',
          active: true,
        },
      ],
    },
  },

  // Customers
  {
    path: '/Customers/Add',
    name: 'app-customers-add',
    component: () => import('@/views/apps/Customers/AddCustomer.vue'),
    meta: {
      pageTitle: 'Add Customer',
      breadcrumb: [
        {
          text: 'Customers',
          to: '/Customers/List',
        },
        {
          text: 'Add Customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Customers/edit/:id',
    name: 'app-customer-edit',
    component: () => import('@/views/apps/Customers/EditCustomer.vue'),
    meta: {
      pageTitle: 'Edit Customer',
      breadcrumb: [
        {
          text: 'Customers',
          to: '/Customers/List',
        },
        {
          text: 'Edit Customer',
          active: false,
        },
      ],
    },
  },
  {
    path: '/Customers/List',
    name: 'app-customers-list',
    component: () => import('@/views/apps/Customers/CustomersList.vue'),
    meta: {
      pageTitle: 'Customers List',
      breadcrumb: [
        {
          text: 'Customers',
          to: '/Customers/List',
        },
        {
          text: 'Customers List',
          active: true,
        },
      ],
    },
  },

  // Contracts
  {
    path: '/Contracts/Add',
    name: 'app-contracts-add',
    component: () => import('@/views/apps/Contracts/AddContract.vue'),
    meta: {
      pageTitle: 'Add Contract',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Add Contract',
          active: true,
        },
      ],
    },
  },
  {
    path: '/MediationContracts/Add/:id',
    name: 'app-mediation-add',
    component: () => import('@/views/apps/Contracts/AddMediationContract.vue'),
    meta: {
      pageTitle: 'Add Mediation Contract',
      breadcrumb: [
        {
          text: 'Mediation Contracts',
          to: '/MediationContracts/List',
        },
        {
          text: 'Add Mediation Contract',
          active: true,
        },
      ],
    },
  },
  {
    path: '/MediationContracts/Edit/:id',
    name: 'app-mediation-edit',
    component: () => import('@/views/apps/Contracts/AddMediationContract.vue'),
    meta: {
      pageTitle: 'Edit Mediation Contract',
      breadcrumb: [
        {
          text: 'Mediation Contracts',
          to: '/MediationContracts/List',
        },
        {
          text: 'Edit Mediation Contract',
          active: true,
        },
      ],
    },
  },
  {
    path: '/MediationContracts/List',
    name: 'app-mediation-list',
    component: () => import('@/views/apps/Contracts/MediationContractList.vue'),
    meta: {
      pageTitle: 'Mediation Contracts List',
      breadcrumb: [
        {
          text: 'Mediation Contracts',
          to: '/MediationContracts/List',
        },
        {
          text: 'Mediation Contracts List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/RentalContracts/List',
    name: 'app-rental-contract-list',
    component: () => import('@/views/apps/Contracts/RentalContracts.vue'),
    meta: {
      pageTitle: 'List',
      breadcrumb: [
        {
          text: 'Rental Contracts',
          to: '/RentalContracts/List',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/RentalContracts/Add/:id',
    name: 'app-rental-contract-add',
    component: () => import('@/views/apps/Contracts/AddRentalContracts.vue'),
    meta: {
      pageTitle: 'Add Rental Contract',
      breadcrumb: [
        {
          text: 'Rental Contracts',
          to: '/RentalContracts/List',
        },
        {
          text: 'Add Rental Contract',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Contracts/List',
    name: 'app-contracts-list',
    component: () => import('@/views/apps/Contracts/ContractsList.vue'),
    meta: {
      pageTitle: 'Contracts List',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Contracts List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Contracts/edit/:id',
    name: 'app-contract-edit',
    component: () => import('@/views/apps/Contracts/EditContract.vue'),
    meta: {
      pageTitle: 'Edit Contract',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Edit Contract',
          active: false,
        },
      ],
    },
  },
  {
    path: '/Contracts/SelectEmployee/:id',
    name: 'app-select-employee',
    component: () => import('@/views/apps/Contracts/SelectEmployee.vue'),
    meta: {
      pageTitle: 'Select Employee',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Select Employee',
          active: false,
        },
      ],
    },
  },
  {
    path: '/Contracts/FollowUp/Add/:id',
    name: 'app-contract-addfollowup',
    component: () => import('@/views/apps/Contracts/AddContractFollowUp.vue'),
    meta: {
      pageTitle: 'Add Follow Up',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Add Follow Up',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Contracts/Arrival/Add/:id',
    name: 'app-contract-EmploymentArrival',
    component: () => import('@/views/apps/Contracts/EmploymentArrival.vue'),
    meta: {
      pageTitle: 'Employment Arrival',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Employment Arrival',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Contracts/FollowUp/:id',
    name: 'app-contract-followup',
    component: () => import('@/views/apps/Contracts/ContractsFollowUp.vue'),
    meta: {
      pageTitle: 'Contracts Follow Up',
      breadcrumb: [
        {
          text: 'Contracts',
          to: '/Contracts/List',
        },
        {
          text: 'Contracts Follow Up',
          active: false,
        },
      ],
    },
  },

  // Employees
  {
    path: '/Employees/Add',
    name: 'app-employees-add',
    component: () => import('@/views/apps/Employees/AddEmployee.vue'),
    meta: {
      pageTitle: 'Add Employee',
      breadcrumb: [
        {
          text: 'Employees',
          to: '/Employees/List',
        },
        {
          text: 'Add Employee',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Employees/edit/:id',
    name: 'app-employee-edit',
    component: () => import('@/views/apps/Employees/EditEmployee.vue'),
    meta: {
      pageTitle: 'Edit Employee',
      breadcrumb: [
        {
          text: 'Employees',
          to: '/Employees/List',
        },
        {
          text: 'Edit Employee',
          active: false,
        },
      ],
    },
  },
  {
    path: '/Employees/List',
    name: 'app-employees-list',
    component: () => import('@/views/apps/Employees/EmployeesList.vue'),
    meta: {
      pageTitle: 'Employees List',
      breadcrumb: [
        {
          text: 'Employees',
          to: '/Employees/List',
        },
        {
          text: 'Employees List',
          active: true,
        },
      ],
    },
  },

  // ExternalOffices
  {
    path: '/ExternalOffices/Add',
    name: 'app-externalOffices-add',
    component: () => import('@/views/apps/ExternalOffices/AddExternalOffice.vue'),
    meta: {
      pageTitle: 'Add External Office',
      breadcrumb: [
        {
          text: 'External Offices',
          to: '/ExternalOffices/List',
        },
        {
          text: 'Add External Office',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ExternalOffices/edit/:id',
    name: 'app-externalOffice-edit',
    component: () => import('@/views/apps/ExternalOffices/EditExternalOffice.vue'),
    meta: {
      pageTitle: 'Edit External Office',
      breadcrumb: [
        {
          text: 'ExternalOffices',
          to: '/ExternalOffices/List',
        },
        {
          text: 'Edit External Office',
          active: false,
        },
      ],
    },
  },
  {
    path: '/ExternalOffices/List',
    name: 'app-externalOffices-list',
    component: () => import('@/views/apps/ExternalOffices/ExternalOfficesList.vue'),
    meta: {
      pageTitle: 'External Offices List',
      breadcrumb: [
        {
          text: 'External Offices',
          to: '/ExternalOffices/List',
        },
        {
          text: 'External Offices List',
          active: true,
        },
      ],
    },
  },

  // Services
  {
    path: '/Services/Add',
    name: 'app-services-add',
    component: () => import('@/views/apps/Services/AddService.vue'),
    meta: {
      pageTitle: 'Add Service',
      breadcrumb: [
        {
          text: 'Services',
          to: '/Services/List',
        },
        {
          text: 'Add Service',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Services/edit/:id',
    name: 'app-service-edit',
    component: () => import('@/views/apps/Services/EditService.vue'),
    meta: {
      pageTitle: 'Edit Service',
      breadcrumb: [
        {
          text: 'Services',
          to: '/Services/List',
        },
        {
          text: 'Edit Service',
          active: false,
        },
      ],
    },
  },
  {
    path: '/Services/List',
    name: 'app-services-list',
    component: () => import('@/views/apps/Services/ServicesList.vue'),
    meta: {
      pageTitle: 'Services List',
      breadcrumb: [
        {
          text: 'Services',
          to: '/Services/List',
        },
        {
          text: 'Services List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/CompanyData',
    name: 'app-systemsettings-companydata',
    component: () => import('@/views/apps/SystemSettings/CompanyData.vue'),
    meta: {
      pageTitle: 'Company Data',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Company Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/CompanyBranches/List',
    name: 'app-companybranches-list',
    component: () => import('@/views/apps/SystemSettings/CompanyBranches/ListBranches.vue'),
    meta: {
      pageTitle: 'Branches',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Branches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/CompanyBranches/Add',
    name: 'app-companybranches-add',
    component: () => import('@/views/apps/SystemSettings/CompanyBranches/AddBranch.vue'),
    meta: {
      pageTitle: 'Add Branch',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Add Branch',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/UserGroups/List',
    name: 'app-UserGroups-list',
    component: () => import('@/views/apps/SystemSettings/UserGroups/ListUserGroups.vue'),
    meta: {
      pageTitle: 'User Groups List',
      breadcrumb: [
        {
          text: 'User Groups',
          active: true,
        },
        {
          text: 'User Groups List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/UserGroups/Add/:id',
    name: 'app-UserGroups-add',
    component: () => import('@/views/apps/SystemSettings/UserGroups/AddUserGroup.vue'),
    meta: {
      pageTitle: 'Add User Group',
      breadcrumb: [
        {
          text: 'User Groups',
          to: '/SystemSettings/UserGroups/List',
        },
        {
          text: 'Add User Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/UserGroups/Edit/:id',
    name: 'app-UserGroups-edit',
    component: () => import('@/views/apps/SystemSettings/UserGroups/AddUserGroup.vue'),
    meta: {
      pageTitle: 'Edit User Group',
      breadcrumb: [
        {
          text: 'User Groups',
          to: '/SystemSettings/UserGroups/List',
        },
        {
          text: 'Edit User Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Users/List',
    name: 'app-user-list',
    component: () => import('@/views/apps/SystemSettings/Users/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Users/Add/:id',
    name: 'app-user-add',
    component: () => import('@/views/apps/SystemSettings/Users/AddUser.vue'),
    meta: {
      pageTitle: 'Add User',
      breadcrumb: [
        {
          text: 'User',
          to: '/SystemSettings/Users/List',
        },
        {
          text: 'Add User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Users/Edit/:id',
    name: 'app-user-edit',
    component: () => import('@/views/apps/SystemSettings/Users/AddUser.vue'),
    meta: {
      pageTitle: 'Edit User',
      breadcrumb: [
        {
          text: 'User',
          to: '/SystemSettings/Users/List',
        },
        {
          text: 'Edit User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Country/List',
    name: 'app-SystemSettings-Nationalities',
    component: () => import('@/views/apps/SystemSettings/Country/CountryList.vue'),
    meta: {
      pageTitle: 'Country',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Country',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Country/Add/:id',
    name: 'app-country-add',
    component: () => import('@/views/apps/SystemSettings/Country/AddEditCountry.vue'),
    meta: {
      pageTitle: 'Add Country',
      breadcrumb: [
        {
          text: 'Countries',
          to: '/SystemSettings/Country/List',
        },
        {
          text: 'Add Country',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/AccessMethods',
    name: 'app-SystemSettings-AccessMethods',
    component: () => import('@/views/apps/SystemSettings/AccessMethods.vue'),
    meta: {
      pageTitle: 'Access Methods',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Access Methods',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Banks',
    name: 'app-SystemSettings-Banks',
    component: () => import('@/views/apps/SystemSettings/Banks.vue'),
    meta: {
      pageTitle: 'Banks',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Banks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Employers',
    name: 'app-SystemSettings-Employers',
    component: () => import('@/views/apps/SystemSettings/Employers.vue'),
    meta: {
      pageTitle: 'Employers',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Employers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Gender',
    name: 'app-SystemSettings-Gender',
    component: () => import('@/views/apps/SystemSettings/Gender.vue'),
    meta: {
      pageTitle: 'Gender',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Gender',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Jobs',
    name: 'app-SystemSettings-Jobs',
    component: () => import('@/views/apps/SystemSettings/Jobs.vue'),
    meta: {
      pageTitle: 'Jobs',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Jobs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/PlacesofBirth',
    name: 'app-SystemSettings-PlacesofBirth',
    component: () => import('@/views/apps/SystemSettings/PlacesofBirth.vue'),
    meta: {
      pageTitle: 'Places of Birth',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Places of Birth',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Religions',
    name: 'app-SystemSettings-Religions',
    component: () => import('@/views/apps/SystemSettings/Religions.vue'),
    meta: {
      pageTitle: 'Religions',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Religions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/SocialStatus',
    name: 'app-SystemSettings-SocialStatus',
    component: () => import('@/views/apps/SystemSettings/SocialStatus.vue'),
    meta: {
      pageTitle: 'Social Status',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Social Status',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Region/List',
    name: 'app-SystemSettings-Regions',
    component: () => import('@/views/apps/SystemSettings/Region/RegionList.vue'),
    meta: {
      pageTitle: 'Regions',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Regions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Region/Add/:id',
    name: 'app-region-add',
    component: () => import('@/views/apps/SystemSettings/Region/AddEditRegion.vue'),
    meta: {
      pageTitle: 'Add Region',
      breadcrumb: [
        {
          text: 'Regions',
          to: '/SystemSettings/Region/List',
        },
        {
          text: 'Add Region',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/City/List',
    name: 'app-SystemSettings-Cities',
    component: () => import('@/views/apps/SystemSettings/City/CityList.vue'),
    meta: {
      pageTitle: 'Cities',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Cities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/City/Add/:id',
    name: 'app-city-add',
    component: () => import('@/views/apps/SystemSettings/City/AddEditCity.vue'),
    meta: {
      pageTitle: 'Add City',
      breadcrumb: [
        {
          text: 'Cities',
          to: '/SystemSettings/City/List',
        },
        {
          text: 'Add City',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Districts',
    name: 'app-SystemSettings-Districts',
    component: () => import('@/views/apps/SystemSettings/Districts.vue'),
    meta: {
      pageTitle: 'Districts',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Districts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/titles',
    name: 'app-SystemSettings-titles',
    component: () => import('@/views/apps/SystemSettings/Titles.vue'),
    meta: {
      pageTitle: 'Titles',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Titles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/Currency',
    name: 'app-SystemSettings-Currency',
    component: () => import('@/views/apps/SystemSettings/Currency.vue'),
    meta: {
      pageTitle: 'Currency',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Currency',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SystemSettings/CostCenter',
    name: 'app-SystemSettings-CostCenter',
    component: () => import('@/views/apps/SystemSettings/CostCenter.vue'),
    meta: {
      pageTitle: 'Cost Center',
      breadcrumb: [
        {
          text: 'System Settings',
          active: true,
        },
        {
          text: 'Cost Center',
          active: true,
        },
      ],
    },
  },

  // Accounts

  {
    path: '/Accounts/Invoices/List',
    name: 'app-invoice-list',
    component: () => import('@/views/apps/Accounts/Invoices/InvoiceList.vue'),
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Invoices',
          to: '/Accounts/Invoices/List',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/Accounts/PayRoll/MonthlyPayrollSheet',
    name: 'monthly-payroll-sheet',
    component: () => import('@/views/apps/Accounts/PayRoll/MonthlyPayrollSheet.vue'),
    meta: {
      pageTitle: 'Monthly Payroll Sheet',
      breadcrumb: [
        {
          text: 'Monthly Payroll Sheet',
          to: '/Accounts/PayRoll/MonthlyPayrollSheet',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },


  {
    path: '/Accounts/AccountingGuide/Add/:id',
    name: 'app-account-add',
    component: () => import('@/views/apps/Accounts/AccountingGuide/AddEditAccount.vue'),
    meta: {
      pageTitle: 'Add Account',
      breadcrumb: [
        {
          text: 'Accounting Guide',
          to: '/Accounts/AccountingGuide/List',
        },
        {
          text: 'Add Account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Accounts/AccountingGuide/Add/:id',
    name: 'app-account-edit',
    component: () => import('@/views/apps/Accounts/AccountingGuide/AddEditAccount.vue'),
    meta: {
      pageTitle: 'Edit Account',
      breadcrumb: [
        {
          text: 'Accounting Guide',
          to: '/Accounts/AccountingGuide/List',
        },
        {
          text: 'Edit Account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Accounts/AccountingGuide/List',
    name: 'app-account-list',
    component: () => import('@/views/apps/Accounts/AccountingGuide/AccountList.vue'),
    meta: {
      pageTitle: 'Accounting Guide',
      breadcrumb: [
        {
          text: 'Accounts',
          to: '/Accounts/AccountingGuide/List',
        },
        {
          text: 'Accounting Guide',
          active: true,
        },
      ],
    },
  },

  // Bond
  {
    path: '/Accounts/Bonds/Add/:id',
    name: 'app-bond-add',
    component: () => import('@/views/apps/Accounts/Bonds/AddEditBond.vue'),
    meta: {
      pageTitle: 'Add Bond',
      breadcrumb: [
        {
          text: 'Bonds',
          active: true,
        },
        {
          text: 'Add Bond',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Accounts/Bonds/Add/:id',
    name: 'app-bond-edit',
    component: () => import('@/views/apps/Accounts/Bonds/AddEditBond.vue'),
    meta: {
      pageTitle: 'Edit Bond',
      breadcrumb: [
        {
          text: 'Bonds',
          active: true,
        },
        {
          text: 'Edit Bond',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Accounts/Bonds/List',
    name: 'app-bond-list',
    component: () => import('@/views/apps/Accounts/Bonds/BondList.vue'),
    meta: {
      pageTitle: 'Bonds',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
        {
          text: 'Bonds',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Accounts/Journals',
    name: 'app-journal',
    component: () => import('@/views/apps/Accounts/Journals/Journals.vue'),
    meta: {
      pageTitle: 'Journals',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
        {
          text: 'Journals',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Accounts/GeneralLedger',
    name: 'app-general-ledger',
    component: () => import('@/views/apps/Accounts/GeneralLedger/GeneralLedger.vue'),
    meta: {
      pageTitle: 'General Ledger',
      breadcrumb: [
        {
          text: 'Accounts',
        },
        {
          text: 'General Ledger',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Pages/List',
    name: 'app-portal-listpage',
    component: () => import('@/views/apps/Portal/Pages/ListPage.vue'),
    meta: {
      pageTitle: 'Pages',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Pages/AddEditPage/:id',
    name: 'app-portal-addpage',
    component: () => import('@/views/apps/Portal/Pages/AddPage.vue'),
    meta: {
      pageTitle: 'Add Page',
      breadcrumb: [
        {
          text: 'Pages',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Sliders/List',
    name: 'app-portal-sliders',
    component: () => import('@/views/apps/Portal/PortalSliders/PortalSliders.vue'),
    meta: {
      pageTitle: 'Sliders',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Sliders/AddEditSlider/:id',
    name: 'app-portal-addslider',
    component: () => import('@/views/apps/Portal/PortalSliders/AddEditPortalSlider.vue'),
    meta: {
      pageTitle: 'Add Slider',
      breadcrumb: [
        {
          text: 'Sliders',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Attachments/List',
    name: 'app-portal-attachments',
    component: () => import('@/views/apps/Portal/Attachments/Attachments.vue'),
    meta: {
      pageTitle: 'Attachments',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Attachments/AddAttachment',
    name: 'app-portal-addattachment',
    component: () => import('@/views/apps/Portal/Attachments/AddAttachment.vue'),
    meta: {
      pageTitle: 'Add Attachment',
      breadcrumb: [
        {
          text: 'Attachments',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Attachments/Browser',
    name: 'app-portal-browserattachments',
    component: () => import('@/views/apps/Portal/Attachments/BrowserAttachments.vue'),
    meta: {
      pageTitle: 'Attachments',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/Portal/Services/List',
    name: 'app-portal-services',
    component: () => import('@/views/apps/Portal/PortalServices/ListPortalServices.vue'),
    meta: {
      pageTitle: 'Services',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Services/AddEditService',
    name: 'app-portal-addservice',
    component: () => import('@/views/apps/Portal/PortalServices/AddEditPortalServices.vue'),
    meta: {
      pageTitle: 'Add Service',
      breadcrumb: [
        {
          text: 'Services',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/ServicesDetails/List',
    name: 'app-portal-servicesdetails',
    component: () => import('@/views/apps/Portal/PortalServicesDetails/ListPortalServicesDetails.vue'),
    meta: {
      pageTitle: 'Services Details',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/ServicesDetails/AddEditServiceDetails/:id',
    name: 'app-portal-addservicedetails',
    component: () => import('@/views/apps/Portal/PortalServicesDetails/AddEditPortalServicesDetails.vue'),
    meta: {
      pageTitle: 'Add Service Details',
      breadcrumb: [
        {
          text: 'Services Details',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },

  {
    path: '/Portal/Faqs/List',
    name: 'app-portal-faqslist',
    component: () => import('@/views/apps/Portal/Faqs/ListFaqs.vue'),
    meta: {
      pageTitle: 'Faqs',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Faqs/Add/:id',
    name: 'app-portal-addfaqs',
    component: () => import('@/views/apps/Portal/Faqs/AddEditFaqs.vue'),
    meta: {
      pageTitle: 'Faqs',
      breadcrumb: [
        {
          text: 'Faqs',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/CustomersMessages/List',
    name: 'app-settings-customersmessages',
    component: () => import('@/views/apps/Portal/CustomersMessages/CustomersMessages.vue'),
    meta: {
      pageTitle: 'Customers Messages',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/CustomersMessages/:id',
    name: 'app-settings-viewMessage',
    component: () => import('@/views/apps/Portal/CustomersMessages/ViewMessage.vue'),
    meta: {
      pageTitle: 'Customers Messages',
      breadcrumb: [
        {
          text: 'View',
          active: true,
        },
      ],
    },

  },

  {
    path: '/Portal/NationalitiesSlider/List',
    name: 'app-portal-nationalitiesdetails',
    component: () => import('@/views/apps/Portal/NationalitiesSlider/ListNationalitiesSlider.vue'),
    meta: {
      pageTitle: 'Nationalities Slider',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/NationalitiesSlider/:id',
    name: 'app-portal-addnationalitiesdetails',
    component: () => import('@/views/apps/Portal/NationalitiesSlider/AddEditNationalitiesSlider.vue'),
    meta: {
      pageTitle: 'Nationalities Slider',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },

  },

  {
    path: '/Portal/Testimonials/List',
    name: 'app-portal-testimonialsdetails',
    component: () => import('@/views/apps/Portal/Testimonials/ListTestimonials.vue'),
    meta: {
      pageTitle: 'Testimonials',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Portal/Testimonials/:id',
    name: 'app-portal-addtestimonialsdetails',
    component: () => import('@/views/apps/Portal/Testimonials/AddEditTestimonial.vue'),
    meta: {
      pageTitle: 'Testimonials',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },

  },
]
