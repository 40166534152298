<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- nameAr -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Name')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.fullName"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- nameEn -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Phone Number')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Phone Number')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.phoneNumber"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Address')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Address')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.address"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Message')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Message')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.message"
                          :state="errors.length > 0 ? false : null"
                          readonly
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-spinner
                v-if="isLoading"
                small
              />
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-settings-customersmessages'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { vueTopprogress } from 'vue-top-progress'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner,
    vueTopprogress,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      UnitsList: [],
      isLoading: false,
      isHidden: false,
      id: 0,
    }
  },
  mounted() {
    if (router.currentRoute.params.id !== 0) {
      this.$refs.topProgress.start()
      api.GetCustomerMessage(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          this.isLoading = true
          // eslint-disable-next-line
          const dataModel = {
            id: router.currentRoute.params.id,
            nameAr: this.dataModel.nameAr,
            nameEn: this.dataModel.nameAr,
            isPublish: true,
            companyId: api.getCompany(),

          }
          api.CreateUpdateCountry(router.currentRoute.params.id, dataModel).then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isLoading = false
            this.$refs.topProgress.done()

            if (router.currentRoute.params.id === 0) {
              this.$router.push({ name: 'app-country-edit', params: { id: result.data.data } })
            }
          })
            .catch(error => {
              this.isLoading = false
              this.$refs.topProgress.done()
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    UnitsDropDown() {
      api.UnitsDropDown().then(result => {
        this.UnitsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
  },

}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  .add-new {
      padding: $options-padding-y $options-padding-x;
        color: $success;

      &:hover {
        background-color: rgba($success, 0.12);
      }
  }
  </style>
