<template>
    <div>
        <b-card>
            <b-card-header class="pb-50">
                <h5>{{ t('Monthly Payroll Sheet') }}</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <!-- Month Selector -->
                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                        <label>{{ t('Month') }}</label>
                        <b-form-select v-model="selectedMonth" :options="monthOptions" />
                    </b-col>

                    <!-- Year Selector -->
                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                        <label>{{ t('Year') }}</label>
                        <b-form-select v-model="selectedYear" :options="yearOptions" />
                    </b-col>

              

                    <!-- Calculate Button -->
                    <b-col cols="12" class="text-center mt-3">
                        <b-button variant="primary" @click="calculateSalaries">
                            {{ t('Calculate') }}
                        </b-button>
                        <b-button variant="success" @click="exportToExcel" :disabled="result.length === 0">
                            {{ t('Export to Excel') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <!-- Result Display -->
        <b-card v-if="result.length > 0" class="mt-3">
            <b-card-header>
                <h5>{{ t('Calculation Result') }}</h5>
            </b-card-header>
            <b-card-body>
                <b-table :items="result" :fields="resultFields" responsive />
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BTable,
} from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import api from '../../../../services/api'
import * as XLSX from 'xlsx'; 

const { t } = useI18nUtils()


export default {
    name: 'VariableSalariesCalculator',
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BFormSelect,
        BButton,
        BTable,
    },
    data() {
        return {
            t,
            selectedMonth: new Date().getMonth() + 1, // Default to current month
            selectedYear: new Date().getFullYear(),   // Default to current year
            selectedCompanyId: null, // Should be set to the default company if applicable
            monthOptions: [
                { value: 1, text: 'January' },
                { value: 2, text: 'February' },
                { value: 3, text: 'March' },
                { value: 4, text: 'April' },
                { value: 5, text: 'May' },
                { value: 6, text: 'June' },
                { value: 7, text: 'July' },
                { value: 8, text: 'August' },
                { value: 9, text: 'September' },
                { value: 10, text: 'October' },
                { value: 11, text: 'November' },
                { value: 12, text: 'December' },
            ],
            yearOptions: this.getYearsOptions(),
            companyOptions: [], // This should be populated with actual company options
            result: [],
            resultFields: [
                { key: 'employeeId', label: 'Employee ID' },
                { key: 'employeeName', label: 'Employee Name' },
                { key: 'rentStart', label: 'Contract Start' },
                { key: 'rentEnd', label: 'Contract End' },
                { key: 'hiredDate', label: 'Hired Date' },
                { key: 'fixedSalary', label: 'Fixed Salary' },
                { key: 'variableSalary', label: 'Variable Salary' },
                { key: 'netSalary', label: 'Net Salary' },
                { key: 'daysWorked', label: 'Days Worked' },
            ],
        };
    },
    methods: {
        getYearsOptions() {
            const currentYear = new Date().getFullYear();
            const yearOptions = [];
            for (let year = currentYear - 10; year <= currentYear + 10; year++) {
                yearOptions.push({ value: year, text: year.toString() });
            }
            return yearOptions;
        },
        async calculateSalaries() {
            try {
                const response = await api.CalculateVariableSalaries(
                    this.selectedMonth,
                    this.selectedYear,
                    api.getCompany()
                );
                this.result = response;
            } catch (error) {
                console.error('Error calculating salaries:', error);
                this.$bvToast.toast('Failed to calculate salaries', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
         exportToExcel() {
            const worksheet = XLSX.utils.json_to_sheet(this.result);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Monthly Payroll Sheet');
            XLSX.writeFile(workbook, `Monthly_Payroll_Sheet_${this.selectedMonth}_${this.selectedYear}.xlsx`);
        },
    },
    mounted() {
      
       
        this.selectedCompanyId = api.getCompany(); // Default to the first company
    },
};
</script>

<style scoped></style>