<template>
  <section>
    <accepted-Value-list
      :accepted-value-id="10"
    />
  </section>
</template>
<script>
import AcceptedValueList from '../../../components/AcceptedValueList.vue'

export default {
  components: {
    AcceptedValueList,
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
