<template>
  <section>
    <div>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>entries</label>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="EmployeeList"
          :fields="fields"
          :filter="filter"
          responsive=""
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(employeeName)="data">
            <b-link
              class="font-weight-bold"
              @click="SetEmployee(data.item.id)"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(employeeNameEn)="data">
            <b-link
              class="font-weight-bold"
              @click="SetEmployee(data.item.id)"
            >
              {{ data.value }}
            </b-link>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'app-employee-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <b-button
                variant="outline-primary"
                :to="{ name: 'app-contracts-list'}"
              >
                Back To List
              </b-button>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import vSelect from 'vue-select'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BLink,
    BPagination,
    vSelect,
  },
  data() {
    return {
      filter: null,
      validityType: 0,
      dataSearch: {},
      EmployeeList: [],
      dataModel: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Name'),
          key: api.getLanguage() === 1 ? 'employeeName' : 'employeeNameEn',
          sortable: true,
        },
        {
          label: t('Passport Number'),
          key: 'passportNumber',
          sortable: true,
        },
        {
          label: t('Passport Issue Date'),
          key: 'passportIssueDate',
          sortable: true,
        },
        {
          label: t('Passport Expiry Date'),
          key: 'passportExpiryDate',
          sortable: true,
        },
        {
          label: t('Birthday'),
          key: 'birthday',
          sortable: true,
        },
        {
          label: t('Birth Place'),
          key: 'birthplace',
          sortable: true,
        },
        {
          label: t('Place Issue'),
          key: 'placeIssue',
          sortable: true,
        },
        {
          label: t('Religion'),
          key: api.getLanguage() === 1 ? 'religionName' : 'religionNameEn',
          sortable: true,
        },
        {
          label: t('Social Status'),
          key: api.getLanguage() === 1 ? 'socialStatusName' : 'socialStatusNameEn',
          sortable: true,
        },
        {
          label: t('Gender'),
          key: api.getLanguage() === 1 ? 'sexName' : 'sexNameEn',
          sortable: true,
        },
        {
          label: t('Nationality'),
          key: api.getLanguage() === 1 ? 'nationalityName' : 'nationalityNameEn',
          sortable: true,
        },
        {
          label: t('Address'),
          key: 'address',
          sortable: true,
        },
        {
          label: t('Mobile No'),
          key: 'mobileNo',
          sortable: true,
        },
        {
          label: t('Mobile No2'),
          key: 'mobileNo2',
          sortable: true,
        },
        {
          label: t('Email'),
          key: 'email',
          sortable: true,
        },
        {
          label: t('Landline'),
          key: 'landline',
          sortable: true,
        },
        {
          label: t('Worked Before'),
          key: 'workedBefore',
          sortable: true,
        },
        {
          label: t('Job'),
          key: 'job',
          sortable: true,
        },
        {
          label: t('Hired Date'),
          key: 'hiredDate',
          sortable: true,
        },
        {
          label: t('Sponsor Name'),
          key: 'sponsorName',
          sortable: true,
        },
        {
          label: t('Branch'),
          key: api.getLanguage() === 1 ? 'branchName' : 'branchNameEn',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
  },
  mounted() {
    this.getemployeeList()
    this.getcontract()
  },
  methods: {

    SetEmployee(empId) {
      // eslint-disable-next-line
      
      const dataModel = {
        contractNo: 0,
        employeeId: empId,
        visaTypeId: this.dataModel.visaTypeId,
        customerId: this.dataModel.customerId,
        registrationDate: this.dataModel.registrationDate,
        serviceId: this.dataModel.serviceId,
        priceBDiscount: this.dataModel.priceBDiscount,
        discount: this.dataModel.discount,
        vatValue: this.dataModel.vatValue,
        musanedFee: this.dataModel.musanedFee,
        totalWVAT: this.dataModel.totalWVAT,
        visaNumber: this.dataModel.visaNumber,
        beneficiariesCount: this.dataModel.beneficiariesCount,
        visaDateHijri: this.dataModel.visaDateHijri,
        visaDateGregorian: this.dataModel.visaDateGregorian,
        jobId: this.dataModel.jobId,
        nationalityId: this.dataModel.nationalityId,
        religionId: this.dataModel.religionId,
        arrivalDestinationId: this.dataModel.arrivalDestinationId,
        arrivalAirportId: this.dataModel.arrivalAirportId,
        ageOf: this.dataModel.ageOf,
        ageTo: this.dataModel.ageTo,
        contractDuration: this.dataModel.contractDuration,
        completionPeriod: this.dataModel.completionPeriod,
        experienceId: this.dataModel.experienceId,
        musanedContractNo: this.dataModel.musanedContractNo,
        musanedAuthorizationNo: this.dataModel.musanedAuthorizationNo,
        musanedAuthorizationDateHijri: this.dataModel.musanedAuthorizationDateHijri,
        musanedAuthorizationDataGregorian: this.dataModel.musanedAuthorizationDataGregorian,
        workCity: this.dataModel.workCity,
        workNeighborhood: this.dataModel.workNeighborhood,
        workStreet: this.dataModel.workStreet,
        responsiblePerson: this.dataModel.responsiblePerson,
        housingTypeId: this.dataModel.housingTypeId,
        authorizationNo: this.dataModel.authorizationNo,
        authorizationDateHijri: this.dataModel.authorizationDateHijri,
        authorizationDateGregorian: this.dataModel.authorizationDateGregorian,
        salary: this.dataModel.salary,
        vacationDays: this.dataModel.vacationDays,
        qualifications: this.dataModel.qualifications,
        experience: this.dataModel.experience,
        appearanceId: this.dataModel.appearanceId,
        borderNumber: this.dataModel.borderNumber,
        externalOfficeId: this.dataModel.externalOfficeId,
        alMaqqabId: this.dataModel.alMaqqabId,
        mediatorId: this.dataModel.mediatorId,
        agent: this.dataModel.agent,
        agentContactDetails: this.dataModel.agentContactDetails,
        condition1Id: this.dataModel.condition1Id,
        condition2Id: this.dataModel.condition2Id,
        condition3Id: this.dataModel.condition3Id,
        condition4Id: this.dataModel.condition4Id,
        condition5Id: this.dataModel.condition5Id,
        additionalTerms: this.dataModel.additionalTerms,
        contractStatusId: this.dataModel.contractStatusId,
        companyId: api.getCompany(),
        branchId: this.dataModel.branchId,

      }
      api.CreateUpdateContract(router.currentRoute.params.id, dataModel)
        .then(result => {
          this.getemployeeList()

          this.$swal({
            title: 'Good job!',
            text: 'Your details have been saved successfully.',
            icon: result.data.httpCode === 200 ? 'success' : 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$router.push({ name: 'app-contracts-list' })
        })
        .catch(error => {
          this.$swal({
            title: error.response.data.title,
            html: Object.values(error.response.data.errors)
              .map(item => `<div>${item}</div>`)
              .join(''),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    getemployeeList() {
      console.log(this.currentPage, this.perPage, '', '', '', api.getCompany(), 0)
      api.getemployeeList(this.currentPage - 1, this.perPage, '', '', '', api.getCompany(), 0).then(result => {
        this.EmployeeList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getemployeeList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getemployeeList()
    },
    getLanguage() {
      return api.getLanguage()
    },
    getcontract() {
      api.getcontract(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
