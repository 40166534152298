<template>
    <section>
        <div>
            <vue-topprogress ref="topProgress" />
            <b-card>
                <b-card-header class="pb-50">
                    <h5>{{ t('Filters') }}</h5>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>{{ t('Invoice No') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input id="searchInvoiceNo" v-model="dataSearch.invoiceNo" />
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>{{ t('Customer') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input id="searchCustomerId" v-model="dataSearch.customerId" />
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>{{ t('Contract Number') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input id="searchContractId" v-model="dataSearch.contractId" />
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>{{ t('Invoice Title') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-select id="searchInvoiceTitle" v-model="dataSearch.invoiceTitle"
                                    :options="invoiceTitleOptions" />
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>{{ t('Start Date') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input id="searchStartDate" type="date" v-model="dataSearch.startDate" />
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>{{ t('End Date') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input id="searchEndDate" type="date" v-model="dataSearch.endDate" />
                            </div>
                        </b-col>

                        <b-col cols="12" md="12" class="mb-md-0 mb-2 text-center">
                            <br>
                            <b-button variant="primary" @click="getInvoicesList">
                                <span class="text-nowrap">{{ t('Search') }}</span>
                            </b-button>
                            &nbsp;&nbsp;
                            <b-button variant="secondary" @click="resetFilters">
                                <span class="text-nowrap">{{ t('Reset') }}</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-card no-body class="mb-0">
                <div class="m-2">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>{{ t("Show") }}</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50"
                                @input="handlePerPageChange" />
                            <label>{{ t("entries") }}</label>
                        </b-col>

                        <!-- Search -->
                        <b-col cols="12" md="6">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                                <b-button variant="primary" :disabled="!filter" @click="filter = ''">
                                    <span class="text-nowrap">{{ t("Clear") }}</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>

                <b-table class="position-relative" :items="InvoicesList" :fields="fields" :filter="filter"
                    :busy="isBusy" responsive="" primary-key="id" show-empty empty-text="No matching records found">
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle" />
                            <strong>{{ t("Loading...") }}</strong>
                        </div>
                    </template>

                    <template #cell(invoiceCode)="data">
                        <b-button variant="primary" block @click="viewInvoiceDetails(data.item.id)">
                            {{ data.item.invoiceCode }}
                        </b-button>
                    </template>

                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" class="cursor-pointer" size="16" />
                            </template>



                            <b-dropdown-item @click="InvoicePDF(data.item.uuid)">
                                <feather-icon icon="PrinterIcon" class="mr-50" size="16" />
                                Print Invoice
                            </b-dropdown-item>

                            <!-- Button to Open Receipt Voucher Modal -->
                            <b-dropdown-item v-if="data.item.invoiceTitle === 1"
                                @click="openUpdateStatusModal(data.item)">
                                <feather-icon icon="FilePlusIcon" class="mr-50" size="16" />
                                Add Receipt Voucher
                            </b-dropdown-item>

                            <!-- Button to Open Payment Voucher Modal -->
                            <b-dropdown-item v-if="data.item.invoiceTitle === 2"
                                @click="openUpdateStatusModal(data.item)">
                                <feather-icon icon="FileMinusIcon" class="mr-50" size="16" />
                                Add Payment Voucher
                            </b-dropdown-item>

                            <b-dropdown-item @click="showReceiptVouchersModal(data.item.id)">
                                <feather-icon icon="FileTextIcon" class="mr-50" size="16" />
                                View Receipt Vouchers
                            </b-dropdown-item>




                        </b-dropdown>
                    </template>
                </b-table>

                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start" />
                        <!-- Pagination -->
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number
                                last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                                @change="handlePageChange">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
            <!-- Modal for Receipt Vouchers -->
            <b-modal id="receiptVoucherModal" ref="receiptVoucherModal" title="Add Receipt Voucher"
                @ok="addReceiptVoucher">
                <b-form @submit.stop.prevent="addReceiptVoucher">
                    <b-form-group label="Voucher Date" label-for="voucher-date">
                        <b-form-input id="voucher-date" v-model="receiptVoucher.voucherDate" type="date"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Amount" label-for="amount">
                        <b-form-input id="amount" v-model="receiptVoucher.amount" type="number" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Payment Method" label-for="payment-method">
                        <b-form-select id="payment-method" v-model="receiptVoucher.paymentMethod"
                            :options="paymentMethodOptions" required>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group label="Notes" label-for="notes">
                        <b-form-textarea id="notes" v-model="receiptVoucher.notes"></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-modal>

            <!-- Modal for Payment Vouchers -->
            <b-modal id="paymentVoucherModal" ref="paymentVoucherModal" title="Add Payment Voucher"
                @ok="addPaymentVoucher">
                <b-form @submit.stop.prevent="addPaymentVoucher">
                    <b-form-group label="Voucher Date" label-for="voucher-date">
                        <b-form-input id="voucher-date" v-model="paymentVoucher.voucherDate" type="date"
                            required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Amount" label-for="amount">
                        <b-form-input id="amount" v-model="paymentVoucher.amount" type="number" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Payment Method" label-for="payment-method">
                        <b-form-select id="payment-method" v-model="receiptVoucher.paymentMethod"
                            :options="paymentMethodOptions" required>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group label="Notes" label-for="notes">
                        <b-form-textarea id="notes" v-model="paymentVoucher.notes"></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-modal>


            <b-modal ref="receiptVouchersModal" title="Receipt Vouchers" hide-footer size="xl">
                <div v-if="receiptVouchers.length > 0">
                    <b-table :items="receiptVouchers" :fields="receiptVoucherFields" responsive>
                        <template #cell(amount)="data">
                            {{ data.item.amount | currency }}
                        </template>
                        <template #cell(voucherDate)="data">
                            {{ data.item.voucherDate | date('YYYY-MM-DD') }}
                        </template>
                        <template #cell(actions)="data">
                            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="cursor-pointer" size="16" />
                                </template>
                                <b-dropdown-item @click="editVoucher(data.item)">
                                    <feather-icon icon="EditIcon" class="mr-50" size="16" />
                                    Edit
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteVoucher(data.item)">
                                    <feather-icon icon="TrashIcon" class="mr-50" size="16" />
                                    Delete
                                </b-dropdown-item>
                                <b-dropdown-item @click="printVoucher(data.item)">
                                    <feather-icon icon="PrinterIcon" class="mr-50" size="16" />
                                    Print
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>

                    </b-table>
                </div>
                <div v-else>
                    <p>No receipt vouchers available for this invoice.</p>
                </div>
            </b-modal>


        </div>
    </section>
</template>

<script>
import {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormGroup,
    BFormTextarea
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import AES from 'crypto-js/aes'
import { vueTopprogress } from 'vue-top-progress'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
    components: {
        BCard,
        BRow,
        BButton,
        BCol,
        BFormInput,
        BTable,
        vueTopprogress,
        BPagination,
        vSelect,
        BSpinner,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        BFormGroup,
        BFormTextarea
    },
    data() {
        return {
            t,
            filter: null,
            isBusy: false,
            dataSearch: {
                invoiceNo: null,
                customerId: null,
                contractId: null,
                invoiceTitle: 1,
                startDate: null,
                endDate: null,
                companyId: api.getCompany(),
            },
            receiptVoucher: {
                invoiceId: 0,
                voucherDate: new Date().toISOString().substr(0, 10), // Set to today's date
                amount: null,
                paymentMethod: 'cash',
                receivedBy: api.getUser(),
                notes: null,
            },
            paymentVoucher: {
                invoiceId: 0,
                voucherDate: new Date().toISOString().substr(0, 10), // Set to today's date
                amount: null,
                paymentMethod: 'cash',
                paidBy: api.getUser(),
                notes: null,
            },
            InvoicesList: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20, 25, 50, 100],
            invoiceTitleOptions: [
                { value: 0, text: 'All' },
                { value: 1, text: 'Sale' },
                { value: 2, text: 'Return' },
            ],
            paymentMethodOptions: [
                { value: 'cash', text: 'Cash' },
                { value: 'bank_transfer', text: 'Bank Transfer' },
                { value: 'credit_card', text: 'Credit Card' },
                { value: 'check', text: 'Check' }
            ],
            receiptVouchers: [], // Array to store the list of receipt vouchers

            receiptVoucherFields: [
                { key: 'voucherDate', label: 'Voucher Date', sortable: true },
                { key: 'amount', label: 'Amount', sortable: true },
                { key: 'paymentMethod', label: 'Payment Method', sortable: true },
                { key: 'receivedByUserName', label: 'Received By', sortable: true },
                { key: 'notes', label: 'Notes', sortable: false },
                { key: 'actions', label: 'Actions', sortable: false },

            ],
        }
    },
    computed: {
        fields() {
            return [
                {
                    label: t('Invoice No'),
                    key: 'invoiceNo',
                    sortable: true,
                },
                {
                    label: t('Contract Number'),
                    key: 'contractNumber',
                    sortable: true,
                },
                {
                    label: t('Customer Name'),
                    key: 'customerName',
                    sortable: true,
                },
                {
                    label: t('Invoice Date'),
                    key: 'invoiceDate',
                    sortable: true,
                },
                {
                    label: t('Invoice Total'),
                    key: 'invoiceTotal',
                    sortable: true,
                },
                {
                    label: t('Vat Ratio'),
                    key: 'vatRatio',
                    sortable: true,
                },
                {
                    label: t('Vat Value'),
                    key: 'vatValue',
                    sortable: true,
                },
                {
                    label: t('Invoice Net'),
                    key: 'invoiceNet',
                    sortable: true,
                },
                {
                    label: t('Remaining Balance'),
                    key: 'remainingBalance',
                    sortable: true,
                },

                {
                    label: t('Branch Name'),
                    key: 'branchName',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: t('Actions'),
                },
            ]
        },
    },
    mounted() {
        this.getInvoicesList()
    },
    methods: {
        getInvoicesList() {
            this.$refs.topProgress.start()

            this.isBusy = true
            api.GetFilteredInvoices(
                this.currentPage,
                this.perPage,
                this.dataSearch.startDate,
                this.dataSearch.endDate,
                this.dataSearch.customerId,
                this.dataSearch.contractId,
                this.dataSearch.invoiceTitle,
                this.dataSearch.invoiceNo,
                this.dataSearch.companyId
            )
                .then(result => {
                    this.InvoicesList = result
                    this.totalRows = result.length
                    this.isBusy = false
                    this.$refs.topProgress.done()
                })
                .catch(() => {
                    this.isBusy = false
                    this.$refs.topProgress.fail()
                })
        },
        resetFilters() {
            // Reset filters to their default values
            this.dataSearch = {
                invoiceNo: null,
                customerId: null,
                contractId: null,
                invoiceTitle: 0, // Reset to "All"
                startDate: null,
                endDate: null,
                companyId: api.getCompany(),  // Assuming this remains constant
            };
            this.getInvoicesList();  // Optionally, re-fetch the list with reset filters
        },
        handlePageChange(value) {
            this.currentPage = value
            this.getInvoicesList()
        },
        handlePerPageChange(value) {
            this.perPage = value
            this.getInvoicesList()
        },
        viewInvoiceDetails(invoiceId) {
            const encryptedId = AES.encrypt(invoiceId.toString(), 'secretPassphrase').toString()
            this.$router.push({ name: 'app-invoice-edit', params: { id: encodeURIComponent(encryptedId) } })
        },
        InvoicePDF(id) {
            return api.PrintInvoicePdf(id)
        },
        openUpdateStatusModal(item) {
            this.resetVouchers(); // Reset vouchers before opening the modal

            if (item.invoiceTitle === 1) {
                this.receiptVoucher.invoiceId = item.id;
                this.$refs.receiptVoucherModal.show()
            } else if (item.invoiceTitle === 2) {
                this.paymentVoucher.invoiceId = item.id;
                this.$refs.paymentVoucherModal.show()
            }
        },
        // Method to handle creating a new receipt voucher
        addReceiptVoucher() {
            api.createReceiptVoucher(this.receiptVoucher)
                .then(response => {
                    const data = response.data;

                    if (data.httpCode === 200) {
                        this.$swal({
                            title: 'Good job!',
                            text: 'Receipt Voucher created successfully!',
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                        this.$refs.receiptVoucherModal.hide();
                        this.getInvoicesList(); // Refresh the invoice list if needed
                    } else {
                        // Handle errors from the response
                        const errorMessage = data.errors && data.errors.length > 0
                            ? data.errors[0]
                            : 'An error occurred while creating the receipt voucher.';

                        this.$swal({
                            title: 'Error',
                            text: errorMessage,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                    }
                }, error => {
                    // Handle network errors or unexpected errors
                    const errorMessage = error.response && error.response.data && error.response.data.errors
                        ? error.response.data.errors[0]
                        : 'An unexpected error occurred.';

                    this.$swal({
                        title: 'Error',
                        text: errorMessage,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                });
        },






        // Method to handle creating a new payment voucher
        addPaymentVoucher() {
            api.createPaymentVoucher(this.paymentVoucher)
                .then(response => {
                    const data = response.data;

                    if (data.httpCode === 200) {
                        this.$swal({
                            title: 'Good job!',
                            text: 'Payment Voucher created successfully!',
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                        this.$refs.paymentVoucherModal.hide();
                        this.getInvoicesList(); // Refresh the invoice list if needed
                    } else {
                        // Handle errors from the response
                        const errorMessage = data.errors && data.errors.length > 0
                            ? data.errors[0]
                            : 'An error occurred while creating the payment voucher.';

                        this.$swal({
                            title: 'Error',
                            text: errorMessage,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                    }
                }, error => {
                    // Handle network errors or unexpected errors
                    const errorMessage = error.response && error.response.data && error.response.data.errors
                        ? error.response.data.errors[0]
                        : 'An unexpected error occurred.';

                    this.$swal({
                        title: 'Error',
                        text: errorMessage,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                });
        },


        // Reset method for receiptVoucher
        resetReceiptVoucher() {
            this.receiptVoucher = {
                invoiceId: 0,
                voucherDate: new Date().toISOString().substr(0, 10), // Set to today's date
                amount: null,
                paymentMethod: 'cash',
                receivedBy: api.getUser(), // Reset to the current user
                notes: null,
            };
        },

        // Reset method for paymentVoucher
        resetPaymentVoucher() {
            this.paymentVoucher = {
                invoiceId: 0,
                voucherDate: new Date().toISOString().substr(0, 10), // Set to today's date
                amount: null,
                paymentMethod: 'cash',
                paidBy: api.getUser(), // Reset to the current user
                notes: null,
            };
        },

        // Example: Method to reset both vouchers
        resetVouchers() {
            this.resetReceiptVoucher();
            this.resetPaymentVoucher();
        },

        showReceiptVouchersModal(invoiceId) {
            // Fetch the list of receipt vouchers using the API
            api.getReceiptVouchersByInvoiceId(invoiceId)
                .then(response => {
                    this.receiptVouchers = response.data;
                    this.$refs.receiptVouchersModal.show();
                })
                .catch(error => {
                    console.error('Error fetching receipt vouchers:', error);
                    this.$swal({
                        title: 'Error',
                        text: 'Failed to load Receipt Vouchers data.',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                });
        },

        editVoucher(voucher) {
            // Handle the logic to edit the voucher
            console.log('Editing voucher:', voucher);
        },
        deleteVoucher(voucher) {
            // Handle the logic to delete the voucher
            console.log('Deleting voucher:', voucher);
            // Example: Call an API to delete the voucher and then remove it from the list
        },
        printVoucher(voucher) {
            console.log('print voucher:', voucher);
            return api.PrintReceiptVoucher(voucher.id)
        },
       
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>