<template>
  <section>

    <div>
      <vue-topprogress ref="topProgress" />

      <b-card-actions
        :title="t('Filters')"
        action-collapse
      >
        <div class="m-2">
          <b-row>
            <!-- Field: socialID -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Social ID')">
                <b-form-input
                  v-model="dataSearch.socialID"
                />
              </b-form-group>
            </b-col>
            <!-- Field: customerMobileNO -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Mobile No')">
                <b-form-input
                  v-model="dataSearch.customerMobileNO"
                />
              </b-form-group>
            </b-col>

            <!-- Field:Customer Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Customer Name')">
                <b-form-input
                  v-model="dataSearch.customerName"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Contract No -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Contract No')">
                <b-form-input
                  v-model="dataSearch.contractNO"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Musaned No -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Musaned No')">

                <b-form-input
                  v-model="dataSearch.mosandContractNo"
                />

              </b-form-group>
            </b-col>

            <!-- Field: Worker Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Worker Name')">

                <b-form-input
                  v-model="dataSearch.workerName"
                />

              </b-form-group>
            </b-col>

            <!-- Field: workerProfessionId -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Worker Profession')">
                <v-select
                  v-model="dataSearch.workerProfessionId"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="JobList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />

              </b-form-group>
            </b-col>
            <!-- Field: NationalityId -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Nationality')">

                <v-select
                  v-model="dataSearch.workerNationalityId"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="NationalityList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />

              </b-form-group>
            </b-col>
            <!-- Field: ReligionId -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Religion')">

                <v-select
                  v-model="dataSearch.workerReligionId"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="ReligionsList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />

              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Is Arrived')">
                <v-select
                  v-model="dataSearch.isArrived"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="ArrivedOptions"
                  :reduce="(val) => val.id"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Warranty Period')">
                <v-select
                  v-model="dataSearch.warranty"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="WarrantyOptions"
                  :reduce="(val) => val.id"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Cancelation Side')">

                <v-select
                  v-model="dataSearch.cancelationSide"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="CancelationSideOptions"
                  :reduce="(val) => val.id"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />

              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Paid')">

                <v-select
                  v-model="dataSearch.isPaid"
                  :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                  :options="PaidOptions"
                  :reduce="(val) => val.id"
                  input-id="id"
                  :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                />

              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="text-center"
            >
              <b-button
                variant="primary"
                @click="getcontractsList"
              >
                <span class="text-nowrap">{{ t('Search') }}</span>
              </b-button>
              &nbsp;&nbsp;&nbsp;
              <b-button
                variant="danger"
                @click="resetSearch"
              >
                <span class="text-nowrap">{{ t('Clear') }}</span>
              </b-button>

&nbsp;&nbsp;&nbsp;

              <b-button
                variant="success"
              >
                <export-excel
                  :data="ContractsList"
                  :fields="excel_fields"
                >
                  <span class="text-nowrap">{{ t('Export Excel') }}</span>

                </export-excel>

              </b-button>
            </b-col>

          </b-row>
        </div>
      </b-card-actions>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t('entries') }}</label>

               &nbsp;&nbsp;&nbsp;
              <b-button
                variant="primary"
                :to="{ name: 'app-mediation-add', params: { id: 0 } }"
              >
                <span class="text-nowrap">{{ t("Add Contract") }}</span>
              </b-button>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="ContractsList"
          :fields="fields"
          :filter="filter"
          responsive
          :busy="isBusy"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>{{ t('Loading...') }}</strong>
            </div>
          </template>
          <template #cell(customerName)="data">
            <b-button
              variant="primary"
              block
              @click="encryptId(data.item.id)"
            >
              {{ data.value }}
            </b-button>
          </template>
          <template #cell(customerNameEn)="data">
            <b-button
              variant="primary"
              block
              @click="encryptId(data.item.id)"
            >
              {{ data.value }}
            </b-button>
          </template>
          <template #cell(cancelationSide)="data">
            <b-badge
              v-if="data.item.cancelationSide === 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
            >
              {{ t('Company') }}
            </b-badge>
            <b-badge
              v-if="data.item.cancelationSide === 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
            >
              {{ t('Customer') }}
            </b-badge>
          </template>
          <template #cell(procedureType)="data">
            <b-badge
              v-if="data.item.procedureType === 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
            >
              {{ t('Cancelation') }}
            </b-badge>
          </template>.
          <template #cell(applyPenalty)="data">
            <b-badge
              v-if="data.item.applyPenalty === 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
            >
              {{ t('Yes') }}
            </b-badge>
            <b-badge
              v-if="data.item.applyPenalty === 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
            >
              {{ t('No') }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="encryptId(data.item.id)"
            >
              <feather-icon
                :id="`row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`row-${data.item.id}-edit-icon`"
              />
            </b-link>

          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BTooltip,
  BLink,
  BPagination,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import AES from 'crypto-js/aes'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { vueTopprogress } from 'vue-top-progress'

import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    vueTopprogress,
    BCardActions,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BTooltip,
    BLink,
    BPagination,
    vSelect,
    BSpinner,
    BBadge,
    BFormGroup,
  },
  data() {
    return {
      t,
      filter: null,
      avatarText,
      loading: false,
      validityType: 0,
      dataSearch: {
        customerName: '',
        socialID: '',
        customerMobileNO: '',
        contractNO: '',
        mosandContractNo: 0,
        workerName: '',
        workerProfessionId: 0,
        workerNationalityId: 0,
        workerReligionId: 0,
        isArrived: 0,
        warranty: 0,
        cancelationSide: 0,
        isPaid: 0,
      },
      ContractsList: [],
      SocialStatusList: [],
      NationalityList: [],
      ReligionsList: [],
      JobList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
      ArrivedOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'وصل', nameEn: 'Arrived' },
        { id: 2, name: 'لم يصل', nameEn: 'Not Arrived' },
      ],
      WarrantyOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'داخل فترة الضمان', nameEn: 'In Warranty' },
        { id: 2, name: 'خارج فترة الضمان', nameEn: 'Out Warranty' },
      ],
      CancelationSideOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'الشركة', nameEn: 'Company' },
        { id: 2, name: 'العميل', nameEn: 'Client' },
      ],
      PaidOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'تم الدفع', nameEn: 'Paid' },
        { id: 2, name: 'لم يتم الدفع', nameEn: 'UnPaid' },
      ],
      isBusy: false,
      excel_fields: {
        'Customer Name': 'customerName',
        'Social ID': 'socialID',
        'Customer Mobile': 'customerMobileNO',
        'Customer Profession': 'customerProfession',
        'Contract No': 'contractNO',
        'Mosand Contract No': 'mosandContractNo',
        'Contract Date': 'contractDate',
        'Contract Amount': 'contactAmount',
        'Vat Percentage': 'vatPercentage',
        'Vat Amount': 'vatAmount',
        'Amount With VAT': 'amountWithVAT',
        'Musaned Fee': 'musanedFee',
        'Amount Due': 'amountDue',
        'Worker Name': 'workerName',
        'Visa No': 'visaNo',
        'Visa Expiry Date': 'visaExpiryDate',
        'Worker Profession': 'workerProfessionName',
        'Worker Nationality': 'workerNationalityName',
        'Use Days': 'useDays',
        'Wait Days': 'waitDays',
        'Procedure Type': {
          field: 'procedureType',
          callback: value => (value === 1 ? 'الغاء' : ''),
        },
        'Cancelation Side': {
          field: 'cancelationSide',
          callback: value => (value === 1 ? 'الشركة' : 'العميل'),
        },
        'Cancelation Penalty': 'cancelationPenalty',
        'Apply Penalty': {
          field: 'applyPenalty',
          callback: value => (value === 1 ? 'نعم' : 'لا'),
        },
        'Total Penalty': 'totalPenalty',
        'Contract Status': 'contractStatusName',
      },
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Customer Name'),
          key: 'customerName',
          sortable: true,
        },
        {
          label: t('Social ID'),
          key: 'socialID',
          sortable: true,
        },
        {
          label: t('Customer Mobile No'),
          key: 'customerMobileNO',
          sortable: true,
        },
        {
          label: t('Customer Profession'),
          key: 'customerProfession',
          sortable: true,
        },
        {
          label: t('Contract NO'),
          key: 'contractNO',
          sortable: true,
        },
        {
          label: t('Mosand Contract No'),
          key: 'mosandContractNo',
          sortable: true,
        },

        {
          label: t('Contract Date'),
          key: 'contractDate',
          sortable: true,
        },
        {
          label: t('Contact Amount'),
          key: 'contactAmount',
          sortable: true,
        },

        {
          label: t('Vat Percentage'),
          key: 'vatPercentage',
          sortable: true,
        },
        {
          label: t('Vat Amount'),
          key: 'vatAmount',
          sortable: true,
        },

        {
          label: t('Amount With VAT'),
          key: 'amountWithVAT',
          sortable: true,
        },

        {
          label: t('Musaned Fee'),
          key: 'musanedFee',
          sortable: true,
        },

        {
          label: t('Amount Due'),
          key: 'amountDue',
          sortable: true,
        },
        {
          label: t('Worker Name'),
          key: 'workerName',
          sortable: true,
        },
        {
          label: t('Visa No'),
          key: 'visaNo',
          sortable: true,
        },
        {
          label: t('Visa Expiry Date'),
          key: 'visaExpiryDate',
          sortable: true,
        },
        {
          label: t('Worker Profession'),
          key: 'workerProfessionName',
          sortable: true,
        },
        {
          label: t('Worker Nationality'),
          key: 'workerNationalityName',
          sortable: true,
        },
        {
          label: t('Use Days'),
          key: 'useDays',
          sortable: true,
        },
        {
          label: t('Wait Days'),
          key: 'waitDays',
          sortable: true,
        },
        {
          label: t('Procedure Type'),
          key: 'procedureType',
          sortable: true,
        },
        {
          label: t('Cancelation Side'),
          key: 'cancelationSide',
          sortable: true,
        },
        {
          label: t('Cancelation Penalty'),
          key: 'cancelationPenalty',
          sortable: true,
        },
        {
          label: t('Apply Penalty'),
          key: 'applyPenalty',
          sortable: true,
        },
        {
          label: t('Total Penalty'),
          key: 'totalPenalty',
          sortable: true,
        },
        { key: 'actions', label: t('Actions') },
      ]
    },
  },
  mounted() {
    this.getcontractsList()
    this.getNationalityList()
    this.getJobList()
    this.getReligionsList()
  },
  methods: {
    getcontractsList() {
      this.$refs.topProgress.start()
      this.isBusy = !this.isBusy
      api.getMediationContractsList(this.currentPage - 1, this.perPage, '', '',
        this.dataSearch.customerName, this.dataSearch.socialID, this.dataSearch.customerMobileNO,
        this.dataSearch.contractNO, this.dataSearch.mosandContractNo, this.dataSearch.workerName,
        this.dataSearch.workerProfessionId, this.dataSearch.workerNationalityId, this.dataSearch.workerReligionId,
        this.dataSearch.isArrived, this.dataSearch.warranty,
        api.getCompany(), this.dataSearch.cancelationSide, this.dataSearch.isPaid).then(result => {
        this.ContractsList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.isBusy = !this.isBusy
        this.$refs.topProgress.done()
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getcontractsList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getcontractsList()
    },
    handleFieldsChange(value) {
      console.log(value)
    },
    getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    getReligionsList() {
      api.getAcceptedOptions(9).then(result => {
        this.ReligionsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    resetSearch() {
      this.dataSearch = {
        customerName: '',
        socialID: '',
        customerMobileNO: '',
        contractNO: '',
        mosandContractNo: 0,
        workerName: '',
        workerProfessionId: 0,
        workerNationalityId: 0,
        workerReligionId: 0,
        isArrived: 0,
        warranty: 0,
      }
      this.getcontractsList()
    },
    encryptId(str) {
      const ciphertext = AES.encrypt(str.toString(), 'secretPassphrase')
      this.$router.push({ name: 'app-mediation-edit', params: { id: encodeURIComponent(ciphertext.toString()) } })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
  .my-class .dropdown-menu {
    max-height: 100px;
    overflow-y: auto;
  }
</style>
