<template>
  <section>
    <div>
      <vue-topprogress ref="topProgress" />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t('entries') }}</label>

                  &nbsp;&nbsp;&nbsp;
              <b-button
                variant="primary"
                :to="{ name: 'app-portal-addattachment'}"
              >
                <span class="text-nowrap">{{ t('Add Attachment') }}</span>
              </b-button>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end" />
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col
            v-for="(n, index) in DataList"
            :key="index"
            class="col-md-3 btn"
            @click="SetImage(getLogo(n.filePath == null ? '' : n.filePath))"
          >

            <b-avatar
              size="200px"
              :src="getLogo(n.filePath == null ? '' : n.filePath)"
              rounded
            />

          </b-col>
        </b-row>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BPagination,
  BAvatar,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { vueTopprogress } from 'vue-top-progress'

import vSelect from 'vue-select'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BPagination,
    vSelect,
    BAvatar,
    vueTopprogress,
  },
  setup() {

  },
  data() {
    return {
      t,
      filter: null,
      // Sidebar
      isAddEditCountrySidebarActive: false,
      validityType: 0,
      dataSearch: {
        name: '',
        statusId: 0,
      },
      DataList: [],
      statusList: [
        { value: 0, label: 'الكل' },
        { value: 1, label: 'نشط' },
        { value: 2, label: 'غير نشط' },
      ],
      totalRows: 1,
      emptytext: '',
      currentPage: 1,
      perPage: 8,
      avatarText,
      pageOptions: [5, 8, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Name'),
          key: 'fileName',
          sortable: true,
        },
        {
          label: t('Path'),
          key: 'filePath',
          sortable: true,
        },
        {
          label: t('Size'),
          key: 'fileSize',
          sortable: true,
        },
        {
          label: t('Extension'),
          key: 'fileExtension',
          sortable: true,
        },
      ]
    },
  },
  mounted() {
    this.LoadData()
  },
  methods: {
    LoadData() {
      this.$refs.topProgress.start()
      this.emptytext = 'Loading Data'
      api.GetAllCompanyAttachment(this.currentPage - 1, this.perPage, '', '').then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.$refs.topProgress.done()
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.LoadData()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.LoadData()
    },
    getLanguage() {
      return api.getLanguage()
    },
    getLogo(dbPath) {
      return api.CompanyLogo(dbPath)
    },
  },
}
</script>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
