<template>
  <section>
    <div>
      <vue-topprogress ref="topProgress" />
      <b-card>
        <b-card-header class="pb-50">
          <h5>
            {{ t('Filters') }}

          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>

            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ t('Name') }}
              </label>
              <div class="d-flex align-items-center justify-content-end">

                <b-form-input
                  id="search"
                  v-model="dataSearch.name"
                />
                &nbsp;&nbsp;&nbsp;
                <b-button
                  variant="primary"
                  @click="getcustomersList"
                >
                  <span class="text-nowrap">{{ t('Search') }}</span>
                </b-button>
              </div>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>{{ t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t("entries") }}</label>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">{{ t("Clear") }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="CustomersList"
          :fields="fields"
          :filter="filter"
          :busy="isBusy"
          responsive=""
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>{{ t("Loading...") }}</strong>
            </div>
          </template>
          <template #cell(customerNameEn)="data">
            <b-button
              variant="primary"
              block
              @click="encryptId(data.item.id)"
            >
              {{ data.item.titleNameEn }}:{{ data.item.customerNameEn }}
            </b-button>

          </template>
          <template #cell(customerName)="data">
            <b-button
              block
              variant="primary"
              @click="encryptId(data.item.id)"
            >
              {{ data.value }}
            </b-button>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-button
              variant="success"
              @click="encryptId(data.item.id)"
            >
              {{ t("Edit") }}
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BSpinner,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import AES from 'crypto-js/aes'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { vueTopprogress } from 'vue-top-progress'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    vueTopprogress,
    BPagination,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      t,
      filter: null,
      avatarText,
      emptytext: '',
      isBusy: false,
      validityType: 0,
      dataSearch: {
        name: '',
      },
      CustomersList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Title'),
          key: api.getLanguage() === 1 ? 'titleName' : 'titleNameEn',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: t('Name'),
          key: api.getLanguage() === 1 ? 'customerName' : 'customerNameEn',
          sortable: true,
        },
        {
          label: t('Identity / Iqama No'),
          key: 'identityOrIqamaNo',
          sortable: true,
        },

        {
          label: t('Gender'),
          key: api.getLanguage() === 1 ? 'sexName' : 'sexNameEn',
          sortable: true,
        },
        {
          label: t('Nationality'),
          key: api.getLanguage() === 1 ? 'nationalityName' : 'nationalityNameEn',
          sortable: true,
        },

        {
          label: t('Mobile No'),
          key: 'mobileNo',
          sortable: true,
        },

        {
          label: t('Job'),
          key: 'job',
          sortable: true,
        },
        { key: 'actions', label: t('Actions') },
      ]
    },
  },
  mounted() {
    this.getcustomersList()
  },
  methods: {
    getcustomersList() {
      this.$refs.topProgress.start()
      this.isBusy = !this.isBusy
      this.emptytext = 'Loading Data'
      api.getcustomersList(this.currentPage - 1, this.perPage, '', '', this.dataSearch.name, api.getCompany(), 0).then(result => {
        this.CustomersList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.isBusy = !this.isBusy
        this.$refs.topProgress.done()
      })
    },
    handlePageChange(value) {
      console.log(value)
      this.currentPage = value
      this.getcustomersList()
    },
    handlePerPageChange(value) {
      console.log(value)
      this.perPage = value
      this.getcustomersList()
    },
    handleFieldsChange(value) {
      console.log(value)
    },
    getLanguage() {
      return api.getLanguage()
    },
    encryptId(str) {
      const ciphertext = AES.encrypt(str.toString(), 'secretPassphrase')
      this.$router.push({ name: 'app-customer-edit', params: { id: encodeURIComponent(ciphertext.toString()) } })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
