import axios from 'axios'
import http from './http-common'

const API_URL = 'https://estkdamapi.azurewebsites.net/api/'
 // const API_URL = 'http://localhost:5001/api/'

   const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  // Add a response interceptor
  api.interceptors.response.use(
    response => {
      // Simply return the response if everything is fine
      return response;
    },
    error => {
      // Extract error message
      let errorMessage = 'An error occurred.';
      if (error.response && error.response.data && error.response.data.Message) {
        errorMessage = error.response.data.Message;
      } else if (error.response && error.response.statusText) {
        errorMessage = error.response.statusText;
      }
  
      // Show the error message using SweetAlert
      Vue.prototype.$swal({
        title: 'Error',
        text: errorMessage,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
  
      // Reject the promise to propagate the error
      return Promise.reject(error);
    }
  );

export default {
  CompanyLogo(serverPath) {
  return `https://estkdamapi.azurewebsites.net/${serverPath}`
  // return `http://localhost:5001/${serverPath}`
  },

  login(creds) {
    return axios
      .post(`${API_URL}account/Login`, creds)
  },
  Signup(dataModel) {
    return axios
      .post(`${API_URL}account/register`, dataModel)
  },
  getAuthHeader() {
    return {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    }
  },

  getCompayData(companyId) {
    return axios
      .get(`${API_URL}company/${companyId}`)
  },
  CreateUpdateCompay(id, dataModel) {
    return axios
      .post(`${API_URL}company/${id}`, dataModel)
  },
  getAcceptedOptionsList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id, acceptedValueId) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    if (acceptedValueId !== 0) {
      params.append('SearchCritira.AcceptedValueId', acceptedValueId)
    }
    return axios
      .get(`${API_URL}accepted-value?${params}`)
  },
  getAcceptedOption(acceptedValueId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}accepted-value/${acceptedValueId}`)
  },
  getAcceptedOptions(acceptedValueId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}accepted-value/drop-down/${acceptedValueId}/${parseInt(localStorage.getItem('companyId'))}`)
  },
  CreateUpdateCustomer(id, dataModel) {
    return axios
      .post(`${API_URL}customer/${id}`, dataModel)
  },
  CreateUpdateContract(id, dataModel) {
    return axios
      .post(`${API_URL}contract/${id}`, dataModel)
  },
  CreateUpdateService(id, dataModel) {
    return axios
      .post(`${API_URL}service/${id}`, dataModel)
  },
  CreateUpdateEmployee(id, dataModel) {
    return axios
      .post(`${API_URL}employee/${id}`, dataModel)
  },
  CreateExternalOffice(id, dataModel) {
    return axios
      .post(`${API_URL}external-Office/${id}`, dataModel)
  },
  getRegionList() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}region/get-regions`)
  },
  getCitiesList(regionId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}city/GetCities/${regionId}`)
  },
  getDistrictsList(parentId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}accepted-value/drop-down-child/${parentId}/${parseInt(localStorage.getItem('companyId'))}`)
  },

  CreateUpdateAcceptedValue(id, dataModel) {
    return axios
      .post(`${API_URL}accepted-value/${id}`, dataModel)
  },

  CreateUpdatefollowup(id, dataModel) {
    return axios
      .post(`${API_URL}contract-followup/${id}`, dataModel)
  },

  CreateUpdateemploymentarrival(id, dataModel) {
    return axios
      .post(`${API_URL}employment-arrival/${id}`, dataModel)
  },

  getcustomersList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}customer?${params}`)
  },
  getserviceList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}service?${params}`)
  },
  getemployeeList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}employee?${params}`)
  },
  getExternalOfficeList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}external-Office?${params}`)
  },
  searchcustomers(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}customer/search?${params}`)
  },
  getcontractsList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}contract?${params}`)
  },
  getfollowupList(PageIndex, PageSize, Order, OrderDir, Name, ContractId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (ContractId !== 0) {
      params.append('SearchCritira.ContractId', ContractId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}contract-followup?${params}`)
  },
  getcompnyList(PageIndex, PageSize, Order, OrderDir, Name) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    return axios
      .get(`${API_URL}company?${params}`)
  },
  getMenusList(PageIndex, PageSize, Order, OrderDir, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}Menus?${params}`)
  },
  getcontract(id) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}contract/${id}`)
  },
  getbycontractId(id) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}employment-arrival/getbycontractId/${id}`)
  },
  getcustomer(id) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}customer/${id}`)
  },
  getemployee(id) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}employee/${id}`)
  },
  getexternalOffice(id) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}external-Office/${id}`)
  },

  getservicebyid(id) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}service/${id}`)
  },

  GetByPort(model) {
    return axios
      // eslint-disable-next-line radix
      .post(`${API_URL}company/GetByPort`, model)
  },

  UploadCompnyLogo(file, onUploadProgress, id) {
    const formData = new FormData()
    formData.append('file', file)
    return http.post(`/upload/company-logo/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },

  getCompaybranchs() {
    return axios
    // eslint-disable-next-line radix
      .get(`${API_URL}company-branch/drop-down/${parseInt(localStorage.getItem('companyId'))}`)
  },
  getcustomersdropdown() {
    return axios
    // eslint-disable-next-line radix
      .get(`${API_URL}customer/drop-down/${parseInt(localStorage.getItem('companyId'))}`)
  },
  getservicedropdown() {
    return axios
    // eslint-disable-next-line radix
      .get(`${API_URL}service/drop-down/${parseInt(localStorage.getItem('companyId'))}`)
  },
  getexternalOfficedropdown() {
    return axios
    // eslint-disable-next-line radix
      .get(`${API_URL}external-Office/drop-down/${parseInt(localStorage.getItem('companyId'))}`)
  },
  wabas(companyId) {
    return axios
      .get(`${API_URL}Messaging/wabas/${companyId}`)
  },
  messages(companyId) {
    return axios
      .get(`${API_URL}Messaging/messages/${companyId}`)
  },

  message(dataModel) {
    return axios
      .post(`${API_URL}Messaging/message`, dataModel)
  },
  checkAuth() {
    const jwt = localStorage.getItem('id_token')
    if (jwt) {
      this.user.authenticated = true
    } else {
      this.user.authenticated = false
    }
  },

  getUser() {
    return localStorage.getItem('userId')
  },
  getCompany() {
    // eslint-disable-next-line radix
    return parseInt(localStorage.getItem('companyId'))
  },
  getLanguage() {
    // eslint-disable-next-line radix
    return parseInt(localStorage.getItem('language'))
  },

  groups(companyId) {
    return axios
      .get(`${API_URL}Authorization/groups/${companyId}`)
  },
  getGroup(groupId) {
    return axios
      .get(`${API_URL}Authorization/group/${groupId}`)
  },

  group(dataModel) {
    return axios
      .post(`${API_URL}Authorization/group`, dataModel)
  },

  CreateUpdateMediation(id, dataModel) {
    return axios
      .post(`${API_URL}mediation-contracts/${id}`, dataModel)
  },

  getMediationContractsList(PageIndex, PageSize, Order, OrderDir, CustomerName, SocialID, CustomerMobileNO,
    ContractNO, MosandContractNo, WorkerName, WorkerProfessionId, WorkerNationalityId, WorkerReligionId,
    IsArrived, Warranty, CompanyId, cancelationSide, IsPaid) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (CustomerName !== '') {
      params.append('SearchCritira.CustomerName', CustomerName)
    }
    if (SocialID !== '') {
      params.append('SearchCritira.SocialID', SocialID)
    }
    if (CustomerMobileNO !== '') {
      params.append('SearchCritira.CustomerMobileNO', CustomerMobileNO)
    }
    if (ContractNO !== '') {
      params.append('SearchCritira.ContractNO', ContractNO)
    }
    if (MosandContractNo !== 0) {
      params.append('SearchCritira.MosandContractNo', MosandContractNo)
    }
    if (WorkerName !== '') {
      params.append('SearchCritira.WorkerName', WorkerName)
    }
    if (WorkerProfessionId !== 0) {
      params.append('SearchCritira.WorkerProfessionId', WorkerProfessionId)
    }
    if (WorkerNationalityId !== 0) {
      params.append('SearchCritira.WorkerNationalityId', WorkerNationalityId)
    }
    if (WorkerReligionId !== 0) {
      params.append('SearchCritira.WorkerReligionId', WorkerReligionId)
    }
    if (IsArrived !== 0) {
      params.append('SearchCritira.IsArrived', IsArrived)
    }
    if (Warranty !== 0) {
      params.append('SearchCritira.Warranty', Warranty)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (cancelationSide !== 0) {
      params.append('SearchCritira.CancelationSide', cancelationSide)
    }
    if (IsPaid !== 0) {
      params.append('SearchCritira.IsPaid', IsPaid)
    }
    return axios
      .get(`${API_URL}mediation-contracts?${params}`)
  },
  getMediation(id) {
    return axios
      .get(`${API_URL}mediation-contracts/${id}`)
  },

  getAccountsList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}account-tree?${params}`)
  },

  CreateUpdateAccount(id, dataModel) {
    return axios
      .post(`${API_URL}account-tree/${id}`, dataModel)
  },
  getAccount(id) {
    return axios
      .get(`${API_URL}account-tree/${id}`)
  },
  GetAccountAutoNum(id) {
    return axios
      .get(`${API_URL}account-tree/GetAccountAutoNum/${id}`)
  },
  GetBondAutoNum(CompanyId, YearId, BondType) {
    return axios
      .get(`${API_URL}bond/GetBondAutoNum/${CompanyId}/${YearId}/${BondType}`)
  },
  getbond(id) {
    return axios
      .get(`${API_URL}bond/${id}`)
  },
  CreateUpdateBond(id, dataModel) {
    return axios
      .post(`${API_URL}bond/${id}`, dataModel)
  },
  getBondList(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id, RecSource, RecId, BondType) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    if (RecSource !== 0) {
      params.append('SearchCritira.RecSource', RecSource)
    }
    if (RecId !== 0) {
      params.append('SearchCritira.RecId', RecId)
    }
    if (BondType !== 0) {
      params.append('SearchCritira.BondType', BondType)
    }
    return axios
      .get(`${API_URL}bond?${params}`)
  },

  GetTreeAccounts(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}account-tree/GetTree?${params}`)
  },
  GetBySocialID(SocialID) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}customer/GetBySocialID/${SocialID}/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetAllAccountChild(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}account-tree/GetAllChild?${params}`)
  },
  GetAccountByRecId(RecSource, RecId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}account-tree/GetByRecId/${RecSource}/${RecId}`)
  },
  GetMediationAutoNum(CompanyId) {
    return axios
      .get(`${API_URL}mediation-contracts/GetAutoNum/${CompanyId}`)
  },

  GetAllGroups(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}account/GetAllGroups?${params}`)
  },
  CreateUpdateGroup(id, dataModel) {
    return axios
      .post(`${API_URL}account/CreateUpdateGroup/${id}`, dataModel)
  },
  GetGroupById(id) {
    return axios
      .get(`${API_URL}account/GetGroupByIdAsync/${id}`)
  },
  GetMenusDropDownList() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}account/GetMenusDropDownList`)
  },
  GetAllUsers(PageIndex, PageSize, Order, OrderDir, Name, Email, PhoneNumber, RoleName, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (Email !== '') {
      params.append('SearchCritira.Email', Email)
    }
    if (PhoneNumber !== '') {
      params.append('SearchCritira.PhoneNumber', PhoneNumber)
    }
    if (RoleName !== '') {
      params.append('SearchCritira.RoleName', RoleName)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}account/GetAllUsers?${params}`)
  },
  AddEditUser(id, dataModel) {
    return axios
      .post(`${API_URL}account/AddEditUser/${id}`, dataModel)
  },
  GetUserByIdAsync(id) {
    return axios
      .get(`${API_URL}account/GetUserByIdAsync/${id}`)
  },
  GroupDropDown() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}account/group-drop-down/${parseInt(localStorage.getItem('companyId'))}`)
  },
  PrintInvoice(id) {
    window.open(`${API_URL}mediation-contracts/Invoice?uuid=${id}`, '_blank')
  },
  PrintBond(id) {
    window.open(`${API_URL}bond/Print?id=${id}`, '_blank')
  },
  GetAllInvoiceForContract(id) {
    return axios
      .get(`${API_URL}mediation-contracts/GetAllInvoiceForContract/${id}`)
  },
  CreateJournalFromInvoice(id, dataModel) {
    return axios
      .post(`${API_URL}journal/CreateJournalFromInvoice/${id}`, dataModel)
  },
  PrintJournal(id) {
    window.open(`${API_URL}journal/Print?id=${id}`, '_blank')
  },
  CreateJournalFromBond(id, dataModel) {
    return axios
      .post(`${API_URL}journal/CreateJournalFromBond/${id}`, dataModel)
  },
  GetJournal(PageIndex, PageSize, Order, OrderDir, Name, CompanyId, Id) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }
    if (CompanyId !== 0) {
      params.append('SearchCritira.CompanyId', CompanyId)
    }
    if (Id !== 0) {
      params.append('SearchCritira.Id', Id)
    }
    return axios
      .get(`${API_URL}journal?${params}`)
  },
  GetGeneralLedger(FromDate, ToDate, AccountId) {
    const params = new URLSearchParams()
    params.append('FromDate', FromDate)
    params.append('ToDate', ToDate)
    params.append('AccountId', AccountId)
    return axios
      .get(`${API_URL}journal/GetGeneralLedger?${params}`)
  },
  GeneralLedger(FromDate, ToDate, AccountId) {
    window.open(`${API_URL}journal/GeneralLedger?FromDate=${FromDate}&ToDate=${ToDate}&AccountId=${AccountId}`, '_blank')
  },
  GetRegisteredCustomersCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetRegisteredCustomersCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetMediationContractCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetMediationContractCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetIndividualContractsCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetIndividualContractsCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetUnpaidBillsCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetUnpaidBillsCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetEmploymentArrivedCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetEmploymentArrivedCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetEmploymentWithinWarrantyCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetEmploymentWithinWarrantyCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetEmploymentOutsideWarrantyCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetEmploymentOutsideWarrantyCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetContractsCanceledCount() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}mediation-contracts/GetContractsCanceledCount/${parseInt(localStorage.getItem('companyId'))}`)
  },
  GetAccountBalanceById(id) {
    return axios
      .get(`${API_URL}journal/GetAccountBalanceById/${id}`)
  },

  GetAllCountry(PageIndex, PageSize, Order, OrderDir, Name) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }

    return axios
      .get(`${API_URL}country?${params}`)
  },
  CreateUpdateCountry(id, dataModel) {
    return axios
      .post(`${API_URL}country/${id}`, dataModel)
  },
  GetCountryById(id) {
    return axios
      .get(`${API_URL}country/${id}`)
  },

  GetAllRegion(PageIndex, PageSize, Order, OrderDir, CountryId, Name) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (CountryId !== 0 || CountryId !== null || CountryId !== 'null') {
      params.append('SearchCritira.CountryId', CountryId)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }

    return axios
      .get(`${API_URL}region?${params}`)
  },
  CreateUpdateRegion(id, dataModel) {
    return axios
      .post(`${API_URL}region/${id}`, dataModel)
  },
  GetRegionById(id) {
    return axios
      .get(`${API_URL}region/${id}`)
  },

  GetAllCity(PageIndex, PageSize, Order, OrderDir, CountryId, RegionId, Name) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (CountryId !== 0) {
      params.append('SearchCritira.CountryId', CountryId)
    }
    if (RegionId !== 0) {
      params.append('SearchCritira.RegionId', RegionId)
    }
    if (Name !== '') {
      params.append('SearchCritira.Name', Name)
    }

    return axios
      .get(`${API_URL}city?${params}`)
  },
  CreateUpdateCity(id, dataModel) {
    return axios
      .post(`${API_URL}city/${id}`, dataModel)
  },
  GetCityById(id) {
    return axios
      .get(`${API_URL}city/${id}`)
  },

  GetCountryDropDownList() {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}country/drop-down`)
  },
  GetRegionDropDownList(countryId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}region/drop-down/${countryId}`)
  },
  GetCityDropDownList(countryId, regionId) {
    return axios
      // eslint-disable-next-line radix
      .get(`${API_URL}city/drop-down/${countryId}/${regionId}`)
  },
  GetPortalPages(PageIndex, PageSize, Order, OrderDir, PageTitle) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (PageTitle !== '') {
      params.append('SearchCritira.PageTitle', PageTitle)
    }

    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}portal/GetPortalPages?${params}`)
  },
  AddEditPortalPage(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditPortalPage/${id}`, dataModel)
  },
  GetPortalPageById(id) {
    return axios
      .get(`${API_URL}portal/GetPortalPageById/${id}`)
  },
  GetPortalHeaderLeft(PageIndex, PageSize, Order, OrderDir, Title) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Title !== '') {
      params.append('SearchCritira.Title', Title)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}portal/GetPortalHeaderLeft?${params}`)
  },
  AddEditPortalHeaderLeft(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditPortalHeaderLeft/${id}`, dataModel)
  },
  GetPortalHeaderLeftById(id) {
    return axios
      .get(`${API_URL}portal/GetPortalHeaderLeftById/${id}`)
  },
  GetPortalFaqs(PageIndex, PageSize, Order, OrderDir, Title) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Title !== '') {
      params.append('SearchCritira.Title', Title)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}portal/GetPortalFaqs?${params}`)
  },
  AddEditPortalFaqs(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditPortalFaqs/${id}`, dataModel)
  },
  GetPortalFaqsById(id) {
    return axios
      .get(`${API_URL}portal/GetPortalFaqsById/${id}`)
  },

  GetCustomersMessages(PageIndex, PageSize, Order, OrderDir, PhoneNumber) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (PhoneNumber !== '') {
      params.append('SearchCritira.PhoneNumber', PhoneNumber)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}portal/GetCustomersMessages?${params}`)
  },
  AddEditCustomerMessage(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditCustomerMessage/${id}`, dataModel)
  },

  GetCustomerMessage(id) {
    return axios
      .get(`${API_URL}portal/GetCustomerMessage/${id}`)
  },
  EditEmailSetting(id, dataModel) {
    return axios
      .post(`${API_URL}company/EditEmailSetting/${id}`, dataModel)
  },
  GetEmailSetting(id) {
    return axios
      .get(`${API_URL}company/GetEmailSetting/${id}`)
  },
  GetPortalSliders(PageIndex, PageSize, Order, OrderDir, Title) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Title !== '') {
      params.append('SearchCritira.Title', Title)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}portal/GetPortalSliders?${params}`)
  },
  AddEditSlider(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditSlider/${id}`, dataModel)
  },
  GetPortalSliderById(id) {
    return axios
      .get(`${API_URL}portal/GetPortalSliderById/${id}`)
  },

  GetPortalServices(PageIndex, PageSize, Order, OrderDir, ServicesDes) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (ServicesDes !== '') {
      params.append('SearchCritira.ServicesDes', ServicesDes)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}portal/GetPortalServices?${params}`)
  },
  AddEditPortalService(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditPortalService/${id}`, dataModel)
  },
  GetPortalServiceById(id) {
    return axios
      .get(`${API_URL}portal/GetPortalServiceById/${id}`)
  },

  GetPortalServicesDetails(PageIndex, PageSize, Order, OrderDir, Title, Section) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (Title !== '') {
      params.append('SearchCritira.Title', Title)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))
    params.append('SearchCritira.Section', Section)
    return axios
      .get(`${API_URL}portal/GetPortalServicesDetails?${params}`)
  },
  AddEditPortalServicesDetail(id, dataModel) {
    return axios
      .post(`${API_URL}portal/AddEditPortalServicesDetail/${id}`, dataModel)
  },
  GetPortalServicesDetailsById(id) {
    return axios
      .get(`${API_URL}portal/GetPortalServicesDetailsById/${id}`)
  },
  AddEditCompanyAttachment(id, dataModel) {
    return axios
      .post(`${API_URL}company/AddEditCompanyAttachment/${id}`, dataModel)
  },
  GetAllCompanyAttachment(PageIndex, PageSize, Order, OrderDir) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }

    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))
    return axios
      .get(`${API_URL}company/GetAllCompanyAttachment?${params}`)
  },
  GetRentalContracts(PageIndex, PageSize, Order, OrderDir, ServiceId, EmployeeId, CustomerId) {
    const params = new URLSearchParams()
    if (PageIndex !== 0) {
      params.append('PageIndex', PageIndex)
    }
    if (PageSize !== 0) {
      params.append('PageSize', PageSize)
    }
    if (Order !== '') {
      params.append('Order', Order)
    }
    if (OrderDir !== '') {
      params.append('OrderDir', OrderDir)
    }
    if (ServiceId !== 0) {
      params.append('SearchCritira.ServiceId', ServiceId)
    }
    if (EmployeeId !== 0) {
      params.append('SearchCritira.EmployeeId', EmployeeId)
    }
    if (CustomerId !== 0) {
      params.append('SearchCritira.CustomerId', CustomerId)
    }
    // eslint-disable-next-line radix
    params.append('SearchCritira.CompanyId', parseInt(localStorage.getItem('companyId')))

    return axios
      .get(`${API_URL}RentalContract?${params}`)
  },
  AddEditRentalContract(id, dataModel) {
    return axios
      .post(`${API_URL}RentalContract/${id}`, dataModel)
  },
  GetRentalContractById(id) {
    return axios
      .get(`${API_URL}RentalContract/${id}`)
  },
  getemployeesdropdown() {
    return axios
    // eslint-disable-next-line radix
      .get(`${API_URL}employee/drop-down/${parseInt(localStorage.getItem('companyId'))}`)
  },
  getemployeesstatistics() {
    return axios
    // eslint-disable-next-line radix
      .get(`${API_URL}employee/get-statistics/${parseInt(localStorage.getItem('companyId'))}`)
  },
   deleteService(id){
    return axios
    .delete(`${API_URL}service/${id}`)
   
  },
  deleteEmployee(id){
    return axios
    .delete(`${API_URL}employee/${id}`)
   
  },
  PrintInvoiceForRentalContract(id) {
    window.open(`${API_URL}RentalContract/Invoice?id=${id}`, '_blank')
  },
  deleteRentalContract(id){
    return axios
    .delete(`${API_URL}RentalContract/${id}`)
   
  },

  InvoicePDF(id) {
    window.open(`${API_URL}RentalContract/InvoicePDF?id=${id}`, '_blank')
  },
  GetDomesticWorkerFormPdf(id) {
    window.open(`${API_URL}RentalContract/GetDomesticWorkerFormPdf?id=${id}`, '_blank')
  },
  generate(id) {
    window.open(`${API_URL}RentalContract/GetContractPdf?id=${id}`, '_blank')
  },

  calculateVariableSalary(employeeId, month, year) {
    return axios
      .get(`${API_URL}employee/calculate-variable-salary`, {
        params: {
          employeeId,
          month,
          year
        }
      });
  },

  updateRentalContractStatus(dataModel) {
    return axios
      .post(`${API_URL}RentalContract/UpdateStatus`, dataModel)
  },

  GetFilteredInvoices(
    PageNumber = 1, 
    PageSize = 10, 
    StartDate = null, 
    EndDate = null, 
    CustomerId = null, 
    ContractId = null, 
    InvoiceTitle = 0, 
    InvoiceNo = null
  ) {
    const params = new URLSearchParams();

    // Pagination
    if (PageNumber !== 1) {
      params.append('PageNumber', PageNumber);
    }
    if (PageSize !== 10) {
      params.append('PageSize', PageSize);
    }

    // Date range
    if (StartDate !== null) {
      params.append('startDate', StartDate);
    }
    if (EndDate !== null) {
      params.append('endDate', EndDate);
    }

    // Filtering by Customer, Contract, Invoice Title, and Invoice Number
    if (CustomerId !== null && CustomerId !== 0) {
      params.append('customerId', CustomerId);
    }
    if (ContractId !== null && ContractId !== 0) {
      params.append('contractId', ContractId);
    }
   
      params.append('invoiceTitle', InvoiceTitle);
    
    if (InvoiceNo !== null && InvoiceNo !== 0) {
      params.append('invoiceNo', InvoiceNo);
    }

    // Mandatory CompanyId
    const companyId = localStorage.getItem('companyId');
    if (companyId !== null) {
      params.append('companyId', parseInt(companyId));
    } else {
      throw new Error("CompanyId is required.");
    }

    // Make the API call
    return axios.get(`${API_URL}Invoices/GetFilteredInvoices?${params.toString()}`)
      .then(response => response.data)
      .catch(error => {
        console.error("There was an error fetching the invoices!", error);
        throw error;
      });
  },
  PrintInvoicePdf(id) {
    window.open(`${API_URL}Invoices/PrintInvoice?uuid=${id}`, '_blank')
  },

      // Method to create a new ReceiptVoucher
      createReceiptVoucher(voucherData) {
        return axios.post(`${API_URL}ReceiptVouchers/Create`, voucherData);
    },

    // Method to update an existing ReceiptVoucher
    updateReceiptVoucher(voucherId, voucherData) {
        return axios.put(`${API_URL}ReceiptVouchers/${voucherId}`, voucherData);
    },

    // Method to create a new PaymentVoucher
    createPaymentVoucher(voucherData) {
        return axios.post(`${API_URL}PaymentVouchers/Create`, voucherData);
    },

    // Method to update an existing PaymentVoucher
    updatePaymentVoucher(voucherId, voucherData) {
        return axios.put(`${API_URL}PaymentVouchers/${voucherId}`, voucherData);
    },

    getReceiptVouchersByInvoiceId(invoiceId) {
      return axios.get(`${API_URL}ReceiptVouchers/GetByInvoiceId/${invoiceId}`);
  },
  PrintReceiptVoucher(id) {
    window.open(`${API_URL}ReceiptVouchers/PrintReceiptVoucher/${id}`, '_blank')
  },
  CalculateVariableSalaries(month = null, year = null, companyId = null) {
    const params = new URLSearchParams();

    // Mandatory parameters
    if (month !== null) {
      params.append('month', month);
    } else {
      throw new Error("Month is required.");
    }

    if (year !== null) {
      params.append('year', year);
    } else {
      throw new Error("Year is required.");
    }

    if (companyId !== null) {
      params.append('companyId', companyId);
    } else {
      const storedCompanyId = localStorage.getItem('companyId');
      if (storedCompanyId !== null) {
        params.append('companyId', parseInt(storedCompanyId));
      } else {
        throw new Error("CompanyId is required.");
      }
    }

    // Make the API call
    return axios.get(`${API_URL}Payroll/CalculateVariableSalaries?${params.toString()}`)
      .then(response => response.data)
      .catch(error => {
        console.error("There was an error calculating the salaries!", error);
        throw error;
      });
  }


}
