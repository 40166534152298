<template>
  <section>
    <div>
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- page length -->

              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>entries</label>

            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"

          :items="ExternalOfficeList"
          :fields="fields"
          :filter="filter"
          responsive=""
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(externalOfficeName)="data">
            <b-link
              :to="{ name: 'app-externalOffice-edit', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(externalOfficeNameEn)="data">
            <b-link
              :to="{ name: 'app-externalOffice-edit', params: { id: data.item.id } }"
              class="font-weight-bold"
            >
              {{ data.value }}
            </b-link>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'app-externalOffice-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BLink,
    BPagination,
    vSelect,
  },
  data() {
    return {
      filter: null,
      validityType: 0,
      dataSearch: {},
      ExternalOfficeList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      ConditionOptions: [
        { value: 0, label: 'Contains' },
        { value: 1, label: 'Equals' },
        { value: 2, label: 'Starts with...' },
        { value: 3, label: 'More than...' },
        { value: 4, label: 'Less than...' },
        { value: 5, label: 'Equal or more than...' },
        { value: 6, label: 'Equal or less than...' },
      ],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Name'),
          key: api.getLanguage() === 1 ? 'externalOfficeName' : 'externalOfficeNameEn',
          sortable: true,
        },
        {
          label: t('Coordinator'),
          key: api.getLanguage() === 1 ? 'coordinatorName' : 'coordinatorNameEn',
          sortable: true,
        },
        {
          label: t('CRN'),
          key: 'crn',
          sortable: true,
        },
        {
          label: t('Phone Numbers'),
          key: 'phoneNumbers',
          sortable: true,
        },
        {
          label: t('Fax Numbers'),
          key: 'faxNumbers',
          sortable: true,
        },
        {
          label: t('Address'),
          key: 'address',
          sortable: true,
        },
        {
          label: t('Branch'),
          key: api.getLanguage() === 1 ? 'branchName' : 'branchNameEn',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
  },
  mounted() {
    this.getExternalOfficeList()
  },
  methods: {
    getExternalOfficeList() {
      api.getExternalOfficeList(this.currentPage - 1, this.perPage, '', '', '', api.getCompany(), 0).then(result => {
        this.ExternalOfficeList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getExternalOfficeList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getExternalOfficeList()
    },
    getLanguage() {
      return api.getLanguage()
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
