<template>
  <section>
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row>

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card>

              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <br>
                  <b-card-title>
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span>{{
                      t("Information")
                    }}</span>
                  </b-card-title>
                  <hr class="invoice-spacing">
                </b-col>
                <!-- Country -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="t('Country')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Country')"
                    >
                      <v-select
                        v-model="dataModel.countryId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="CountryList"
                        :reduce="(val) => val.id"
                        :clearable="true"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                />
                <!-- nameAr -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="t('Name')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Name')"
                    >
                      <b-form-input
                        v-model="dataModel.nameAr"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- nameEn -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="t('Name En')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Name En')"
                    >
                      <b-form-input
                        v-model="dataModel.nameEn"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
              <br>
              <br>

            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                block
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="danger"
                block
                :to="{ name: 'app-SystemSettings-Regions'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { vueTopprogress } from 'vue-top-progress'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vueTopprogress,
    BCardTitle,
    BSpinner,
    vSelect,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      selected: [],
      dataModel: {},
      MenusList: [],
      CountryList: [],
      id: 0,
      isLoading: false,
    }
  },
  mounted() {
    this.LoadCountryList()
    if (router.currentRoute.params.id !== 0) {
      this.$refs.topProgress.start()
      api.GetRegionById(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          this.isLoading = true
          // eslint-disable-next-line
        const dataModel = {
            id: router.currentRoute.params.id,
            countryId: this.dataModel.countryId,
            nameAr: this.dataModel.nameAr,
            nameEn: this.dataModel.nameEn,
          }
          api.CreateUpdateRegion(router.currentRoute.params.id, dataModel).then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isLoading = false
            this.$refs.topProgress.done()

            if (router.currentRoute.params.id === 0) {
              this.$router.push({ name: 'app-region-add', params: { id: result.data.data } })
            }
          })
            .catch(error => {
              this.$refs.topProgress.done()
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    LoadCountryList() {
      this.$refs.topProgress.start()
      api.GetCountryDropDownList().then(result => {
        this.CountryList = result.data.data.pageData

        this.$refs.topProgress.done()
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
