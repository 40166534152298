<template>
    <div>
        <b-button variant="primary" @click="goToAddTransferRequest">إضافة طلب نقل كفاله</b-button>
        <br><br><br>
        <b-table :items="transferRequests" :fields="fields" striped hover responsive>
            <template #cell(actions)="data">
                <b-button size="sm" variant="danger" @click="deleteRequest(data.item.id)">حذف</b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BLink,
    BPagination,
    BModal,
    BForm,
    BFormGroup,
    BFormSelect
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import api from '../../../services/api'

const { t } = useI18nUtils()
import TransferRequestsService from '@/services/TransferRequestsService'

export default {
    components: {
        BCard,
        BRow,
        BButton,
        BCol,
        BFormInput,
        BTable,
        BDropdown,
        BDropdownItem,
        BLink,
        BPagination,
        vSelect,
        BModal,
        BForm,
        BFormGroup,
        BFormSelect,
    },
    data() {
        return {
            transferRequests: [],
            fields: [
                { key: 'id', label: 'الرقم' },
                { key: 'employeeName', label: 'الموظف' },
                { key: 'customerName', label: 'العميل' },
                { key: 'trialStartDate', label: 'تاريخ بدء التجربة' },
                { key: 'trialEndDate', label: 'تاريخ انتهاء التجربة' },
                { key: 'deliveryDate', label: 'تاريخ التسليم' },
                { key: 'statusNameAr', label: 'الحالة' },
                { key: 'actions', label: 'إجراءات', sortable: false },
            ],
        }
    },
    methods: {
        async fetchTransferRequests() {
            try {
                const response = await TransferRequestsService.getAll()
                this.transferRequests = response.data
            } catch (error) {
                console.error('خطأ في جلب طلبات النقل:', error)
            }
        },
        goToAddTransferRequest() {
            this.$router.push({ name: 'add-transfer-request' })
        },
        viewRequest(id) {
            this.$router.push({ name: 'transfer-request-details', params: { id } })
        },
        editRequest(id) {
            this.$router.push({ name: 'edit-transfer-request', params: { id } })
        },
        async deleteRequest(id) {
            if (confirm('هل أنت متأكد أنك تريد حذف هذا الطلب؟')) {
                try {
                    await TransferRequestsService.delete(id)
                    this.transferRequests = this.transferRequests.filter(request => request.id !== id)
                    alert('تم حذف الطلب بنجاح')
                } catch (error) {
                    console.error('خطأ في حذف طلب النقل:', error)
                }
            }
        },
    },
    mounted() {
        this.fetchTransferRequests()
    },
}
</script>

<style scoped>
.table {
    width: 100%;
}
</style>
