<template>
  <section>
    <vue-topprogress ref="topProgress" />
    <div>
      <b-card>
        <b-card-header class="pb-50">
          <h5>
            {{ t('Filters') }}

          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>

            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ t('Country') }}
              </label>
              <v-select
                v-model="dataSearch.countryId"
                :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                :options="CountryList"
                :reduce="(val) => val.id"
                :clearable="true"
                input-id="id"
                :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <label>{{ t('Name') }}
              </label>
              <div class="d-flex align-items-center justify-content-end">

                <b-form-input
                  v-model="dataSearch.name"
                />
                &nbsp;&nbsp;&nbsp;
                <b-button
                  variant="primary"
                  @click="LoadData"
                >
                  <span class="text-nowrap">{{ t('Search') }}</span>
                </b-button>
              </div>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="handlePerPageChange"
              />
              <label>{{ t('entries') }}</label>

                &nbsp;&nbsp;&nbsp;
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'app-region-add', params: { id: 0 } }"
                variant="primary"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-50"
                />
                <span class="align-middle">
                  {{ t('Add New') }}
                </span>
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"
          :items="DataList"
          :busy="isBusy"
          :fields="fields"
          :filter="filter"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>{{ t("Loading...") }}</strong>
            </div>
          </template>
          <template #cell(nameAr)="data">
            <b-link
              class="font-weight-bold"
              :to="{ name: 'app-region-add', params: { id: data.item.id } }"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(nameEn)="data">
            <b-link
              class="font-weight-bold"
              :to="{ name: 'app-region-add', params: { id: data.item.id } }"
            >
              {{ data.value }}
            </b-link>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              :to="{ name: 'app-region-add', params: { id: data.item.id } }"
            >
              {{ t('Edit') }}
            </b-button>

          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            />
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handlePageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BLink,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { vueTopprogress } from 'vue-top-progress'
import vSelect from 'vue-select'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    vSelect,
    vueTopprogress,
    BSpinner,
  },
  data() {
    return {
      t,
      filter: null,
      validityType: 0,
      DataList: [],
      CountryList: [],
      isBusy: false,
      dataSearch: {
        name: '',
        countryId: 0,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      id: 0,
      companyId: api.getCompany(),
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Country'),
          key: api.getLanguage() === 1 ? 'countryNameAr' : 'countryNameEn',
          sortable: true,
        },
        {
          label: t('Name'),
          key: api.getLanguage() === 1 ? 'nameAr' : 'nameEn',
          sortable: true,
        },

        { key: 'actions', label: t('Actions') },
      ]
    },
  },
  mounted() {
    this.LoadData()
    this.LoadCountryList()
  },
  methods: {
    LoadData() {
      this.$refs.topProgress.start()
      this.isBusy = !this.isBusy
      api.GetAllRegion(this.currentPage - 1, this.perPage, '', '', this.dataSearch.countryId === null ? 0 : this.dataSearch.countryId, this.dataSearch.name).then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.isBusy = !this.isBusy
        this.$refs.topProgress.done()
      })
    },
    LoadCountryList() {
      this.$refs.topProgress.start()
      api.GetCountryDropDownList().then(result => {
        this.CountryList = result.data.data.pageData

        this.$refs.topProgress.done()
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.LoadData()
    },
    handlePerPageChange(value) {
      if (value > this.totalRows) {
        this.currentPage = 1
      }
      this.perPage = value
      this.LoadData()
    },
    getLanguage() {
      return api.getLanguage()
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
