<template>
  <section class="add-wrapper">

    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-card>
          <b-card-body>
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                />

                <!-- Search -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      variant="primary"
                      :disabled="isLoading"
                      @click.prevent="validationForm"
                    >
                      <b-spinner
                        v-if="isLoading"
                        small
                      />

                      {{ t("Save Changes") }}
                    </b-button>
                        &nbsp;&nbsp;&nbsp;

                    <b-button
                      variant="outline-primary"
                      :to="{ name: 'app-company-list'}"
                    >
                      {{ t("Back To List") }}
                    </b-button>

                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >

                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="imagePath"
                    size="104px"
                    style="object-fit: fill;"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <label class="btn">
                        <input
                          ref="file"
                          type="file"
                          @change="selectFile"
                        >
                      </label>
                    </div>

                  </div>
                </div>

              </b-col>

            </b-row>

            <!-- personal details tab -->

            <b-row>
              <b-col
                cols="12"
                class="mb-2"
                style="
    background-color: #7367f0;
    height: 60px;
"
              >
                <br>
                <h5
                  class="mb-0"
                  style="color:white"
                >
                  {{ t('Company Info') }}
                </h5>
              </b-col>
              <!-- CompanyName -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Company Name')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Company Name')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.companyName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- CompanyNameEn -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Company Name En')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Company Name En')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.companyNameEn"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- GeneralDirector -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('General Director')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('General Director')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.generalDirector"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- GeneralDirectorEn -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('General Director En')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('General Director En')"
                    rules="required"
                  >
                    <b-form-input
                      id="generalDirectorEn"
                      v-model="dataModel.generalDirectorEn"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- address  -->

            <b-row>
              <b-col
                cols="12"
                class="mb-2"
                style="
    background-color: #7367f0;
    height: 60px;
"
              >
                <br>
                <h5
                  class="mb-0"
                  style="color:white"
                >
                  {{ t('Address') }}
                </h5>
              </b-col>
              <!-- Field: CountryId -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Country')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Country')"
                    rules="required"
                  >

                    <v-select
                      v-model="dataModel.countryId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="CountryList"
                      :reduce="val => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                      @input="val => LoadRegionList(val)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: RegionId -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Region')"
                >

                  <validation-provider
                    #default="{ errors }"
                    :name="t('Region')"
                    rules="required"
                  >

                    <v-select
                      v-model="dataModel.regionId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="RegionList"
                      :reduce="val => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                      @input="val => GetCityDropDownList(val)"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>

                    </v-select></validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: CityId -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('City')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('City')"
                    rules="required"
                  >
                    <v-select
                      v-model="dataModel.cityId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="CityList"
                      :reduce="val => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: DistrictId -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('District')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('District')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.district"
                      maxlength="150"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Street -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Street')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Street')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.street"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- StreetEn -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Street En')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Street En')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.streetEn"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- PostalBox -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Postal Box')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Postal Box')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.postalBox"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- PostalCode -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Postal Code')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Postal Code')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.postalCode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- address -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Address')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Address')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.address"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Address En -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Address En')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Address En')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.addressEn"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="mb-2"
                style="
    background-color: #7367f0;
    height: 60px;
"
              >
                <br>
                <h5
                  class="mb-0"
                  style="color:white"
                >
                  {{ t('Other Data') }}
                </h5>
              </b-col>
              <!-- LicenseNumber -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('License Number')"
                >
                  <b-form-input
                    v-model="dataModel.licenseNumber"
                  />
                </b-form-group>
              </b-col>
              <!-- CompanyWebsite -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Website')"
                >

                  <b-form-input
                    v-model="dataModel.companyWebsite"
                  />
                </b-form-group>
              </b-col>
              <!-- CommercialRegistry -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Commercial Registry')"
                >
                  <b-form-input
                    v-model="dataModel.commercialRegistry"
                  />
                </b-form-group>
              </b-col>
              <!-- TaxNumber -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Tax Number')"
                >

                  <b-form-input
                    v-model="dataModel.taxNumber"
                  />
                </b-form-group>
              </b-col>
              <!-- FaxNumbers -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Fax Numbers')"
                >
                  <b-form-input
                    v-model="dataModel.faxNumbers"
                  />
                </b-form-group>
              </b-col>
              <!-- Field: Currency -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Currency')"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="t('Currency')"
                  >
                    <v-select
                      v-model="dataModel.currencyId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="CurrencyList"
                      :reduce="val => val.id"
                      :clearable="false"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </v-select></validation-provider>
                </b-form-group>
              </b-col>
              <!-- PhoneNumbers -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Phone Number')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Phone Number')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="dataModel.phoneNumbers"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
                style="
    background-color: #7367f0;
    height: 60px;
"
              >
                <br>
                <h5
                  class="mb-0"
                  style="color:white"
                >
                  {{ t('Portal Data') }}
                </h5>
              </b-col>

              <!-- Admin Url -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Admin Url')"
                >
                  <b-form-input
                    v-model="dataModel.adminUrl"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- Portal Url -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Portal Url')"
                >

                  <b-form-input
                    v-model="dataModel.portalUrl"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Facebook Url')"
                >
                  <b-form-input
                    v-model="dataModel.facebookUrl"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Google Plus Url')"
                >

                  <b-form-input
                    v-model="dataModel.googlePlusUrl"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Linked in Url')"
                >

                  <b-form-input
                    v-model="dataModel.linkedinUrl"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Instagram Url')"
                >

                  <b-form-input
                    v-model="dataModel.instagramUrl"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Twitter Url')"
                >

                  <b-form-input
                    v-model="dataModel.twitterUrl"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Youtube Url')"
                >

                  <b-form-input
                    v-model="dataModel.youtubeUrl"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Whatsapp Number')"
                >

                  <b-form-input
                    v-model="dataModel.whatsappUrl"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="t('Footer Des1')"
                >

                  <b-form-textarea
                    v-model="dataModel.footerDes1"
                    rows="3"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="t('Footer Des2')"
                >

                  <b-form-textarea
                    v-model="dataModel.footerDes2"
                    rows="3"
                  />

                </b-form-group>
              </b-col>

              <!-- ColorPrimary -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Color Primary')"
                >
                  <b-form-input
                    v-model="dataModel.colorPrimary"
                    type="color"
                  />
                </b-form-group>
              </b-col>
              <!-- ColorSecondry -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Color Secondry')"
                >
                  <b-form-input
                    v-model="dataModel.colorSecondry"
                    type="color"
                  />
                </b-form-group>
              </b-col>
              <!-- ColorHover -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Color Hover')"
                >
                  <b-form-input
                    v-model="dataModel.colorHover"
                    type="color"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
                style="
    background-color: #7367f0;
    height: 60px;
"
              >
                <br>
                <h5
                  class="mb-0"
                  style="color:white"
                >
                  {{ t('Package') }}
                </h5>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Package')"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="t('Package')"
                  >
                    <v-select
                      v-model="dataModel.packageId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="PackageOptions"
                      :reduce="(val) => val.id"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: StartDate -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('Start Date')"
                  label-for="StartDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('Start Date')"
                    rules="required"
                  >

                    <flat-pickr
                      v-model="dataModel.startDate"
                      :config="{ dateFormat: 'Y-m-d', enableTime: false , defaultDate: new Date()}"
                      class="form-control"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: EndDate -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="t('End Date')"
                  label-for="endDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="t('End Date')"
                    rules="required"
                  >

                    <flat-pickr
                      v-model="dataModel.endDate"
                      :config="{ dateFormat: 'Y-m-d', enableTime: false , defaultDate: new Date()}"
                      class="form-control"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </b-card-body>
        </b-card>

      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BFormTextarea, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BSpinner,
  BAvatar,
  BCardBody,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import api from '../../../services/api'

const { t } = useI18nUtils()
const { isRTL } = $themeConfig.layout

export default {
  components: {
    BCard,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BAvatar,
    BCardBody,
    vSelect,
    flatPickr,
    BSpinner,

  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      isRTL,
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      CountryList: [],
      RegionList: [],
      CityList: [],
      DistrictList: [],
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: '',
      fileInfos: [],
      CurrencyList: [],
      PackageOptions: [
        { id: 0, name: 'الكل', nameEn: 'All' },
        { id: 1, name: 'عقود الوساطة', nameEn: 'Mediation Contract' },
        { id: 2, name: 'عقود الافراد', nameEn: 'Individual Contract' },
      ],
      filePath: '',
      imagePath: '',
      acceptedId: 0,
      isLoading: false,
      passwordCon: '',
    }
  },
  mounted() {
    this.getCompayData()
    this.getCountryList()
    this.getCurrencyList()
  },
  methods: {
    getCountryList() {
      api.GetCountryDropDownList().then(result => {
        this.CountryList = result.data.data.pageData
      })
    },
    getCurrencyList() {
      api.getAcceptedOptions(27).then(result => {
        this.CurrencyList = result.data.data.pageData
        this.dataModel.currencyId = result.data.data.pageData[0].id
      })
    },
    getCompayData() {
      api.getCompayData(api.getCompany()).then(result => {
        this.LoadRegionList(result.data.data.countryId)
        this.dataModel.countryId = result.data.data.countryId
        this.GetCityDropDownList(result.data.data.regionId)
        this.dataModel = result.data.data
        // eslint-disable-next-line global-require
        this.filePath = result.data.data.companyLogo
        this.imagePath = api.CompanyLogo(result.data.data.companyLogo)
      })
    },

    getLanguage() {
      return api.getLanguage()
    },
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.dataModel.companyLogo = this.filePath
          api.CreateUpdateCompay(api.getCompany(), this.dataModel)
            .then(result => {
              this.isLoading = false
              this.$swal({
                title: this.$t('Good job!'),
                text: this.$t('Your details have been saved successfully.'),
                icon: result.data.httpCode === 200 ? 'success' : 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              this.isLoading = false
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    selectFile() {
      this.selectedFiles = this.$refs.file.files
      this.upload()
    },
    upload() {
      this.progress = 0
      this.currentFile = this.selectedFiles.item(0)
      api.UploadCompnyLogo(this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      }, api.getCompany())
        .then(response => {
          this.message = response.data.message
          this.filePath = response.data.dbPath
          this.imagePath = api.CompanyLogo(response.data.dbPath)
          this.$swal({
            title: this.$t('Good job!'),
            text: this.$t('Your Image have been Upload successfully.'),
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$swal({
            title: error.response.data.title,
            html: Object.values(error.response.data.errors)
              .map(item => `<div>${item}</div>`)
              .join(''),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.selectedFiles = undefined
        })

      this.selectedFiles = undefined
    },
    GetCityDropDownList(val) {
      this.CityList = []
      this.dataModel.cityId = ''
      console.log(this.dataModel.countryId, val)
      api.GetCityDropDownList(this.dataModel.countryId, val).then(result => {
        this.CityList = result.data.data.pageData
      })
    },
    LoadRegionList(val) {
      this.RegionList = []
      this.CityList = []
      this.dataModel.regionId = ''
      this.dataModel.cityId = ''
      api.GetRegionDropDownList(val).then(result => {
        this.RegionList = result.data.data.pageData
      })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
.add-wrapper {
  .add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>
