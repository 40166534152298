<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- pageTitle -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Title')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Title')"
                      >
                        <b-form-input
                          v-model="dataModel.title"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- pageLink -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Answer')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Answer')"
                      >
                        <b-form-input
                          v-model="dataModel.answer"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- pageDisplayOrder -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Display Order')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Display Order')"
                      >
                        <b-form-input
                          v-model="dataModel.displayOrder"
                          min="0"
                          max="100"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Publish -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Publish')"
                    >

                      <b-form-checkbox
                        v-model="dataModel.isPublish"
                      />

                    </b-form-group>
                  </b-col>

                </b-row>
                <br>
                <br>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                @click.prevent="validationForm"
              >
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="outline-primary"
                :to="{ name: 'app-portal-faqslist'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
  required, email, confirmed,
  password,
} from '@validations'
import { vueTopprogress } from 'vue-top-progress'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    vueTopprogress,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      confirmed,
      password,
      selected: [],
      dataModel: {},
      IsEditMode: false,
      GroupList: [],
      AppItemList: [],
      AppItemListSelected: [],
      id: 0,
    }
  },
  mounted() {
    this.IsEditMode = false
    if (router.currentRoute.params.id !== '0' && router.currentRoute.params.id !== 0) {
      this.IsEditMode = true
      this.$refs.topProgress.start()
      api.GetPortalFaqsById(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          // eslint-disable-next-line
              this.dataModel.companyId= api.getCompany(),
          api.AddEditPortalFaqs(router.currentRoute.params.id, this.dataModel).then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs.topProgress.done()

            this.$router.push({ name: 'app-portal-faqslist' })
          })
            .catch(error => {
              this.$refs.topProgress.done()
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
  },

}
</script>

          <style lang="scss">
          @import "@core/scss/vue/libs/vue-flatpicker.scss";
          @import "~@core/scss/base/pages/app-invoice.scss";
          @import "@core/scss/vue/libs/vue-select.scss";
          @import "@core/scss/vue/libs/quill.scss";
          .add-new {
              padding: $options-padding-y $options-padding-x;
                color: $success;

              &:hover {
                background-color: rgba($success, 0.12);
              }
          }
          </style>
