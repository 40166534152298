<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="12"
            xl="12"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ t("Save Changes") }}
              </b-button>
              &nbsp;&nbsp;
              <b-button
                variant="outline-primary"
                :to="{ name: 'app-account-list'}"
              >
                {{ t("Back To List") }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="12"
            md="12"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Account Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-row>
                      <!-- Field: parentId -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="t('Main Account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="t('Main Account')"
                          >
                            <v-select
                              v-model="dataModel.parentId"
                              :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                              :options="AccountsList"
                              :reduce="(val) => val.accountId"
                              :clearable="false"
                              input-id="accountId"
                              :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                              disabled
                              @input="val => GetAccountAutoNum(val)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Field: accountNo -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="t('Account No')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="t('Account No')"
                          >
                            <b-form-input
                              v-model="dataModel.accountNo"
                              :state="errors.length > 0 ? false : null"
                              readonly
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- accountName -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="t('Account Name')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="t('Account Name')"
                          >
                            <b-form-input
                              v-model="dataModel.accountName"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- accountNameEn -->
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="t('Account Name En')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="t('Account Name En')"
                          >
                            <b-form-input
                              v-model="dataModel.accountNameEn"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="t('Final Account Type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="t('Final Account Type')"
                          >
                            <v-select
                              v-model="dataModel.finalAccountType"
                              :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                              :options="FinalAccountTypeOptions"
                              :reduce="(val) => val.id"
                              input-id="id"
                              :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                              disabled
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="t('Account Type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="t('Account Type')"
                          >
                            <v-select
                              v-model="dataModel.accountType"
                              :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                              :options="AccountTypeOptions"
                              :reduce="(val) => val.id"
                              input-id="id"
                              :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                              disabled
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notes -->
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          :label="t('Notes')"
                          label-for="notes"
                        >
                          <b-form-textarea
                            v-model="dataModel.notes"
                            rows="3"
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <div class="m-2">
                      <vue-tree-list
                        :model="treeData"
                        :default-expanded="false"
                        :add-tree-node-disabled="false"
                        :drag-disabled="false"
                        dir="ltr"
                        @click="onClick"
                      >
                        <template v-slot:leafNameDisplay="slotProps">
                          <span>
                            <span class="muted">#{{ slotProps.model.accountId }}</span>  {{ slotProps.model.accountName }}
                          </span>
                        </template>
                        <span
                          slot="addTreeNodeIcon"
                          hidden
                          class="icon"
                        >📂</span>
                        <span
                          slot="addLeafNodeIcon"
                          hidden
                          class="icon"
                        >＋</span>
                        <span
                          slot="editNodeIcon"
                          hidden
                          class="icon"
                        >📃</span>
                        <span
                          slot="delNodeIcon"
                          hidden
                          class="icon"
                        >✂️</span>
                        <span
                          slot="leafNodeIcon"
                          class="icon"
                        >📂</span>
                        <span
                          slot="treeNodeIcon"
                          class="icon"
                        >📂</span>

                      </vue-tree-list>
                    </div>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BFormTextarea, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { vueTopprogress } from 'vue-top-progress'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { VueTreeList, Tree } from 'vue-tree-list'
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormTextarea,
    VueTreeList,
    vueTopprogress,
    BSpinner,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      dataModel: {},
      BranchsList: [],
      AccountsList: [],
      JobList: [],
      isLoading: false,
      treeData: new Tree([]),
      id: 0,
      acceptedId: 0,
      FinalAccountTypeOptions: [
        { id: 1, name: 'الميزانية العمومية', nameEn: 'Balance Statement' },
        { id: 2, name: 'قائمة الدخل', nameEn: 'Income Statement' },
      ],
      AccountTypeOptions: [
        { id: 1, name: 'مدين', nameEn: 'Debtor' },
        { id: 2, name: 'دائن', nameEn: 'Creditor' },
      ],

    }
  },
  mounted() {
    this.getCompaybranchs()
    this.getAccountsList()
    if (this.decryptId(router.currentRoute.params.id) !== 0) {
      this.$refs.topProgress.start()
      api.getAccount(this.decryptId(router.currentRoute.params.id)).then(result => {
        this.dataModel = result.data.data
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          // eslint-disable-next-line
        const dataModel = {
            accountId: this.dataModel.accountId,
            parentId: this.dataModel.parentId,
            accountNo: this.dataModel.accountNo,
            accountName: this.dataModel.accountName,
            accountNameEn: this.dataModel.accountNameEn,
            finalAccountType: this.dataModel.finalAccountType,
            accountType: this.dataModel.accountType,
            recSource: 0,
            recId: 0,
            isParent: false,
            isBasic: false,
            isCostCenter: false,
            isCostCenterRequired: false,
            notes: this.dataModel.notes,
            branchId: this.dataModel.branchId,
            companyId: api.getCompany(),

          }
          api.CreateUpdateAccount(this.decryptId(router.currentRoute.params.id), dataModel).then(result => {
            this.isLoading = false

            this.getAccountsList()
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: 'app-account-list' })
            this.$refs.validationRules.reset()
          })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.$swal({
                title: 'خطا',
                text: 'تحقق من اسم المستخدم وكلمة السر',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$refs.topProgress.done()
            })
        }
      })
    },
    getAccountsList() {
      api.getAccountsList(0, 0, '', '', '', api.getCompany(), 0).then(result => {
        this.AccountsList = result.data.data.pageData
      })
      api.GetTreeAccounts(0, 0, '', '0', '', api.getCompany(), 0).then(result => {
        this.treeData = new Tree(result.data.data.pageData)
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    GetAccountAutoNum(val) {
      api.GetAccountAutoNum(val).then(result => {
        this.dataModel = result.data.data
      })
    },
    SetAcceptedId(id) {
      this.acceptedId = id
    },
    onClick(params) {
      this.GetAccountAutoNum(params.accountId)
    },
    decryptId(str) {
      const decodedStr = decodeURIComponent(str)
      return AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8) === '' ? 0
        : AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8)
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
