<template>
  <section>
    <div>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-form @submit.prevent>
            <validation-observer ref="validationRules">
              <b-row>
                <!-- fromDate -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('From Date')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="dataModel.fromDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control invoice-edit-input"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>

                </b-col>
                <!-- ToDate -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('To Date')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="dataModel.toDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control invoice-edit-input"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>

                </b-col>
                <!-- Account -->
                <b-col
                  cols="12"
                  md="4"
                >

                  <b-form-group :label="t('Account')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <v-select
                        v-model="dataModel.accountId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="AccountsList"
                        :reduce="(val) => val.uid"
                        :clearable="false"
                        input-id="uid"
                        :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-button
                    variant="primary"
                    @click="getDataList"
                  >
                    <span class="text-nowrap">{{ t('Search') }}</span>
                  </b-button>
                  &nbsp;&nbsp;&nbsp;
                  <b-button
                    variant="success"
                    @click="Print"
                  >
                    <span class="text-nowrap">{{ t('Print') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
        </div>
        <b-row>

          <b-col
            cols="12"
            md="12"
          >
            <b-table
              class="position-relative"

              :items="DataList"
              :fields="fields"
              responsive=""
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <template #cell(jNo)="data">
                <b-link
                  class="font-weight-bold"
                  @click="PrintJournal(data.item.uid)"
                >
                  {{ data.value }}
                </b-link>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </section>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
} from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BLink,
  BFormGroup,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BButton,
    BCol,
    BTable,
    BLink,
    flatPickr,
    vSelect,
    BFormGroup,
  },
  data() {
    const { getValidationState } = formValidation(this.dataModel)
    return {
      t,
      getValidationState,
      required,
      filter: null,
      validityType: 0,
      dataSearch: {},
      DataList: [],
      AccountsList: [],
      dataModel: {
        fromDate: '',
        toDate: '',
        accountId: '',
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Journal No'),
          key: 'jNo',
          sortable: true,
        },
        {
          label: t('Journal Date'),
          key: 'jDate',
          sortable: true,
        },
        {
          label: t('Description'),
          key: 'description',
          sortable: true,
        },
        {
          label: t('Deptor'),
          key: 'deptor',
          sortable: true,
        },

        {
          label: t('Creditor'),
          key: 'creditor',
          sortable: true,
        },

        {
          label: t('Balance'),
          key: 'balance',
          sortable: true,
        },

      ]
    },
  },
  mounted() {
    this.getAccountsList()
  },
  methods: {
    getDataList() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          api.GetGeneralLedger(this.dataModel.fromDate, this.dataModel.toDate, this.dataModel.accountId).then(result => {
            this.DataList = result.data.data.pageData
          })
        }
      })
    },

    getLanguage() {
      return api.getLanguage()
    },

    PrintJournal(id) {
      if (id === '00000000-0000-0000-0000-000000000000') {
        return
      }

      // eslint-disable-next-line consistent-return
      return api.PrintJournal(id)
    },
    Print() {
      return api.GeneralLedger(this.dataModel.fromDate, this.dataModel.toDate, this.dataModel.accountId)
    },
    getAccountsList() {
      api.GetAllAccountChild(0, 0, '', '', '', api.getCompany(), 0).then(result => {
        this.AccountsList = result.data.data.pageData
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
