<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- nameAr -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Name')"
                      >
                        <b-form-input
                          v-model="dataModel.fullName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- nameEn -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Email')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Email')"
                      >
                        <b-form-input
                          v-model="dataModel.email"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Phone Number')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Phone Number')"
                      >
                        <b-form-input
                          v-model="dataModel.phoneNumber"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Group')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Group')"
                      >
                        <v-select
                          v-model="dataModel.groupId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="GroupList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'nameAr' : 'nameEn'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Password')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Password')"
                        vid="password"
                      >
                        <b-form-input
                          id="password"
                          v-model="dataModel.password"
                          type="password"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Confirm Password')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required|confirmed:password"
                        :name="t('Confirm Password')"
                      >
                        <b-form-input
                          v-model="dataModel.confirmPassword"
                          type="password"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <br>

                </b-row>
                <br>
                <br>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                block
                :disabled="isLoading"
                @click.prevent="validationForm"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-user-list'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import {
  required, email, confirmed,
  password,
} from '@validations'
import { vueTopprogress } from 'vue-top-progress'
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vueTopprogress,
    vSelect,
    BSpinner,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      getValidationState,
      required,
      email,
      confirmed,
      password,
      dataModel: {
        fullName: '',
        email: '',
        phoneNumber: '',
        groupId: 0,
        password: '',
        confirmPassword: '',
      },
      GroupList: [],
      id: 0,
      isLoading: false,
    }
  },
  mounted() {
    console.log(this.decryptId(router.currentRoute.params.id))
    if (this.decryptId(router.currentRoute.params.id) !== 0) {
      this.$refs.topProgress.start()
      api.GetUserByIdAsync(this.decryptId(router.currentRoute.params.id)).then(result => {
        this.dataModel = result.data.data
        this.$refs.topProgress.done()
      })
    }
    this.GroupDropDown()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          this.isLoading = true
          // eslint-disable-next-line
        const dataModel = {
            id: this.decryptId(router.currentRoute.params.id),
            fullName: this.dataModel.fullName,
            email: this.dataModel.email,
            phoneNumber: this.dataModel.phoneNumber,
            groupId: this.dataModel.groupId,
            password: this.dataModel.password,
            confirmPassword: this.dataModel.confirmPassword,
            companyId: api.getCompany(),

          }
          api.AddEditUser(this.decryptId(router.currentRoute.params.id), dataModel).then(result => {
            this.isLoading = false
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$router.push({ name: 'app-user-list' })

            this.$refs.topProgress.done()
          })
            .catch(error => {
              this.$refs.topProgress.done()
              this.isLoading = false
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    GroupDropDown() {
      api.GroupDropDown().then(result => {
        this.GroupList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    decryptId(str) {
      const decodedStr = decodeURIComponent(str)
      return AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8) === '' ? 0
        : AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8)
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.add-new {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
