<!-- eslint-disable vue/valid-v-for -->
<template>
  <section>

    <vue-topprogress ref="topProgress" />

    <b-row>
      <b-col
        v-for="(n, index) in DataList"
        :key="index"
        class="col--md-3"
      >
        <b-card class="text-center">
          <b-avatar
            size="200px"
            :src="getLogo(n.filePath == null ? '' : n.filePath)"
            rounded
          />
        </b-card>
      </b-col>
    </b-row>

    <div class="mx-2 mb-2">
      <b-row>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handlePageChange"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

      </b-row>
    </div>

  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BPagination,
  BAvatar,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { vueTopprogress } from 'vue-top-progress'

import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BAvatar,
    vueTopprogress,
  },
  setup() {

  },
  data() {
    return {
      t,
      filter: null,
      // Sidebar
      validityType: 0,
      dataSearch: {
        name: '',
        statusId: 0,
      },
      DataList: [],
      statusList: [
        { value: 0, label: 'الكل' },
        { value: 1, label: 'نشط' },
        { value: 2, label: 'غير نشط' },
      ],
      totalRows: 1,
      emptytext: '',
      currentPage: 1,
      perPage: 5,
      avatarText,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],

    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Name'),
          key: 'fileName',
          sortable: true,
        },
        {
          label: t('Path'),
          key: 'filePath',
          sortable: true,
        },
        {
          label: t('Size'),
          key: 'fileSize',
          sortable: true,
        },
        {
          label: t('Extension'),
          key: 'fileExtension',
          sortable: true,
        },
      ]
    },
  },
  mounted() {
    this.LoadData()
  },
  methods: {
    LoadData() {
      api.GetAllCompanyAttachment(this.currentPage - 1, this.perPage, '', '').then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.LoadData()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.LoadData()
    },
    getLanguage() {
      return api.getLanguage()
    },
    getLogo(dbPath) {
      return api.CompanyLogo(dbPath)
    },
  },
}
</script>
    <style lang="scss" scoped>
    .per-page-selector {
      width: 90px;
    }
    </style>

    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    </style>
