<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- pageTitle -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Page Title')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Page Title')"
                      >
                        <b-form-input
                          v-model="dataModel.pageTitle"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- pageKey -->
                  <b-col
                    cols="12"
                    md="6"
                  />
                  <!-- pageLink -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Page Link')"
                    >
                      <b-form-input
                        v-model="dataModel.pageLink"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- pageDisplayOrder -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="t('Page Display Order')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Page Display Order')"
                      >
                        <b-form-input
                          v-model="dataModel.pageDisplayOrder"
                          min="0"
                          max="100"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- pageContent -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Page Content')"
                    >

                      <quill-editor
                        v-model="dataModel.pageContent"
                        :options="snowOption"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- pageHeaderImage -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Page Header Image')"
                    >

                      <b-input-group>
                        <b-form-input
                          v-model="dataModel.pageHeaderImage"
                        />
                        <b-input-group-append>
                          <b-button
                            v-b-modal.modal-new
                            variant="outline-primary"
                            @click="LoadData"
                          >
                            {{ t("Browser") }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>

                    </b-form-group>
                  </b-col>
                  <!-- Show Top Header Menu -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Show Top Header Menu')"
                    >

                      <b-form-checkbox
                        v-model="dataModel.showTopHeaderMenu"
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Show Footer Menu -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Show Footer Menu')"
                    >

                      <b-form-checkbox
                        v-model="dataModel.showTopFooterMenu"
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Publish -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="t('Publish')"
                    >

                      <b-form-checkbox
                        v-model="dataModel.isPublish"
                      />

                    </b-form-group>
                  </b-col>

                </b-row>
                <br>
                <br>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mb-75"
                @click.prevent="validationForm"
              >
                {{ t("Save Changes") }}
              </b-button>
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'app-portal-listpage'}"
              >
                {{ t("Back To List") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      ref="my-modal"
      :hide-footer="true"
      centered
      size="xl"
      :title="t('Attachments')"
    >
      <b-row>
        <b-col
          v-for="(n, index) in DataList"
          :key="index"
          class="col-md-3 btn"
          @click="SetImage(getLogo(n.filePath == null ? '' : n.filePath))"
        >

          <b-avatar
            size="200px"
            :src="getLogo(n.filePath == null ? '' : n.filePath)"
            rounded
          />

        </b-col>
      </b-row>
      <div class="mx-2 mb-2 text-center">
        <b-row>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-row>
      </div>

    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox,
  BPagination,
  BInputGroup,
  BAvatar,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
  required, email, confirmed,
  password,
} from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { vueTopprogress } from 'vue-top-progress'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    quillEditor,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    vueTopprogress,
    BPagination,
    BInputGroup,
    BAvatar,
    BInputGroupAppend,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      confirmed,
      password,
      selected: [],
      dataModel: {
        pageTitle: '',
        pageKey: '',
        pageLink: '',
        pageContent: '',
        pageHeaderImage: '',
        showTopHeaderMenu: false,
        showTopFooterMenu: false,
        pageDisplayOrder: 0,
        isPublish: true,
        companyId: api.getCompany(),
      },
      IsEditMode: false,
      GroupList: [],
      AppItemList: [],
      AppItemListSelected: [],
      id: 0,
      DataList: [],
      currentPage: 1,
      perPage: 12,
    }
  },
  mounted() {
    this.IsEditMode = false
    if (router.currentRoute.params.id !== '0' && router.currentRoute.params.id !== 0) {
      this.IsEditMode = true
      this.$refs.topProgress.start()
      api.GetPortalPageById(router.currentRoute.params.id).then(result => {
        this.dataModel = result.data.data
        this.$refs.topProgress.done()
      })
    }
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          // eslint-disable-next-line
            const dataModel = {
            pageTitle: this.dataModel.pageTitle,
            pageKey: this.dataModel.pageKey,
            pageLink: this.dataModel.pageLink,
            pageContent: this.dataModel.pageContent,
            pageHeaderImage: this.dataModel.pageHeaderImage,
            showTopHeaderMenu: this.dataModel.showTopHeaderMenu,
            showTopFooterMenu: this.dataModel.showTopFooterMenu,
            pageDisplayOrder: this.dataModel.pageDisplayOrder,
            isPublish: this.dataModel.isPublish,
            companyId: api.getCompany(),
          }
          api.AddEditPortalPage(router.currentRoute.params.id, dataModel).then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs.topProgress.done()

            this.$router.push({ name: 'app-portal-listpage' })
          })
            .catch(error => {
              this.$refs.topProgress.done()
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    GroupDropDown() {
      api.GroupDropDown().then(result => {
        this.GroupList = result.data.data.pageData
      })
    },
    GetAllAppItem() {
      api.GetAllAppItem().then(result => {
        console.log(result.data)
        this.AppItemList = result.data
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    handlePageChange(value) {
      this.currentPage = value
      this.LoadData()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.LoadData()
    },
    SetImage(image) {
      this.dataModel.pageHeaderImage = image
      this.$refs['my-modal'].hide()
    },
    LoadData() {
      api.GetAllCompanyAttachment(this.currentPage - 1, this.perPage, '', '').then(result => {
        this.DataList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
      })
    },
    getLogo(dbPath) {
      return api.CompanyLogo(dbPath)
    },
  },

}
</script>

    <style lang="scss">
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    @import "~@core/scss/base/pages/app-invoice.scss";
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/quill.scss";
    .add-new {
        padding: $options-padding-y $options-padding-x;
          color: $success;

        &:hover {
          background-color: rgba($success, 0.12);
        }
    }
    </style>
