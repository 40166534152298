<template>
  <section>
    <div>

      <!-- Table Container Card -->
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <!-- page length -->

              <label>{{ t('Show') }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" @input="handlePerPageChange" />
                <label>{{ t('entries') }}</label>

            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" :placeholder="t('Search...')" />
                <b-button variant="primary" :disabled="!filter" @click="filter = ''">
                  <span class="text-nowrap">{{ t('Clear') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table class="position-relative" :items="EmployeeList" :fields="fields" :filter="filter" responsive=""
          primary-key="id" :busy="isBusy" show-empty :empty-text="t('No matching records found')">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>{{ t('Loading...') }}</strong>
            </div>
          </template>

          <!-- Employee Name -->
          <template #cell(employeeName)="data">
            <b-link :to="{ name: 'app-employee-edit', params: { id: data.item.id } }" class="font-weight-bold">
              {{ data.value }}
            </b-link>
          </template>

          <template #cell(employeeNameEn)="data">
            <b-link :to="{ name: 'app-employee-edit', params: { id: data.item.id } }" class="font-weight-bold">
              {{ data.value }}
            </b-link>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'app-employee-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDelete(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ t('Delete') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="openModal(data.item.id, data.item.employeeName)">
                <feather-icon icon="DollarSignIcon" />
                <span class="align-middle ml-50">{{ t('Calculate Salary') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" />
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="handlePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>

    <b-modal id="calculate-salary-modal" ref="calculateSalaryModal" :title="t('Calculate Salary')" @hide="resetModal">
      <b-form @submit.prevent="calculateSalary">
        <b-form-group :label="t('Month')" label-for="month">
          <b-form-select id="month" v-model="modalData.month" :options="months" required />
        </b-form-group>
        <b-form-group :label="t('Year')" label-for="year">
          <b-form-select id="year" v-model="modalData.year" :options="years" required />
        </b-form-group>
        <b-button type="submit" variant="primary">{{ t('Calculate') }}</b-button>
      </b-form>

      <!-- Receipt to display return data from proc -->
      <div v-if="salaryData" class="mt-3">
        <b-card>
          <div class="text-center mb-3">
            <h4>{{ t('Salary Receipt') }}</h4>
          </div>
          <b-row>
            <b-col cols="6"><strong>{{ t('Employee Name') }}:</strong></b-col>
            <b-col cols="6">{{ employeeName }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Employee ID') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.employeeId }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Payment Date') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.paymentDate }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Month') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.month }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Year') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.year }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Fixed Salary') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.fixedSalary }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Variable Salary') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.variableSalary }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6"><strong>{{ t('Net Salary') }}:</strong></b-col>
            <b-col cols="6">{{ salaryData.fixedSalary + salaryData.variableSalary }}</b-col>
          </b-row>
        </b-card>
      </div>
    </b-modal>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
  BPagination,
  BModal,
  BForm,
  BFormGroup,
  BFormSelect
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {

    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BLink,
    BPagination,
    vSelect,
    BModal,
    BForm,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      t,
      filter: null,
      validityType: 0,
      dataSearch: {},
      EmployeeList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      isBusy: false,
      modalData: {
        employeeId: null,
        month: null,
        year: null,
      },
      response: null,
      salaryData: null, // To store the return data
      employeeName: '', // To store the employee name

      months: [
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' }
      ],
      years: Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i).map(year => ({ value: year, text: year })),
  

    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Name'),
          key: api.getLanguage() === 1 ? 'employeeName' : 'employeeNameEn',
          sortable: true,
        },
        {
          label: t('Availability'),
          key: 'availability',
          sortable: true,
        },
        {
          label: t('Rent Start'),
          key: 'rentStart',
          sortable: true,
        },
        {
          label: t('Rent End'),
          key: 'rentEnd',
          sortable: true,
        },

        {
          label: t('Fixed Salary'),
          key: 'fixedSalary',
          sortable: true,
        },
        {
          label: t('Customer'),
          key: api.getLanguage() === 1 ? 'customerName' : 'customerNameEn',
          sortable: true,
        },
        {
          label: t('Gender'),
          key: api.getLanguage() === 1 ? 'sexName' : 'sexNameEn',
          sortable: true,
        },
        {
          label: t('Age'),
          key: 'age',
          sortable: true,
        },
        {
          label: t('Nationality'),
          key: api.getLanguage() === 1 ? 'nationalityName' : 'nationalityNameEn',
          sortable: true,
        },
        {
          label: t('Mobile No'),
          key: 'mobileNo',
          sortable: true,
        },
        {
          label: t('Job'),
          key: api.getLanguage() === 1 ? 'jobName' : 'jobNameEn',
          sortable: true,
        },

        {
          label: t('Branch'),
          key: api.getLanguage() === 1 ? 'branchName' : 'branchNameEn',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
    salaryFields() {
      return [
        { key: 'employeeId', label: 'Employee ID' },
        { key: 'paymentDate', label: 'Payment Date' },
        { key: 'fixedSalary', label: 'Fixed Salary' },
        { key: 'variableSalary', label: 'Variable Salary' },
        { key: 'month', label: 'Month' },
        { key: 'year', label: 'Year' },
      ]
    }
  },
  mounted() {
    this.getemployeeList()
  },
  methods: {
    getemployeeList() {
      this.isBusy = !this.isBusy
      api.getemployeeList(this.currentPage - 1, this.perPage, '', '', '', api.getCompany(), 0).then(result => {
        this.EmployeeList = result.data.data.pageData
        this.totalRows = result.data.data.filteredCount
        this.isBusy = !this.isBusy
      })
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getemployeeList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getemployeeList()
    },
    getLanguage() {
      return api.getLanguage()
    },
    handleDelete(id) {
      if (confirm('Are you sure you want to delete this employee?')) {
        this.isBusy = true;
        api.deleteEmployee(id)
          .then(() => {
            this.getemployeeList();
            this.isBusy = false;
            this.$swal({
              title: 'Good job!',
              text: 'Employee has been deleted successfully.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          })
          .catch(error => {
            console.error('There was an error deleting the employee!', error);
            this.isBusy = false;
            this.$swal({
              title: 'Error!',
              text: 'There was an error deleting the employee.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          });
      }
    },
    openModal(employeeId, employeeName) {
      this.modalData.employeeId = employeeId;
      this.employeeName = employeeName;
      this.modalData.month = null;
      this.modalData.year = null;
      this.salaryData = null; // Reset the salary data
      this.$refs.calculateSalaryModal.show();
    },
    resetModal() {
      this.modalData = {
        employeeId: null,
        month: null,
        year: null,
      };
      this.salaryData = null; // Reset the salary data
      this.employeeName = ''; // Reset the employee name

    },
    async calculateSalary() {
      try {
        const { data } = await api.calculateVariableSalary(this.modalData.employeeId, this.modalData.month, this.modalData.year);
        this.salaryData = data.data;
        this.$swal({
          title: 'Success!',
          text: 'Salary calculated successfully.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } catch (error) {
        if (error.response) {
          this.response = error.response.data;
          this.$swal({
            title: 'Error!',
            text: 'Salary already calculated for this employee in the specified month and year.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: 'Error!',
            text: 'An unexpected error occurred.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
      }
    }
  },
  printReceipt() {
      const printContent = this.$refs.receipt.innerHTML;
      const originalContent = document.body.innerHTML;
      document.body.innerHTML = printContent;
      window.print();
      document.body.innerHTML = originalContent;
      window.location.reload(); // Optional: reload the page to reset the UI state
    }
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
