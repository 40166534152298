<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form>
      <b-row class="invoice-add">

        <!-- Left Col: Card -->
        <b-col
          cols="12"
          xl="12"
          md="12"
        >

          <b-card>
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                />

                <!-- Search -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">

                    <b-button
                      variant="outline-primary"
                      :to="{ name: 'app-portal-attachments'}"
                    >
                      {{ t("Back To List") }}
                    </b-button>

                  </div>
                </b-col>
              </b-row>
              <b-row>

                <b-col
                  cols="12"
                  md="6"
                >

                  <!-- User Avatar & Action Buttons -->
                  <div class="d-flex justify-content-start">
                    <b-avatar
                      :src="imagePath"
                      size="104px"
                      rounded
                    />
                    <div class="d-flex flex-column ml-1">
                      <div class="mb-1">
                        <label class="btn">
                          <input
                            ref="file"
                            type="file"
                            multiple
                            @change="selectFile"
                          >
                        </label>
                      </div>
                      <div class="d-flex flex-wrap">
                        <b-button
                          variant="primary"
                          @click="upload"
                        >

                          {{ t('Upload') }}
                        </b-button>

                      </div>
                    </div>
                  </div>

                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-progress
                    :value="progress"
                    max="100"
                    class="progress-bar-success"
                    show-progress
                    variant="success"
                  />

                </b-col>
                <br>
              </b-row>
            </b-card-body>
          </b-card>

        </b-col>

      </b-row>
    </b-form>
  </section>
</template>

<script>

import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BCard, BButton, BRow, BCol, BForm, BAvatar, BProgress,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { required, email } from '@validations'
import { vueTopprogress } from 'vue-top-progress'

import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BForm,
    BProgress,
    vueTopprogress,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,

      logoModel: {
        id: 0,
        fileName: '',
        filePath: '',
        fileSize: 0,
        fileExtension: '',
        attachmentType: 0,
        companyId: api.getCompany(),
      },

      CountryList: [],
      RegionList: [],
      CityList: [],
      id: 0,
      acceptedId: 0,
      isLoading: false,
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      totalFiles: 0,
      countFiles: 0,
      IsEditMode: false,
      message: '',
      fileInfos: [],
      filePath: '',
      imagePath: '',
    }
  },
  mounted() {

  },
  methods: {

    getLanguage() {
      return api.getLanguage()
    },
    selectFile() {
      this.selectedFiles = this.$refs.file.files
      this.totalFiles = this.selectedFiles.length
    },
    upload() {
      this.progress = 0
      this.$refs.topProgress.start()
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.selectedFiles.length; index++) {
        this.currentFile = this.selectedFiles.item(index)
        api.UploadCompnyLogo(this.currentFile, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        }, 0)
          .then(response => {
            this.message = response.data.message
            this.filePath = response.data.dbPath
            this.imagePath = api.CompanyLogo(response.data.dbPath)
            this.logoModel = {
              id: 0,
              fileName: response.data.fileName,
              filePath: response.data.dbPath,
              fileSize: response.data.filesize,
              fileExtension: response.data.fileExtension,
              attachmentType: 2,
              companyId: api.getCompany(),
            }
            // eslint-disable-next-line no-unused-vars
            api.AddEditCompanyAttachment(0, this.logoModel).then(result => {
              this.countFiles = index + 1
              if (this.countFiles === this.totalFiles) {
                this.$swal({
                  title: this.$t('Good job!'),
                  text: this.$t('Your details have been saved successfully.'),
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$refs.topProgress.done()
              }
            })
              .catch(error => {
                this.countFiles = index + 1
                this.$refs.topProgress.done()
                this.isLoading = false
                this.$swal({
                  title: 'error',
                  html: Object.values(error.response.data.errors)
                    .map(item => `<div>${item}</div>`)
                    .join(''),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          })
          .catch(error => {
            this.countFiles = index + 1
            this.$swal({
              title: 'error',
              html: Object.values(error.response.data.errors)
                .map(item => `<div>${item}</div>`)
                .join(''),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.selectedFiles = undefined
          })
      }

      this.selectedFiles = undefined
    },

  },

}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  .add-new {
      padding: $options-padding-y $options-padding-x;
        color: $success;

      &:hover {
        background-color: rgba($success, 0.12);
      }
  }
  </style>
