<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row class="invoice-add">

          <!-- Left Col: Card -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Information")
                      }}</span>
                    </b-card-title>
                    <hr class="invoice-spacing">
                  </b-col>

                  <!-- pageContent -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Services Content')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Services Content')"
                      >

                        <quill-editor
                          v-model="dataModel.servicesDes"
                          :options="snowOption"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Nationalities Content')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Nationalities Content')"
                      >

                        <quill-editor
                          v-model="dataModel.nationalitiesSection"
                          :options="snowOption"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('About Content')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('About Content')"
                      >

                        <quill-editor
                          v-model="dataModel.aboutSection"
                          :options="snowOption"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('About Video Url')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('About Video Url')"
                      >
                        <b-form-input
                          v-model="dataModel.aboutVideoUrl"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Testimonials Section')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Testimonials Section')"
                      >

                        <quill-editor
                          v-model="dataModel.testimonialsSection"
                          :options="snowOption"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="t('Contact Section')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Contact Section')"
                      >

                        <quill-editor
                          v-model="dataModel.contactSection"
                          :options="snowOption"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="t('Employee')"
                    >
                      <b-form-checkbox
                        v-model="dataModel.employeeCountPublish"
                      />
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Employee Count Label')"
                      >
                        {{ t('Label') }}
                        <b-form-input
                          v-model="dataModel.employeeCountLabel"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Employee Count')"
                      >
                        {{ t('Value') }}
                        <b-form-input
                          v-model="dataModel.employeeCount"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="t('Branch')"
                    >
                      <b-form-checkbox
                        v-model="dataModel.branchCountPublish"
                      />
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Branch Count Label')"
                      >
                        {{ t('Label') }}
                        <b-form-input
                          v-model="dataModel.branchCountLabel"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Branch Count')"
                      >
                        {{ t('Value') }}
                        <b-form-input
                          v-model="dataModel.branchCount"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="t('Nationalities')"
                    >
                      <b-form-checkbox
                        v-model="dataModel.nationalitiesCountPublish"
                      />
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Nationalities Count Label')"
                      >
                        {{ t('Label') }}
                        <b-form-input
                          v-model="dataModel.nationalitiesCountLabel"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Nationalities Count')"
                      >
                        {{ t('Value') }}
                        <b-form-input
                          v-model="dataModel.nationalitiesCount"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="t('House Keeper')"
                    >
                      <b-form-checkbox
                        v-model="dataModel.houseKeeperCountPublish"
                      />
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('House Keeper Label')"
                      >
                        {{ t('Label') }}
                        <b-form-input
                          v-model="dataModel.houseKeeperCountLabel"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('House Keeper Count')"
                      >
                        {{ t('Value') }}
                        <b-form-input
                          v-model="dataModel.houseKeeperCount"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <br>
                <br>
              </b-card-body>
            </b-card>

          </b-col>
          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <b-card>
              <!-- Action Buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mb-75"
                @click.prevent="validationForm"
              >
                {{ t("Save Changes") }}
              </b-button>

            </b-card>
          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BCard, BButton, BRow, BCol, BFormGroup, BForm, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
  required, email, confirmed,
  password,
} from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { vueTopprogress } from 'vue-top-progress'

import api from '../../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    quillEditor,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BFormInput,
    vueTopprogress,
  },
  data() {
    const {
      getValidationState,
    } = formValidation(this.dataModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      required,
      email,
      confirmed,
      password,
      selected: [],
      dataModel: {},
      IsEditMode: false,
      GroupList: [],
      AppItemList: [],
      AppItemListSelected: [],
      id: 0,
    }
  },
  mounted() {
    this.$refs.topProgress.start()
    api.GetPortalServiceById(api.getCompany()).then(result => {
      this.dataModel = result.data.data
      this.$refs.topProgress.done()
    })
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.$refs.topProgress.start()
          // eslint-disable-next-line
            const dataModel = {
            servicesDes: this.dataModel.servicesDes,
            nationalitiesSection: this.dataModel.nationalitiesSection,
            employeeCount: this.dataModel.employeeCount,
            branchCount: this.dataModel.branchCount,
            nationalitiesCount: this.dataModel.nationalitiesCount,
            houseKeeperCount: this.dataModel.houseKeeperCount,
            aboutSection: this.dataModel.aboutSection,
            aboutVideoUrl: this.dataModel.aboutVideoUrl,
            testimonialsSection: this.dataModel.testimonialsSection,
            contactSection: this.dataModel.contactSection,
            companyId: api.getCompany(),

          }
          api.AddEditPortalService(api.getCompany(), dataModel).then(result => {
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.$refs.topProgress.done()
          })
            .catch(error => {
              this.$refs.topProgress.done()
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
  },

}
</script>

    <style lang="scss">
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    @import "~@core/scss/base/pages/app-invoice.scss";
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/quill.scss";
    .add-new {
        padding: $options-padding-y $options-padding-x;
          color: $success;

        &:hover {
          background-color: rgba($success, 0.12);
        }
    }
    </style>
