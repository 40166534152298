<template>
    <section class="invoice-add-wrapper">
        <b-form @submit.prevent>
            <validation-observer ref="validationRules">
            
                <b-card no-body class="mb-2 shadow-sm">
                    <b-card-header class="bg-primary text-white">معلومات العاملة</b-card-header>
                    <b-card-body>
                        <br>
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group label="العاملة" label-for="employee-id">
                                    <v-select v-model="transferRequest.employeeId" :options="employeesOptions"
                                        :reduce="employee => employee.id" label="employeeName"
                                        placeholder="اختر العاملة" required></v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

               <b-card no-body class="mb-2 shadow-sm">
                    <b-card-header class="bg-primary text-white">معلومات العميل</b-card-header>
                    <b-card-body>
                        <br>
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group label="رقم الهوية">
                                    <ValidationProvider name="رقم الهوية" rules="required|integer" v-slot="{ errors }">
                                        <b-form-input id="socialID" v-model="transferRequest.socialID" maxlength="10"
                                            :state="errors.length ? false : null" @input="onSocialIDInput" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group label="رقم الجوال">
                                    <ValidationProvider name="رقم الجوال" rules="required|integer" v-slot="{ errors }">
                                        <b-form-input id="customerMobileNO" v-model="transferRequest.customerMobileNO"
                                            :disabled="IsEditMode" maxlength="12" :state="errors.length ? false : null"
                                            @input="onMobileNoInput" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group label="اسم العميل">
                                    <ValidationProvider name="اسم العميل" rules="required" v-slot="{ errors }">
                                        <b-form-input v-model="transferRequest.customerName" :disabled="IsEditMode"
                                            maxlength="150" :state="errors.length ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="12">
                                <b-form-group label="العنوان">
                                    <ValidationProvider name="العنوان" rules="required" v-slot="{ errors }">
                                        <b-form-input v-model="transferRequest.customerAddress" maxlength="100"
                                            :state="errors.length ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

                <b-card no-body class="mb-2 shadow-sm">
                    <b-card-header class="bg-primary text-white">معلومات الفترة والحالة</b-card-header>
                    <b-card-body>
                        <br>
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group label="تاريخ بدء التجربة" label-for="trial-start-date">
                                    <b-form-input id="trial-start-date" type="date"
                                        v-model="transferRequest.trialStartDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group label="تاريخ انتهاء التجربة" label-for="trial-end-date">
                                    <b-form-input id="trial-end-date" type="date" v-model="transferRequest.trialEndDate"
                                        required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group label="تاريخ التسليم" label-for="delivery-date">
                                    <b-form-input id="delivery-date" type="date" v-model="transferRequest.deliveryDate"
                                        required></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group label="الحالة" label-for="status">
                                    <b-form-select id="status" v-model="transferRequest.requestStatusId"
                                        :options="statusesOptions" required></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

                <div class="text-center">
                    <b-button type="submit"  @click.prevent="createTransferRequest" variant="primary" class="mt-3">حفظ</b-button>
                </div>
            </validation-observer>
        </b-form>
    </section>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BCol,
    BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import TransferRequestsService from '@/services/TransferRequestsService'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BButton,
        BCol,
        BRow,
        ValidationProvider,
        ValidationObserver,
        vSelect,
    },
    data() {
        const { getValidationState } = formValidation(this.transferRequest)
        return {
            t,
            getValidationState,
            required,
            transferRequest: {
                id:0,
                employeeId: null,
                customerId: null,
                trialStartDate: null,
                trialEndDate: null,
                deliveryDate: null,
                requestStatusId: null,
                companyId: api.getCompany(),
                userId: api.getUser(),
                socialID: '',
                customerMobileNO: '',
                customerName: '',
                customerAddress: '',
            },
            statusesOptions: [],
            employeesOptions: [],
            IsEditMode: false,
        }
    },
    methods: {
        async fetchStatuses() {
            try {
                const response = await TransferRequestsService.getStatuses()
                this.statusesOptions = response.data.map(status => ({
                    value: status.id,
                    text: status.statusNameAr,
                }))
            } catch (error) {
                console.error('خطأ في جلب الحالات:', error)
            }
        },
        async fetchEmployees() {
            try {
                const companyId = api.getCompany()
                const response = await TransferRequestsService.getAvailableEmployeesForTransfer(companyId)
                this.employeesOptions = response.data
            } catch (error) {
                console.error('خطأ في جلب العاملةين:', error)
            }
        },
        onSocialIDInput() {
            if (this.transferRequest.socialID.length >= 10) {
                const companyId = api.getCompany();
                this.fetchCustomerBySocialID(this.transferRequest.socialID, companyId);
            }
        },
        onMobileNoInput() {
            if (this.transferRequest.customerMobileNO.length === 10) {
                const companyId = api.getCompany();
                this.fetchCustomerByMobileNo(this.transferRequest.customerMobileNO, companyId);
            }
        },
        async fetchCustomerBySocialID(socialId) {
            try {
                const response = await TransferRequestsService.fetchCustomerBySocialID(socialId, api.getCompany());
                const customer = response.data;
                this.transferRequest.socialID = customer.identityOrIqamaNo;
                this.transferRequest.customerMobileNO = customer.mobileNo;
                this.transferRequest.customerName = customer.customerName;
                this.transferRequest.customerAddress = customer.address;
                this.transferRequest.customerId = customer.id;
            } catch (error) {
                console.error("خطأ في جلب العميل برقم الهوية:", error);
            }
        },
        async fetchCustomerByMobileNo(mobileNo) {
            try {
                const response = await TransferRequestsService.fetchCustomerByMobileNo(mobileNo, api.getCompany());
                const customer = response.data;
                this.transferRequest.socialID = customer.identityOrIqamaNo;
                this.transferRequest.customerMobileNO = customer.mobileNo;
                this.transferRequest.customerName = customer.customerName;
                this.transferRequest.customerAddress = customer.address;
                this.transferRequest.customerId = customer.id;
            } catch (error) {
                console.error("خطأ في جلب العميل برقم الجوال:", error);
            }
        },
        async createTransferRequest() {
            try {
                await TransferRequestsService.create(this.transferRequest)
                alert('تم إنشاء طلب النقل بنجاح')
                this.$router.push({ name: 'transfer-requests-list' })
            } catch (error) {
                console.error('خطأ في إنشاء طلب النقل:', error)
            }
        },
    },
    mounted() {
        this.fetchStatuses()
        this.fetchEmployees()
    },
}
</script>

<style scoped>
.v-select {
    max-width: 100%;
}

.text-center {
    text-align: center;
}
</style>
