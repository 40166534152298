import { render, staticRenderFns } from "./ListPortalServicesDetails.vue?vue&type=template&id=0d99d838&scoped=true&"
import script from "./ListPortalServicesDetails.vue?vue&type=script&lang=js&"
export * from "./ListPortalServicesDetails.vue?vue&type=script&lang=js&"
import style0 from "./ListPortalServicesDetails.vue?vue&type=style&index=0&id=0d99d838&lang=scss&scoped=true&"
import style1 from "./ListPortalServicesDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d99d838",
  null
  
)

export default component.exports