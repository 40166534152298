<template>
  <section class="invoice-add-wrapper">
    <vue-topprogress ref="topProgress" />
    <b-form @submit.prevent>
      <validation-observer ref="validationRules">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="text-right"
          >

            <!-- Action Buttons -->
            <b-button
              variant="primary"
              :disabled="isLoading"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              {{ t("Save Changes") }}
            </b-button>

            &nbsp; &nbsp;
            <b-button
              variant="danger"
              :to="{ name: 'app-mediation-list' }"
            >
              {{ t("Back To List") }}
            </b-button>

          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            cols="12"
            xl="12"
            md="12"
          >

            <b-card>
              <!-- Header -->
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Customer Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: socialID -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Social ID')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Social ID')"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="socialID"
                          v-model="dataModel.socialID"
                          maxlength="10"
                          :state="errors.length > 0 ? false : null"
                          :disabled="IsEditMode"
                          @input="val => GetBySocialID(val)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: customerMobileNO -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Mobile No')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Mobile No')"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="customerMobileNO"
                          v-model="dataModel.customerMobileNO"
                          :disabled="IsEditMode"
                          maxlength="12"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Name -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Customer Name')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Customer Name')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.customerName"
                          :disabled="IsEditMode"
                          maxlength="150"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Country -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Country')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Country')"
                      >
                        <v-select
                          v-model="dataModel.customerCountryId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="NationalityList"
                          :reduce="(val) => val.id"
                          :disabled="IsEditMode"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getNationalityList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(8)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: RegionId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Region')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Region')"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.customerRegionId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="RegionList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          :disabled="IsEditMode"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getRegionList()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: City -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('City')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('City')"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.customerCityId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="CityList"
                          :reduce="val => val.id"
                          :clearable="false"
                          :disabled="IsEditMode"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getCitiesList(dataModel.customerRegionId)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: customerGenderId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Gender')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Gender')"
                        rules="required"
                      >
                        <v-select
                          v-model="dataModel.customerGenderId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="GenderList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          :disabled="IsEditMode"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getGenderList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(7)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: customerProfession -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Profession')">
                      <validation-provider
                        #default="{ errors }"
                        :name="t('Profession')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.customerProfession"
                          maxlength="100"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Contract Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Contract No -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Contract No')">
                      <b-form-input
                        v-model="dataModel.contractNO"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Musaned No -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Musaned No')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                        :name="t('Musaned No')"
                      >
                        <b-form-input
                          v-model="dataModel.mosandContractNo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <!-- Field: contractDate -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Contract Date')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Contract Date')"
                      >
                        <flat-pickr
                          v-model="dataModel.contractDate"
                          :state="errors.length > 0 ? false : null"
                          class="form-control invoice-edit-input"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                  <!-- Field: Contract Value -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Contract Value')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.contactAmount"
                          :disabled="IsEditMode"
                          :state="errors.length > 0 ? false : null"
                          @input="val => updateContactAmount(val)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VAT Ratio -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('VAT Ratio')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-input
                          v-model="dataModel.vatPercentage"
                          :disabled="IsEditMode"
                          :state="errors.length > 0 ? false : null"
                          @input="val => updateVatAmount(val)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: VATValue -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('VAT Value')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="dataModel.vatAmount"
                          disabled
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: amountWithVAT -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Total With VAT')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-input
                          v-model="dataModel.amountWithVAT"

                          disabled
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: MusanedFee -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Musaned Fee')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-input
                          v-model="dataModel.musanedFee"
                          :disabled="IsEditMode"
                          :state="errors.length > 0 ? false : null"
                          @input="val => updateMusanedFee(val)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: AmountDue -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Amount Due')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-input
                          v-model="dataModel.amountDue"
                          disabled
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Required Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: Worker Name -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Worker Name')">

                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Worker Name')"
                      >

                        <b-form-input
                          v-model="dataModel.workerName"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: agentId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Agent')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Agent')"
                      >
                        <v-select
                          v-model="dataModel.agentId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="AgentList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getAgentList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(26)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: workerProfessionId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Job')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Job')"
                      >
                        <v-select
                          v-model="dataModel.workerProfessionId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="JobList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getJobList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(11)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: NationalityId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Nationality')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Nationality')"
                      >
                        <v-select
                          v-model="dataModel.workerNationalityId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="NationalityList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getNationalityList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(8)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ReligionId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Religion')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Religion')"
                      >
                        <v-select
                          v-model="dataModel.workerReligionId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ReligionsList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getReligionsList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(9)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: ArrivalDestinationId -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Arrival Destination')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Arrival Destination')"
                      >
                        <v-select
                          v-model="dataModel.arrivalDestinationId"
                          :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                          :options="ArrivalDestinationList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          input-id="id"
                          :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                          @click.native="getArrivalDestinationList()"
                        >
                          <template #list-header>
                            <li
                              v-b-modal.modal-new
                              class="add-new d-flex align-items-center my-50"
                              @click="SetAcceptedId(17)"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">{{ t("Add New") }}</span>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: AgeOf -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Age Of')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                        :name="t('Age Of')"
                      >
                        <b-form-input
                          v-model="dataModel.ageOf"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: AgeTo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Age To')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                        :name="t('Age To')"
                      >
                        <b-form-input
                          v-model="dataModel.ageTo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Salary -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Salary')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required|regex:^([0-9]+)$"
                        :name="t('Salary')"
                      >
                        <b-form-input
                          v-model="dataModel.workerSalary"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: workerQualification -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Worker Qualification')">
                      <b-form-input v-model="dataModel.workerQualification" />
                    </b-form-group>
                  </b-col>

                  <!-- Field: ResponsiblePerson -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Responsible Person')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="t('Responsible Person')"
                      >
                        <b-form-input
                          v-model="dataModel.responsiblePerson"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: visaNo -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Visa No')">

                      <b-form-input v-model="dataModel.visaNo" />

                    </b-form-group>
                  </b-col>
                  <!-- Field: visaExpiryDate -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Visa Expiry Date')">

                      <flat-pickr
                        v-model="dataModel.visaExpiryDate"
                        class="form-control invoice-edit-input"
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Other Information")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: isDelegation -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group :label="t('Delegation')">
                      <b-form-checkbox v-model="dataModel.isDelegation" />
                    </b-form-group>
                  </b-col>
                  <!-- Field: isArrived -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group :label="t('Arrived')">
                      <b-form-checkbox
                        v-model="dataModel.isArrived"
                        @input="val => updateArrived(val)"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Field: isTicketReserved -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group :label="t('Ticket Reserved')">
                      <b-form-checkbox v-model="dataModel.isTicketReserved" />
                    </b-form-group>
                  </b-col>
                  <!-- Field: isPaid -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group :label="t('Paid')">
                      <b-form-checkbox v-model="dataModel.isPaid" />
                    </b-form-group>
                  </b-col>
                  <!-- Field: delegationDate -->
                  <b-col
                    v-if="dataModel.isDelegation"
                    cols="12"
                    md="3"
                  >
                    <b-form-group :label="t('Delegation Date')">
                      <flat-pickr
                        v-model="dataModel.delegationDate"
                        class="form-control invoice-edit-input"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: arrivalDate -->
                  <b-col
                    v-if="dataModel.isArrived"
                    cols="12"
                    md="3"
                  >
                    <b-form-group :label="t('Arrival Date')">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <flat-pickr
                          v-model="dataModel.arrivalDate"
                          class="form-control invoice-edit-input"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>

                  <!-- Field: Additional Terms -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group :label="t('Additional Terms')">

                      <quill-editor
                        v-model="dataModel.additionalTerms"
                        :options="snowOption"
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Mediation Procedures")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <!-- Field: paymentType -->
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Procedure Type')">

                      <v-select
                        v-model="dataModel.procedureType"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ProcedureTypeOptions"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @input="val => updateProcedureType(val)"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Cancelation Side')">

                      <v-select
                        v-model="dataModel.cancelationSide"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="CancelationSideOptions"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        :disabled="dataModel.isArrived"
                        @input="val => updateCancelationSide(val)"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Field: RequestDate -->
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Request Date')">

                      <flat-pickr
                        v-model="dataModel.requestDate"
                        class="form-control invoice-edit-input"
                        @input="val => updateRequestDate(val)"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Field: Use Days -->
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Use Days')">

                      <b-form-input
                        v-model="dataModel.useDays"
                        readonly
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Field: Wait Days -->
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Wait Days')">

                      <b-form-input
                        v-model="dataModel.waitDays"
                        readonly
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Field: Cancelation Penalty -->
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Cancelation Penalty')">

                      <b-form-input
                        v-model="dataModel.cancelationPenalty"
                        readonly
                      />

                    </b-form-group>
                  </b-col>

                  <!-- Field: Apply Penalty ? -->
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Apply Penalty')">
                      <v-select
                        v-model="dataModel.applyPenalty"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="ApplyPenaltyOptions"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        :disabled="dataModel.procedureType === 1 ? false : true"
                        @input="val => updateApplyPenalty(val)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Total Penalty')">

                      <b-form-input
                        v-model="dataModel.totalPenalty"
                        readonly
                      />

                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="t('Customer Balance')">

                      <b-form-input
                        v-model="dataModel.customerBalance"
                        readonly
                      />

                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Invoices")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >

                    <b-table
                      class="position-relative"
                      :items="InvoicesList"
                      :fields="InvoiceFields"
                      responsive=""
                      primary-key="id"
                      show-empty
                      empty-text="No matching records found"
                    >

                      <!-- Column: Actions -->
                      <template #cell(actions)="data">
                        <b-button-group>
                          <b-button
                            variant="success"
                            size="sm"
                            @click="PrintInvoice(data.item.uuid)"
                          >
                            {{ t("Print Invoice") }}
                          </b-button>
                          &nbsp;&nbsp;&nbsp;
                          <b-button
                            v-if="data.item.jId === 0"
                            v-b-modal.transfer_to_accounts
                            variant="primary"
                            size="sm"
                            @click="GetInvoice(data.item)"
                          >
                            {{ t("Transfer to Accounts") }}
                          </b-button>
                          <b-button
                            v-if="data.item.jId != 0"
                            variant="primary"
                            size="sm"
                            @click="PrintJournal(data.item.juid)"
                          >
                            {{ t("Journal") }}
                          </b-button>
                        </b-button-group>
                      </template>
                    </b-table>

                  </b-col>

                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Receipt Voucher")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >
                    <div class="m-2">
                      <!-- Table Top -->
                      <b-row>
                        <!-- Per Page -->
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >

                          <b-button
                            v-b-modal.modal-lg
                            variant="primary"
                          >
                            <feather-icon
                              icon="PlusCircleIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">
                              {{ t("Add New") }}
                            </span>
                          </b-button>

                        </b-col>

                        <!-- Search -->
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                              v-model="filter"
                              class="d-inline-block mr-1"
                              :placeholder="t('Search...')"
                            />
                            <b-button
                              variant="primary"
                              :disabled="!filter"
                              @click="filter = ''"
                            >
                              <span class="text-nowrap">{{ t('Clear') }}</span>
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <b-table
                      class="position-relative"
                      :items="DataList"
                      :fields="fields"
                      :filter="filter"
                      responsive=""
                      primary-key="id"
                      show-empty
                      empty-text="No matching records found"
                    >

                      <!-- Column: Actions -->
                      <template #cell(actions)="data">
                        <div class="text-nowrap">
                          <feather-icon
                            :id="`row-${data.item.id}-edit-icon`"
                            icon="PrinterIcon"
                            class="cursor-pointer"
                            size="16"
                            @click="PrintBond(data.item.id)"
                          />
                          <b-tooltip
                            title="Print"
                            class="cursor-pointer"
                            :target="`row-${data.item.id}-edit-icon`"
                          />

                          &nbsp;&nbsp;&nbsp;
                          <feather-icon
                            v-if="data.item.jId === 0"
                            v-b-tooltip.hover.bottom="'Transfer To Accounts'"
                            :disabled="isLoading"
                            icon="BookmarkIcon"
                            class="cursor-pointer"
                            size="16"
                            @click="CreateJournalFromBond(data.item)"
                          >
                            <b-spinner
                              v-if="isLoading"
                              small
                            />
                          </feather-icon>

                          <feather-icon
                            v-if="data.item.jId != 0"
                            v-b-tooltip.hover.bottom="'Journal'"
                            icon="FileTextIcon"
                            class="cursor-pointer"
                            size="16"
                            @click="PrintJournal(data.item.juid)"
                          />

                        </div>
                      </template>
                    </b-table>

                  </b-col>

                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >
                    <br>
                    <b-card-title>
                      <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span>{{
                        t("Exchange Voucher")
                      }}</span>
                    </b-card-title>
                    <!-- Spacer -->
                    <hr class="invoice-spacing">
                  </b-col>
                  <b-col
                    v-if="IsBond"
                    cols="12"
                    md="12"
                  >
                    <div class="m-2">
                      <!-- Table Top -->
                      <b-row>
                        <!-- Per Page -->
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >

                          <b-button
                            v-if="dataModel.customerBalance!=0"
                            variant="primary"
                            @click="CheckBalance"
                          >
                            <feather-icon
                              icon="PlusCircleIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">
                              {{ t("Add New") }}
                            </span>
                          </b-button>

                        </b-col>

                        <!-- Search -->
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                              v-model="filterExchange"
                              class="d-inline-block mr-1"
                              :placeholder="t('Search...')"
                            />
                            <b-button
                              variant="primary"
                              :disabled="!filterExchange"
                              @click="filterExchange = ''"
                            >
                              <span class="text-nowrap">{{ t('Clear') }}</span>
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <b-table
                      class="position-relative"
                      :items="DataListExchange"
                      :fields="fieldsExchange"
                      :filter="filterExchange"
                      responsive=""
                      primary-key="id"
                      show-empty
                      empty-text="No matching records found"
                    >

                      <!-- Column: Actions -->
                      <template #cell(actions)="data">
                        <div class="text-nowrap">
                          <feather-icon
                            :id="`row-${data.item.id}-edit-icon`"
                            icon="PrinterIcon"
                            class="cursor-pointer"
                            size="16"
                            @click="PrintBond(data.item.id)"
                          />
                          <b-tooltip
                            title="Print"
                            class="cursor-pointer"
                            :target="`row-${data.item.id}-edit-icon`"
                          />

                          &nbsp;&nbsp;&nbsp;
                          <feather-icon
                            v-if="data.item.jId === 0"
                            v-b-tooltip.hover.bottom="'Transfer To Accounts'"
                            :disabled="isLoading"
                            icon="BookmarkIcon"
                            class="cursor-pointer"
                            size="16"
                            @click="CreateJournalFromBond(data.item)"
                          >
                            <b-spinner
                              v-if="isLoading"
                              small
                            />
                          </feather-icon>

                          <feather-icon
                            v-if="data.item.jId != 0"
                            v-b-tooltip.hover.bottom="'Journal'"
                            icon="FileTextIcon"
                            class="cursor-pointer"
                            size="16"
                            @click="PrintJournal(data.item.juid)"
                          />

                        </div>
                      </template>
                    </b-table>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>

          </b-col>

        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-new"
      ref="modal-new"
      :hide-footer="true"
      centered
      size="lg"
      title="t('Add New')"
    >
      <add-accepted-Value
        :id="0"
        :parent-id="0"
        :accepted-value-id="acceptedId"
      />

    </b-modal>
    <b-modal
      id="modal-lg"
      ref="modal-lg"
      centered
      size="lg"
      :title="t('Receipt Voucher')"
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.prevent>
        <validation-observer ref="validationBondRules">
          <b-row>
            <!-- Left Col: Card -->
            <b-col
              cols="12"
              xl="12"
              md="12"
            >

              <b-row>
                <!-- notebook -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Notebook')">
                    <b-form-input v-model="bondModel.notebook" />
                  </b-form-group>
                </b-col>
                <!-- bondDate -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Bond Date')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Bond Date')"
                    >
                      <flat-pickr
                        v-model="bondModel.bondDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: amount -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Amount')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Amount')"
                    >
                      <b-form-input
                        v-model="bondModel.amount"
                        :state="errors.length > 0 ? false : null"
                        @input="val => updateBondAmount(val)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: accountFrom -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('From Account')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('From Account')"
                    >
                      <v-select
                        v-model="bondModel.accountFrom"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="AccountsList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: paymentType -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Payment Type')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Payment Type')"
                    >
                      <v-select
                        v-model="bondModel.paymentType"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="PaymentTypeOptions"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: paperNo -->
                <b-col
                  v-if="bondModel.paymentType === 2"
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Paper No')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Paper No')"
                    >
                      <b-form-input
                        v-model="bondModel.paperNo"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: paperDate -->
                <b-col
                  v-if="bondModel.paymentType === 2"
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Paper Date')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Paper Date')"
                    >
                      <flat-pickr
                        v-model="bondModel.paperDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- representativeId -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Representative')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Representative')"
                    >
                      <v-select
                        v-model="bondModel.representativeId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="EmployeeList"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- costCenterId -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Cost Center')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Cost Center')"
                    >
                      <v-select
                        v-model="bondModel.costCenterId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="CostCenterList"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- currencyId -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Currency')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Currency')"
                    >
                      <v-select
                        v-model="bondModel.currencyId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="CurrencyList"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @input="val => GetCurrencyExRate(val)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- exRate -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Exchange Rate')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Exchange Rate')"
                    >
                      <b-form-input
                        v-model="bondModel.exRate"
                        :state="errors.length > 0 ? false : null"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- description -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group :label="t('Description')">
                    <b-form-input
                      v-model="bondModel.description"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>

          </b-row>
          <b-button
            variant="primary"
            :disabled="isLoading"
            @click.prevent="validationBondForm"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            {{ t("Save Changes") }}
          </b-button>
        </validation-observer>
      </b-form>

    </b-modal>
    <b-modal
      id="modalExchange-lg"
      ref="modalExchange-lg"
      centered
      size="lg"
      :title="t('Exchange Voucher')"
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.prevent>
        <validation-observer ref="validationBondRules">
          <b-row>
            <!-- Left Col: Card -->
            <b-col
              cols="12"
              xl="12"
              md="12"
            >

              <b-row>

                <!-- notebook -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Notebook')">
                    <b-form-input v-model="bondExchangeModel.notebook" />
                  </b-form-group>
                </b-col>
                <!-- bondDate -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Bond Date')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Bond Date')"
                    >
                      <flat-pickr
                        v-model="bondExchangeModel.bondDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: amount -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Amount')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Amount')"
                    >
                      <b-form-input
                        v-model="bondExchangeModel.amount"
                        :state="errors.length > 0 ? false : null"
                        readonly
                        @input="val => updateBondAmount(val)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: accountFrom -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('To Account')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('To Account')"
                    >
                      <v-select
                        v-model="bondExchangeModel.accountTo"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="AccountsList"
                        :reduce="(val) => val.id"
                        :clearable="false"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: paymentType -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Payment Type')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Payment Type')"
                    >
                      <v-select
                        v-model="bondExchangeModel.paymentType"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="PaymentTypeOptions"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: paperNo -->
                <b-col
                  v-if="bondExchangeModel.paymentType === 2"
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Paper No')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Paper No')"
                    >
                      <b-form-input
                        v-model="bondExchangeModel.paperNo"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field: paperDate -->
                <b-col
                  v-if="bondExchangeModel.paymentType === 2"
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Paper Date')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Paper Date')"
                    >
                      <flat-pickr
                        v-model="bondExchangeModel.paperDate"
                        :state="errors.length > 0 ? false : null"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- representativeId -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Representative')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Representative')"
                    >
                      <v-select
                        v-model="bondExchangeModel.representativeId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="EmployeeList"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- costCenterId -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Cost Center')">

                    <v-select
                      v-model="bondExchangeModel.costCenterId"
                      :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                      :options="CostCenterList"
                      :reduce="(val) => val.id"
                      input-id="id"
                      :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                    />
                  </b-form-group>
                </b-col>
                <!-- currencyId -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Currency')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Currency')"
                    >
                      <v-select
                        v-model="bondExchangeModel.currencyId"
                        :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                        :options="CurrencyList"
                        :reduce="(val) => val.id"
                        input-id="id"
                        :label="getLanguage() === 1 ? 'name' : 'nameEn'"
                        @input="val => GetCurrencyExRate(val)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- exRate -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="t('Exchange Rate')">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="t('Exchange Rate')"
                    >
                      <b-form-input
                        v-model="bondExchangeModel.exRate"
                        :state="errors.length > 0 ? false : null"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- description -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group :label="t('Description')">
                    <b-form-input
                      v-model="bondExchangeModel.description"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>

          </b-row>
          <b-button
            variant="primary"
            :disabled="isLoading"
            @click.prevent="validationBondExchangeForm"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            {{ t("Save Changes") }}
          </b-button>
        </validation-observer>
      </b-form>

    </b-modal>
    <b-modal
      id="transfer_to_accounts"
      ref="transfer_to_accounts"
      centered
      size="lg"
      :title="t('Transfer to Accounts')"
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.prevent>
        <validation-observer ref="validationTransferToAccountsRules">
          <b-row>
            <!-- Field: Amount -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group :label="t('Amount')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Amount')"
                >
                  <b-form-input
                    v-model="transferToAccountsModel.fromAccountValue"
                    :state="errors.length > 0 ? false : null"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Customer Account -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Customer Account')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Customer Account')"
                >
                  <v-select
                    v-model="transferToAccountsModel.fromAccountId"
                    :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                    :options="AccountsList"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    input-id="id"
                    :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Amount -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group :label="t('Amount')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Amount')"
                >
                  <b-form-input
                    v-model="transferToAccountsModel.toAccountValue"
                    :state="errors.length > 0 ? false : null"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Sales Account -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Sales Account')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Sales Account')"
                >
                  <v-select
                    v-model="transferToAccountsModel.toAccountId"
                    :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                    :options="AccountsList"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    input-id="id"
                    :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Amount -->
            <b-col

              cols="12"
              md="2"
            >
              <b-form-group
                v-if="transferToAccountsModel.discountAccountValue !== 0"
                :label="t('Amount')"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Amount')"
                >
                  <b-form-input
                    v-model="transferToAccountsModel.discountAccountValue"
                    :state="errors.length > 0 ? false : null"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Discount Account -->
            <b-col

              cols="12"
              md="4"
            >
              <b-form-group
                v-if="transferToAccountsModel.discountAccountValue !== 0"
                :label="t('Discount Account')"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Discount Account')"
                >
                  <v-select
                    v-model="transferToAccountsModel.discountAccountId"
                    :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                    :options="AccountsList"
                    :reduce="(val) => val.id"
                    :clearable="true"
                    input-id="id"
                    :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Amount -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group :label="t('Amount')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Amount')"
                >
                  <b-form-input
                    v-model="transferToAccountsModel.vatAccountValue"
                    :state="errors.length > 0 ? false : null"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Vat Account -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="t('Vat Account')">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="t('Vat Account')"
                >
                  <v-select
                    v-model="transferToAccountsModel.vatAccountId"
                    :dir="getLanguage() === 1 ? 'rtl' : 'ltr'"
                    :options="AccountsList"
                    :reduce="(val) => val.id"
                    :clearable="true"
                    input-id="id"
                    :label="getLanguage() === 1 ? 'accountName' : 'accountNameEn'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <hr>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  :disabled="isLoading"
                  @click="validationTransferToAccountsForm"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  <span class="text-nowrap">{{ t('Transfer to Accounts') }}</span>
                </b-button>
              </div>
            </b-col>

          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BSpinner, BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox, BCardBody, BCardTitle, BTable,
  BTooltip, VBTooltip, BButtonGroup,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { vueTopprogress } from 'vue-top-progress'
import vSelect from 'vue-select'
import AddAcceptedValue from '../../../components/AddAcceptedValue.vue'
import api from '../../../services/api'

const { t } = useI18nUtils()

export default {
  components: {
    vueTopprogress,
    quillEditor,
    BCard,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    AddAcceptedValue,
    flatPickr,
    BCardBody,
    BCardTitle,
    BSpinner,
    BTooltip,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    const { getValidationState } = formValidation(this.dataModel)
    const { getValidationStatebond } = formValidation(this.bondModel)
    const { getValidationStatetransferToAccounts } = formValidation(this.transferToAccountsModel)
    return {
      t,
      snowOption: {
        theme: 'snow',
      },
      getValidationState,
      getValidationStatebond,
      getValidationStatetransferToAccounts,
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
      DataList: [],
      dataModel: {
        socialID: '',
        customerMobileNO: '',
        customerName: '',
        customerCountryId: '',
        customerRegionId: '',
        customerCityId: '',
        customerGenderId: '',
        customerProfession: '',
        contractNO: '',
        mosandContractNo: '',
        contractDate: new Date(),
        contactAmount: 0,
        vatPercentage: 0,
        vatAmount: 0,
        amountWithVAT: 0,
        musanedFee: 0,
        amountDue: 0,
        workerName: '',
        agentId: '',
        workerProfessionId: '',
        workerNationalityId: '',
        workerReligionId: '',
        arrivalDestinationId: '',
        ageOf: '',
        ageTo: '',
        workerSalary: '',
        workerQualification: '',
        responsiblePerson: '',
        isDelegation: false,
        delegationDate: new Date(),
        isArrived: false,
        arrivalDate: new Date(),
        visaNo: '',
        visaExpiryDate: '',
        isTicketReserved: false,
        createdDate: new Date(),
        createdByUser: api.getUser(),
        isPaid: false,
        contractStatusId: 0,
        additionalTerms: '',
        companyId: api.getCompany(),
        branchId: 0,
        procedureType: 0,
        cancelationSide: 0,
        requestDate: new Date(),
        useDays: 0,
        waitDays: 0,
        cancelationPenalty: 0,
        totalPenalty: 0,
        applyPenalty: 2,
        customerBalance: 0,
      },
      bondModel: {
        bondType: 1,
        bondNo: '',
        notebook: '',
        bondDate: new Date(),
        accountFrom: '',
        accountTo: '',
        amount: '',
        vatRatio: 0,
        vatValue: 0,
        amountWithVat: 0,
        representativeId: 0,
        paymentType: 1,
        paperNo: '',
        paperDate: '',
        description: '',
        companyId: api.getCompany(),
        branchId: 0,
        userId: api.getUser(),
        carryOvered: true,
        costCenterId: 0,
        currencyId: '',
        exRate: 1,
        recSource: 1,
        recId: 0,
        yearId: 0,
      },
      bondExchangeModel: {
        bondType: 2,
        bondNo: '',
        notebook: '',
        bondDate: new Date(),
        accountFrom: '',
        accountTo: '',
        amount: '',
        vatRatio: 0,
        vatValue: 0,
        amountWithVat: 0,
        representativeId: 0,
        paymentType: 1,
        paperNo: '',
        paperDate: '',
        description: '',
        companyId: api.getCompany(),
        branchId: 0,
        userId: api.getUser(),
        carryOvered: true,
        costCenterId: 0,
        currencyId: '',
        exRate: 1,
        recSource: 1,
        recId: 0,
        yearId: 0,
      },
      transferToAccountsModel: {
        invoiceId: 0,
        fromAccountId: 0,
        fromAccountValue: 0,
        discountAccountId: 0,
        discountAccountValue: 0,
        toAccountId: 0,
        toAccountValue: 0,
        vatAccountId: 0,
        vatAccountValue: 0,
        costCenterId: 0,
        currencyId: 0,
        exRate: 0,
        companyId: api.getCompany(),
        branchId: 0,
        userId: api.getUser(),
      },
      transferBondToAccountsDto: {
        bondId: 0,
        fromAccountId: 0,
        fromAccountValue: 0,
        toAccountId: 0,
        toAccountValue: 0,
        vatAccountId: 0,
        vatAccountValue: 0,
        costCenterId: 0,
        currencyId: 0,
        exRate: 0,
        companyId: api.getCompany(),
        branchId: 0,
        userId: api.getUser(),
      },
      id: 0,
      RegionList: [],
      CityList: [],
      DistrictList: [],
      TitlesList: [],
      BankList: [],
      AccessMethodList: [],
      SocialStatusList: [],
      GenderList: [],
      NationalityList: [],
      VisaTypeList: [],
      BranchsList: [],
      acceptedId: 0,
      ReligionsList: [],
      JobList: [],
      ArrivalDestinationList: [],
      ArrivalAirportList: [],
      ExperienceList: [],
      HousingTypeList: [],
      AppearanceList: [],
      AlMaqqabList: [],
      MediatorList: [],
      AgentList: [],
      CustomersList: [],
      ServicesList: [],
      ExternalOfficeList: [],
      errorsList: [],
      DataListExchange: [],
      isLoading: false,
      filter: null,
      filterExchange: null,
      totalRowsExchange: 1,

      perPageExchange: 5,
      totalRows: 1,
      currentPageExchange: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      PaymentTypeOptions: [
        { id: 1, name: 'صندوق', nameEn: 'Cash' },
        { id: 2, name: 'بنك', nameEn: 'Bank' },
      ],
      BondTypeOptions: [
        { id: 1, name: 'سند قبض', nameEn: 'Receipt Voucher' },
        { id: 2, name: 'سند صرف', nameEn: 'Exchange Voucher' },
      ],
      ProcedureTypeOptions: [
        { id: 1, name: 'الغاء', nameEn: 'Cancelation' },
      ],
      CancelationSideOptions: [
        { id: 1, name: 'الشركة', nameEn: 'Company' },
        { id: 2, name: 'العميل', nameEn: 'Client' },
      ],
      ApplyPenaltyOptions: [
        { id: 1, name: 'نعم', nameEn: 'Yes' },
        { id: 2, name: 'لا', nameEn: 'No' },
      ],
      AccountsList: [],
      EmployeeList: [],
      CostCenterList: [],
      CurrencyList: [],
      InvoicesList: [],
      CustomerId: 0,
      totalAmount: 0,
      totalExchangeAmount: 0,
      IsBond: false,
      IsEditMode: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          label: t('Bond No'),
          key: 'bondNo',
          sortable: true,
        },
        {
          label: t('Bond Type'),
          key: api.getLanguage() === 1 ? 'bondTypeName' : 'bondTypeNameEn',
          sortable: true,
        },
        {
          label: t('Bond Date'),
          key: 'bondDate',
          sortable: true,
        },
        {
          label: t('From Account'),
          key: api.getLanguage() === 1 ? 'accountFromName' : 'accountFromNameEn',
          sortable: true,
        },

        {
          label: t('To Account'),
          key: api.getLanguage() === 1 ? 'accountToName' : 'accountToNameEn',
          sortable: true,
        },

        {
          label: t('Amount'),
          key: 'amountWithVat',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
    fieldsExchange() {
      return [
        {
          label: t('Bond No'),
          key: 'bondNo',
          sortable: true,
        },
        {
          label: t('Bond Type'),
          key: api.getLanguage() === 1 ? 'bondTypeName' : 'bondTypeNameEn',
          sortable: true,
        },
        {
          label: t('Bond Date'),
          key: 'bondDate',
          sortable: true,
        },
        {
          label: t('From Account'),
          key: api.getLanguage() === 1 ? 'accountFromName' : 'accountFromNameEn',
          sortable: true,
        },

        {
          label: t('To Account'),
          key: api.getLanguage() === 1 ? 'accountToName' : 'accountToNameEn',
          sortable: true,
        },

        {
          label: t('Amount'),
          key: 'amountWithVat',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
    InvoiceFields() {
      return [
        {
          label: t('Invoice No'),
          key: 'id',
          sortable: true,
        },
        {
          label: t('Description'),
          key: 'serviceDescription',
          sortable: true,
        },
        {
          label: t('Date'),
          key: 'invoiceDate',
          sortable: true,
        },

        {
          label: t('Total'),
          key: 'invoiceTotal',
          sortable: true,
        },
        {
          label: t('Vat Ratio'),
          key: 'vatRatio',
          sortable: true,
        },
        {
          label: t('Vat Value'),
          key: 'vatValue',
          sortable: true,
        },
        {
          label: t('Invoice Net'),
          key: 'invoiceNet',
          sortable: true,
        },

        { key: 'actions', label: '' },
      ]
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    validationForm() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.isLoading = true
          const dataModel = {
            socialID: this.dataModel.socialID,
            customerMobileNO: this.dataModel.customerMobileNO,
            customerName: this.dataModel.customerName,
            customerCountryId: this.dataModel.customerCountryId,
            customerRegionId: this.dataModel.customerRegionId,
            customerCityId: this.dataModel.customerCityId,
            customerGenderId: this.dataModel.customerGenderId,
            customerProfession: this.dataModel.customerProfession,
            contractNO: this.dataModel.contractNO,
            // eslint-disable-next-line radix
            mosandContractNo: parseInt(this.dataModel.mosandContractNo),
            contractDate: this.dataModel.contractDate,
            contactAmount: parseFloat(this.dataModel.contactAmount),
            vatPercentage: parseFloat(this.dataModel.vatPercentage),
            vatAmount: parseFloat(this.dataModel.vatAmount),
            amountWithVAT: parseFloat(this.dataModel.amountWithVAT),
            musanedFee: parseFloat(this.dataModel.musanedFee),
            amountDue: parseFloat(this.dataModel.amountDue),
            workerName: this.dataModel.workerName,
            agentId: this.dataModel.agentId,
            workerProfessionId: this.dataModel.workerProfessionId,
            workerNationalityId: this.dataModel.workerNationalityId,
            workerReligionId: this.dataModel.workerReligionId,
            arrivalDestinationId: this.dataModel.arrivalDestinationId,
            // eslint-disable-next-line radix
            ageOf: parseInt(this.dataModel.ageOf),
            // eslint-disable-next-line radix
            ageTo: parseInt(this.dataModel.ageTo),
            workerSalary: parseFloat(this.dataModel.workerSalary),
            workerQualification: this.dataModel.workerQualification,
            responsiblePerson: this.dataModel.responsiblePerson,
            isDelegation: this.dataModel.isDelegation,
            delegationDate: this.dataModel.delegationDate,
            isArrived: this.dataModel.isArrived,
            arrivalDate: this.dataModel.arrivalDate,
            visaNo: this.dataModel.visaNo,
            visaExpiryDate: this.dataModel.visaExpiryDate,
            isTicketReserved: this.dataModel.isTicketReserved,
            // eslint-disable-next-line radix
            createdByUser: parseInt(api.getUser()),
            isPaid: this.dataModel.isPaid,
            contractStatusId: this.dataModel.contractStatusId,
            additionalTerms: this.dataModel.additionalTerms,
            companyId: api.getCompany(),
            branchId: this.dataModel.branchId,
            procedureType: this.dataModel.procedureType,
            cancelationSide: this.dataModel.cancelationSide,
            requestDate: this.dataModel.requestDate,
            useDays: this.dataModel.useDays,
            waitDays: this.dataModel.waitDays,
            cancelationPenalty: this.dataModel.cancelationPenalty,
            totalPenalty: this.dataModel.totalPenalty,
            applyPenalty: this.dataModel.applyPenalty,
            customerBalance: this.dataModel.customerBalance,

          }
          api.CreateUpdateMediation(this.decryptId(router.currentRoute.params.id) === '' ? 0 : this.decryptId(router.currentRoute.params.id), dataModel)
            .then(result => {
              this.$swal({
                title: this.$t('Good job!'),
                text: this.$t('Your details have been saved successfully.'),
                icon: result.data.httpCode === 200 ? 'success' : 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$router.push({ name: 'app-mediation-list' })
              if (this.decryptId(router.currentRoute.params.id) === '' || this.decryptId(router.currentRoute.params.id) === 0 || this.decryptId(router.currentRoute.params.id) === '0' || this.decryptId(router.currentRoute.params.id) === undefined) {
                this.IsBond = true
                // this.$router.push({ name: 'app-mediation-edit', params: { id: result.data.data } })
                this.encryptId(result.data.data)
                this.getDataList(result.data.data)
              } else {
                this.init()
              }
              this.isLoading = false
              this.GetAccountByRecId(this.CustomerId)
              this.$refs.validationRules.reset()
            })
            .catch(error => {
              this.isLoading = false
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    validationBondForm() {
      if (this.bondModel.amount > this.dataModel.amountWithVAT - this.totalAmount) {
        this.$swal({
          title: this.$t('warning'),
          text: this.$t('It is not allowed to make a receipt voucher greater than the contract value'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else if (this.bondModel.amount <= 0) {
        this.$swal({
          title: this.$t('warning'),
          text: this.$t('It is not allowed to make a receipt voucher equal  0'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$refs.validationBondRules.validate().then(success => {
          if (success) {
            this.isLoading = true
            // eslint-disable-next-line
          this.bondModel.recId = this.decryptId(router.currentRoute.params.id)
            api.CreateUpdateBond(this.id, this.bondModel).then(result => {
              this.getDataList(this.decryptId(router.currentRoute.params.id))
              this.$swal({
                title: this.$t('Good job!'),
                text: this.$t('Your details have been saved successfully.'),
                icon: result.data.httpCode === 200 ? 'success' : 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.isLoading = false
              this.$refs['modal-lg'].hide()
              this.bondModel = {
                bondType: 1,
                notebook: '',
                bondDate: new Date(),
                amount: '',
                vatRatio: 0,
                vatValue: 0,
                amountWithVat: 0,
                representativeId: 0,
                paymentType: 1,
                paperNo: '',
                paperDate: '',
                description: '',
                companyId: api.getCompany(),
                branchId: 0,
                userId: api.getUser(),
                carryOvered: true,
                costCenterId: 0,
                currencyId: '',
                exRate: 1,
                recSource: 1,
                recId: this.decryptId(router.currentRoute.params.id),
                yearId: 0,
              }
              this.GetAccountByRecId(this.CustomerId)
              this.$refs.validationBondRules.reset()
            })
            // eslint-disable-next-line no-unused-vars
              .catch(error => {
                this.isLoading = false
                this.$swal({

                  title: error.response.data.title,
                  html: Object.values(error.response.data.errors)
                    .map(item => `<div>${item}</div>`)
                    .join(''),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
      }
    },
    validationBondExchangeForm() {
      if (this.totalExchangeAmount >= this.dataModel.customerBalance) {
        this.$swal({
          title: this.$t('Warning'),
          text: this.$t('The customer has no balance'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.$refs.validationBondRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          // eslint-disable-next-line
          this.bondExchangeModel.recId = this.decryptId(router.currentRoute.params.id)
          api.CreateUpdateBond(this.id, this.bondExchangeModel).then(result => {
            this.getDataList(this.decryptId(router.currentRoute.params.id))
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isLoading = false
            this.$refs['modalExchange-lg'].hide()
            this.bondExchangeModel = {
              bondType: 2,

              notebook: '',
              bondDate: new Date(),

              amount: '',
              vatRatio: 0,
              vatValue: 0,
              amountWithVat: 0,
              representativeId: 0,
              paymentType: 1,
              paperNo: '',
              paperDate: '',
              description: '',
              companyId: api.getCompany(),
              branchId: 0,
              userId: api.getUser(),
              carryOvered: true,
              costCenterId: 0,
              currencyId: '',
              exRate: 1,
              recSource: 1,
              recId: this.decryptId(router.currentRoute.params.id),
              yearId: 0,
            }
            this.GetAccountByRecId(this.CustomerId)
            this.$refs.validationBondRules.reset()
          })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.isLoading = false
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    validationTransferToAccountsForm() {
      this.$refs.validationTransferToAccountsRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          api.CreateJournalFromInvoice(0, this.transferToAccountsModel).then(result => {
            this.getDataList(this.decryptId(router.currentRoute.params.id))
            this.$swal({
              title: this.$t('Good job!'),
              text: this.$t('Your details have been saved successfully.'),
              icon: result.data.httpCode === 200 ? 'success' : 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isLoading = false
            this.GetAccountByRecId(this.CustomerId)
            this.$refs.transfer_to_accounts.hide()
            this.$refs.validationBondRules.reset()
          })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.isLoading = false
              this.$swal({
                title: error.response.data.title,
                html: Object.values(error.response.data.errors)
                  .map(item => `<div>${item}</div>`)
                  .join(''),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    CheckBalance() {
      if (this.totalExchangeAmount >= this.dataModel.customerBalance) {
        this.$refs['modalExchange-lg'].hide()
        this.$swal({
          title: this.$t('Warning'),
          text: this.$t('The customer has no balance'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$refs['modalExchange-lg'].show()
      }
    },
    getAccountsList() {
      api.GetAllAccountChild(0, 0, '', '', '', api.getCompany(), 0).then(result => {
        this.AccountsList = result.data.data.pageData
      })
    },
    getRegionList() {
      api.getRegionList().then(result => {
        this.RegionList = result.data.data.pageData
      })
    },
    getCitiesList(parentId) {
      api.getCitiesList(parentId).then(result => {
        this.CityList = result.data.data.pageData
      })
    },
    getDistrictsList(parentId) {
      api.getDistrictsList(parentId).then(result => {
        this.DistrictList = result.data.data.pageData
      })
    },

    getGenderList() {
      api.getAcceptedOptions(7).then(result => {
        this.GenderList = result.data.data.pageData
      })
    },
    getNationalityList() {
      api.getAcceptedOptions(8).then(result => {
        this.NationalityList = result.data.data.pageData
      })
    },
    getCompaybranchs() {
      api.getCompaybranchs().then(result => {
        this.BranchsList = result.data.data.pageData
      })
    },
    getJobList() {
      api.getAcceptedOptions(11).then(result => {
        this.JobList = result.data.data.pageData
      })
    },
    getReligionsList() {
      api.getAcceptedOptions(9).then(result => {
        this.ReligionsList = result.data.data.pageData
      })
    },
    getLanguage() {
      return api.getLanguage()
    },
    SetAcceptedId(id) {
      this.acceptedId = id
    },
    getArrivalDestinationList() {
      api.getAcceptedOptions(17).then(result => {
        this.ArrivalDestinationList = result.data.data.pageData
      })
    },

    getAgentList() {
      api.getAcceptedOptions(26).then(result => {
        this.AgentList = result.data.data.pageData
      })
    },

    updateContactAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.dataModel.vatAmount = parseFloat(val) * parseFloat(this.dataModel.vatPercentage === '' ? 0 : this.dataModel.vatPercentage) / 100

      this.dataModel.amountWithVAT = parseFloat(val)
        + parseFloat(this.dataModel.vatAmount === '' ? 0 : this.dataModel.vatAmount)

      this.dataModel.amountDue = parseFloat(this.dataModel.amountWithVAT === '' ? 0 : this.dataModel.amountWithVAT)
        + parseFloat(this.dataModel.musanedFee === '' ? 0 : this.dataModel.musanedFee)
    },
    updateBondAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.bondModel.vatValue = parseFloat(val) * parseFloat(this.bondModel.vatRatio === '' ? 0 : this.bondModel.vatRatio) / 100

      this.bondModel.amountWithVat = parseFloat(this.bondModel.amount) + parseFloat(this.bondModel.vatValue)

      // eslint-disable-next-line no-mixed-operators
      this.bondExchangeModel.vatValue = parseFloat(val) * parseFloat(this.bondExchangeModel.vatRatio === '' ? 0 : this.bondExchangeModel.vatRatio) / 100

      this.bondExchangeModel.amountWithVat = parseFloat(this.bondExchangeModel.amount) + parseFloat(this.bondExchangeModel.vatValue)
    },
    updateBondVatAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.bondModel.vatValue = parseFloat(val) * parseFloat(this.bondModel.amount === '' ? 0 : this.bondModel.amount) / 100

      this.bondModel.amountWithVat = parseFloat(this.bondModel.amount) + parseFloat(this.bondModel.vatValue)

      // eslint-disable-next-line no-mixed-operators
      this.bondExchangeModel.vatValue = parseFloat(val) * parseFloat(this.bondExchangeModel.amount === '' ? 0 : this.bondExchangeModel.amount) / 100

      this.bondExchangeModel.amountWithVat = parseFloat(this.bondExchangeModel.amount) + parseFloat(this.bondExchangeModel.vatValue)
    },

    updateVatAmount(val) {
      // eslint-disable-next-line no-mixed-operators
      this.dataModel.vatAmount = parseFloat(val) * parseFloat(this.dataModel.contactAmount === '' ? 0 : this.dataModel.contactAmount) / 100

      this.dataModel.amountWithVAT = parseFloat(this.dataModel.contactAmount === '' ? 0 : this.dataModel.contactAmount)
        + parseFloat(this.dataModel.vatAmount === '' ? 0 : this.dataModel.vatAmount)

      this.dataModel.amountDue = parseFloat(this.dataModel.amountWithVAT === '' ? 0 : this.dataModel.amountWithVAT)
        + parseFloat(this.dataModel.musanedFee === '' ? 0 : this.dataModel.musanedFee)
    },

    updateMusanedFee(val) {
      this.dataModel.amountDue = parseFloat(this.dataModel.amountWithVAT === '' ? 0 : this.dataModel.amountWithVAT)
        + parseFloat(val === '' ? 0 : val)
    },
    GetBySocialID(val) {
      api.GetBySocialID(val).then(result => {
        console.log(result.data.data)
        console.log(result.data.data.regionId)
        this.dataModel.customerMobileNO = result.data.data === null ? '' : result.data.data.mobileNo
        this.dataModel.customerName = result.data.data === null ? '' : result.data.data.customerName
        this.dataModel.customerCountryId = result.data.data === null ? '' : result.data.data.nationalityId
        this.dataModel.customerRegionId = result.data.data === null ? '' : result.data.data.regionId
        this.getCitiesList(result.data.data === null ? '' : result.data.data.regionId)
        this.dataModel.customerCityId = result.data.data === null ? '' : result.data.data.cityId
        this.dataModel.customerGenderId = result.data.data === null ? '' : result.data.data.sexId
        this.dataModel.customerProfession = result.data.data === null ? '' : result.data.data.job
      })
    },
    getDataList(id) {
      if (id !== 0) {
        api.getBondList(0, 0, '', '0', '', api.getCompany(), 0, 1,
          id, 1).then(result => {
          this.DataList = result.data.data.pageData
          this.totalRows = result.data.data.filteredCount
          this.totalAmount = result.data.data.pageData
            .map(({ amount }) => amount)
            .reduce((a, b) => a + b, 0)
        })
        api.getBondList(0, 0, '', '0', '', api.getCompany(), 0, 1,
          id, 2).then(result => {
          this.DataListExchange = result.data.data.pageData
          this.totalRowsExchange = result.data.data.filteredCount
          this.totalExchangeAmount = result.data.data.pageData
            .map(({ amount }) => amount)
            .reduce((a, b) => a + b, 0)
        })
        api.GetAllInvoiceForContract(id).then(result => {
          this.InvoicesList = result.data.data.pageData
        })
      }
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataList()
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.getDataList()
    },
    handlePageChangeExchange(value) {
      this.currentPageExchange = value
      this.getDataList()
    },
    handlePerPageChangeExchange(value) {
      this.perPageExchange = value
      this.getDataList()
    },
    GetBondAutoNum(val) {
      api.GetBondAutoNum(api.getCompany(), 0, val).then(result => {
        this.bondModel.bondNo = result.data.data
      })
    },
    GetBondExchangeAutoNum(val) {
      api.GetBondAutoNum(api.getCompany(), 0, val).then(result => {
        this.bondExchangeModel.bondNo = result.data.data
      })
    },
    getemployeeList() {
      api.getemployeeList(0, 0, '', '', '', api.getCompany(), 0).then(result => {
        this.EmployeeList = result.data.data.pageData
      })
    },
    getCurrencyList() {
      api.getAcceptedOptions(27).then(result => {
        this.CurrencyList = result.data.data.pageData
      })
    },
    GetCurrencyExRate(val) {
      api.getAcceptedOption(val).then(result => {
        this.bondModel.exRate = result.data.data.optionValue
      })
    },
    getCostCenterList() {
      api.getAcceptedOptions(28).then(result => {
        this.CostCenterList = result.data.data.pageData
      })
    },
    GetAccountByRecId(CustomerId) {
      api.GetAccountByRecId(1, CustomerId === undefined ? 0 : CustomerId).then(result => {
        this.bondModel.accountTo = result.data.data.id
        this.transferToAccountsModel.fromAccountId = result.data.data.id
        this.bondExchangeModel.accountFrom = result.data.data.id
        this.bondExchangeModel.amount = this.dataModel.customerBalance - this.totalExchangeAmount
        this.bondExchangeModel.amountWithVat = this.dataModel.customerBalance - this.totalExchangeAmount
      })
    },
    PrintInvoice(id) {
      return api.PrintInvoice(id)
    },
    PrintBond(id) {
      return api.PrintBond(id)
    },
    PrintJournal(id) {
      return api.PrintJournal(id)
    },
    updateRequestDate(val) {
      // eslint-disable-next-line camelcase
      const date1Str_useDays = new Date(this.dataModel.arrivalDate)
      // eslint-disable-next-line camelcase
      const date2Str_useDays = new Date(val)
      // eslint-disable-next-line camelcase
      const diffTime_useDays = Math.abs(date2Str_useDays - date1Str_useDays)
      // eslint-disable-next-line camelcase
      const diffDays_useDays = this.dataModel.isArrived === true ? Math.ceil(diffTime_useDays / (1000 * 60 * 60 * 24)) : 0

      // eslint-disable-next-line camelcase
      this.dataModel.useDays = this.dataModel.isArrived === true ? diffDays_useDays : 0

      // eslint-disable-next-line camelcase
      const date1Str_waitDays = new Date(this.dataModel.contractDate)
      // eslint-disable-next-line camelcase
      const date2Str_waitDays = new Date(this.dataModel.isArrived === true ? new Date(this.dataModel.arrivalDate) : val)
      // eslint-disable-next-line camelcase
      const diffTime_waitDays = Math.abs(date2Str_waitDays - date1Str_waitDays)
      // eslint-disable-next-line camelcase
      const diffDays_waitDays = Math.ceil(diffTime_waitDays / (1000 * 60 * 60 * 24))

      // eslint-disable-next-line camelcase
      this.dataModel.waitDays = diffDays_waitDays

      if (this.dataModel.isArrived === true) {
        // eslint-disable-next-line camelcase
        this.dataModel.totalPenalty = this.dataModel.applyPenalty === 1
          // eslint-disable-next-line camelcase
          ? (this.dataModel.amountWithVAT - ((this.dataModel.amountWithVAT / 24) * (24 - Math.ceil(diffDays_useDays / 30)))).toFixed(2)
          : 0

        if (this.dataModel.applyPenalty === 1) {
          this.dataModel.customerBalance = this.dataModel.amountWithVAT - this.dataModel.totalPenalty
        }
      // eslint-disable-next-line camelcase
      } else if (diffDays_waitDays < 5) {
        this.dataModel.cancelationPenalty = 0
      // eslint-disable-next-line camelcase
      } else if (diffDays_waitDays >= 6 && diffDays_waitDays <= 30) {
        this.dataModel.cancelationPenalty = 5
        this.dataModel.totalPenalty = 0
        // eslint-disable-next-line no-mixed-operators
        this.dataModel.totalPenalty = this.dataModel.applyPenalty === 1 ? this.dataModel.amountWithVAT * 5 / 100 : 0
        if (this.dataModel.applyPenalty === 1) {
          this.dataModel.customerBalance = this.dataModel.cancelationSide === 1 ? this.dataModel.amountWithVAT + this.dataModel.totalPenalty : this.dataModel.amountWithVAT - this.dataModel.totalPenalty
        }
      // eslint-disable-next-line camelcase
      } else if (diffDays_waitDays >= 31 && diffDays_waitDays <= 120) {
        this.dataModel.cancelationPenalty = 10
        // eslint-disable-next-line no-mixed-operators
        this.dataModel.totalPenalty = this.dataModel.applyPenalty === 1 ? this.dataModel.amountWithVAT * 10 / 100 : 0
        if (this.dataModel.applyPenalty === 1) {
          this.dataModel.customerBalance = this.dataModel.cancelationSide === 1 ? this.dataModel.amountWithVAT + this.dataModel.totalPenalty : this.dataModel.amountWithVAT - this.dataModel.totalPenalty
        }
      // eslint-disable-next-line camelcase
      } else if (diffDays_waitDays >= 121) {
        this.dataModel.cancelationPenalty = 15
        // eslint-disable-next-line no-mixed-operators
        this.dataModel.totalPenalty = this.dataModel.applyPenalty === 1 ? this.dataModel.amountWithVAT * 15 / 100 : 0
        if (this.dataModel.applyPenalty === 1) {
          this.dataModel.customerBalance = this.dataModel.cancelationSide === 1 ? this.dataModel.amountWithVAT + this.dataModel.totalPenalty : this.dataModel.amountWithVAT - this.dataModel.totalPenalty
        }
      }
    },
    updateArrived(val) {
      if (val === true) {
        this.dataModel.cancelationSide = 2
      } else {
        this.dataModel.useDays = 0
        // eslint-disable-next-line camelcase
        const date1Str_waitDays = new Date(this.dataModel.contractDate)
        // eslint-disable-next-line camelcase
        const date2Str_waitDays = new Date(this.dataModel.isArrived === true ? new Date(this.dataModel.arrivalDate) : this.dataModel.requestDate)
        // eslint-disable-next-line camelcase
        const diffTime_waitDays = Math.abs(date2Str_waitDays - date1Str_waitDays)
        // eslint-disable-next-line camelcase
        const diffDays_waitDays = Math.ceil(diffTime_waitDays / (1000 * 60 * 60 * 24))

        // eslint-disable-next-line camelcase
        this.dataModel.waitDays = diffDays_waitDays
      }
    },
    updateProcedureType(val) {
      if (val === 1 && this.dataModel.applyPenalty === 1) {
        this.updateRequestDate(this.dataModel.requestDate)
      } else if (val === 1 && this.dataModel.applyPenalty === 2) {
        this.dataModel.totalPenalty = 0
        this.dataModel.customerBalance = this.dataModel.amountWithVAT
      }
    },
    updateCancelationSide() {
      this.updateRequestDate(this.dataModel.requestDate)
    },
    updateApplyPenalty(val) {
      if (val === 1) {
        this.updateRequestDate(this.dataModel.requestDate)
      } else {
        this.dataModel.totalPenalty = 0
        this.dataModel.customerBalance = this.dataModel.procedureType === 1 ? this.dataModel.amountWithVAT : 0
      }
    },
    GetAllInvoiceForContract(ContractId) {
      api.GetAllInvoiceForContract(ContractId).then(result => {
        this.InvoicesList = result.data.data.pageData
      })
    },
    init() {
      this.getCompaybranchs()
      this.getDataList()
      this.getGenderList()
      this.getNationalityList()
      this.getJobList()
      this.getReligionsList()
      this.getArrivalDestinationList()
      this.getAgentList()
      this.getRegionList()
      this.getAccountsList()
      this.getemployeeList()
      this.getCurrencyList()
      this.GetBondAutoNum(1)
      this.GetBondExchangeAutoNum(2)
      this.getCostCenterList()

      if (this.decryptId(router.currentRoute.params.id) !== '' && this.decryptId(router.currentRoute.params.id) !== 0 && this.decryptId(router.currentRoute.params.id) !== undefined) {
        this.IsBond = true
        this.IsEditMode = true
        this.$refs.topProgress.start()
        api.getMediation(this.decryptId(router.currentRoute.params.id)).then(result => {
          this.getCitiesList(result.data.data.customerRegionId)

          this.CustomerId = result.data.data.customerId
          this.GetAccountByRecId(result.data.data.customerId)
          this.dataModel = result.data.data
          this.dataModel.applyPenalty = result.data.data.applyPenalty === 0 ? 2 : result.data.data.applyPenalty
          console.log(result.data.data)
          this.dataModel.requestDate = new Date()
          this.$refs.topProgress.done()
        })

        this.getDataList(this.decryptId(router.currentRoute.params.id))
      } else {
        this.dataModel = {
          socialID: '',
          customerMobileNO: '',
          customerName: '',
          customerCountryId: '',
          customerRegionId: '',
          customerCityId: '',
          customerGenderId: '',
          customerProfession: '',
          contractNO: '',
          mosandContractNo: '',
          contractDate: new Date(),
          contactAmount: 0,
          vatPercentage: 0,
          vatAmount: 0,
          amountWithVAT: 0,
          musanedFee: 0,
          amountDue: 0,
          workerName: '',
          agentId: '',
          workerProfessionId: '',
          workerNationalityId: '',
          workerReligionId: '',
          arrivalDestinationId: '',
          ageOf: '',
          ageTo: '',
          workerSalary: '',
          workerQualification: '',
          responsiblePerson: '',
          isDelegation: false,
          delegationDate: new Date(),
          isArrived: false,
          arrivalDate: new Date(),
          visaNo: '',
          visaExpiryDate: '',
          isTicketReserved: false,
          createdDate: new Date(),
          createdByUser: api.getUser(),
          isPaid: false,
          contractStatusId: 0,
          additionalTerms: '',
          companyId: api.getCompany(),
          branchId: 0,
          procedureType: 0,
          cancelationSide: 0,
          requestDate: '',
          useDays: 0,
          waitDays: 0,
          cancelationPenalty: 0,
          totalPenalty: 0,
          applyPenalty: 0,
          customerBalance: 0,
        }
        api.GetMediationAutoNum(api.getCompany()).then(result => {
          this.dataModel.contractNO = result.data.data
        })
      }
    // re-validate to re-generate the messages.
    // this.$refs.validationRules.validate()
    },
    GetInvoice(data) {
      this.$refs.topProgress.start()
      api.getMediation(this.decryptId(router.currentRoute.params.id)).then(result1 => {
        this.CustomerId = result1.data.data.customerId
        api.GetAccountByRecId(1, result1.data.data.customerId).then(result => {
          this.transferToAccountsModel.fromAccountId = result.data.data.id
          this.transferToAccountsModel = {
            invoiceId: data.id,
            fromAccountId: result.data.data.id,
            fromAccountValue: data.invoiceNet,
            discountAccountId: 0,
            discountAccountValue: data.discountValue,
            toAccountId: '',
            toAccountValue: data.invoiceTotal,
            vatAccountId: '',
            vatAccountValue: data.vatValue,
            costCenterId: 0,
            currencyId: 0,
            exRate: 0,
            companyId: api.getCompany(),
            branchId: 0,
            userId: api.getUser(),
          }
          this.$refs.topProgress.done()
        })
      })
    },
    CreateJournalFromBond(data) {
      this.transferBondToAccountsDto = {
        bondId: data.id,
        fromAccountId: data.accountFrom,
        fromAccountValue: data.amountWithVat,
        toAccountId: data.accountTo,
        toAccountValue: data.amountWithVat,
        vatAccountId: 0,
        vatAccountValue: 0,
        costCenterId: 0,
        currencyId: 0,
        exRate: 0,
        companyId: api.getCompany(),
        branchId: 0,
        userId: api.getUser(),
      }

      this.isLoading = true
      api.CreateJournalFromBond(0, this.transferBondToAccountsDto).then(result => {
        this.getDataList(this.decryptId(router.currentRoute.params.id))
        this.$swal({
          title: this.$t('Good job!'),
          text: this.$t('Your details have been saved successfully.'),
          icon: result.data.httpCode === 200 ? 'success' : 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.isLoading = false
        this.$refs.validationBondRules.reset()
      })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoading = false
          this.$swal({
            title: error.response.data.title,
            html: Object.values(error.response.data.errors)
              .map(item => `<div>${item}</div>`)
              .join(''),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    decryptId(str) {
      const decodedStr = decodeURIComponent(str)
      return AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8)
    },
    encryptId(str) {
      const ciphertext = AES.encrypt(str.toString(), 'secretPassphrase')
      this.$router.push({ name: 'app-mediation-edit', params: { id: encodeURIComponent(ciphertext.toString()) } })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.add-new {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
